import { Box, Divider, Grid } from "@mui/material";
import { Formik, Form } from "formik";
import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { IMAGES } from "../../../assets/images";
import {
  changeColor,
  getCurrencyType,
  onKeyDown,
  status,
} from "../../../utils/helper-functions";
import { twoButtonList } from "../../../utils/reusable-functions/buttonFunction";
import { validateEditReferralCoupon } from "../../../utils/reusable-functions/data-validation/couponsValidatoin";
import { couponsConst } from "../../../utils/text-constants";
import * as components from "../../../components";
import useMessage from "../../../hooks/customHooks/useMessage";
import style from "./coupons.module.scss";
import { fetchEachCouponDetailsController } from "../../../controllers/couponsController";
import { getCountryName } from "../../../utils/reusable-functions/getCountryName";
import { handleSubmitEditReferralCoupon } from "../../../utils/reusable-functions/coupons/editCoupons";
import {
  handleDropFunction2,
  handleOnDragFunction,
} from "../../../utils/reusable-functions/dragDropFunction";

export default function EditCouponPage() {
  const navigate = useNavigate();
  const location: any = useLocation();
  const { setMsg } = useMessage();
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [editData, setEditData] = React.useState({
    isLoading: true,
    data: null,
  });
  const [dragBoxId, setDragBoxId] = React.useState(null);

  React.useEffect(() => {
    if (location.state?.couponId) {
      fetchEachCouponDetailsController(
        (response: any) => {
          const initialValues: any = {
            title: response.couponTitle,
            discount: response.discount,
            countryId: response.country,
            durationInMonths: response.durationInMonths,
            minBillAmount: response.minAmount,
            maxDiscountAmount: response.maxDiscount,
            description: response.description,
            termsAndCondition: "",
            termsAndCondtionsList: response.termsAndConditions,
            status: response.status,
          };
          setEditData({ isLoading: false, data: initialValues });
        },
        () => {
          setEditData({ isLoading: false, data: null });
        },
        location.state.couponId
      );
    }
  }, []);

  return (
    <>
      {!editData.isLoading && editData.data && (
        <Formik
          initialValues={editData.data}
          validationSchema={validateEditReferralCoupon}
          onSubmit={(values) =>
            handleSubmitEditReferralCoupon(values, navigate, setMsg, setIsUpdating)
          }
          validateOnChange
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
          }: any) => {
            return (
              <Form onKeyDown={onKeyDown}>
                <Grid container spacing={2} p={2.5} pl={3}>
                  <Grid item sm={12} md={12} lg={12}>
                    <components.TextInputField
                      name={"title"}
                      label={couponsConst.TITLE}
                      placeholder={"Enter Coupon Title"}
                      errorText={errors.title}
                      value={values.title}
                      onChange={handleChange}
                      error={errors.title && touched.title}
                      onBlur={handleBlur}
                      maxInputLenth={30}
                    />
                  </Grid>
                  <Grid item sm={12} md={6} lg={6}>
                    <components.TextInputField
                      name={"discount"}
                      label={couponsConst.DISCOUNT}
                      placeholder={"Enter Discount"}
                      value={values.discount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorText={errors.discount}
                      error={errors.discount && touched.discount}
                    />
                  </Grid>
                  <Grid item sm={12} md={6} lg={6}>
                    <components.TextInputField
                      name={"durationInMonths"}
                      label={couponsConst.DURATION_IN_MONTHS}
                      placeholder={"Enter Duration in months"}
                      value={values.durationInMonths}
                      onChange={handleChange}
                      maxInputLenth={2}
                      onBlur={handleBlur}
                      errorText={errors.durationInMonths}
                      error={errors.durationInMonths && touched.durationInMonths}
                    />
                  </Grid>
                  <Grid item sm={12} md={6} lg={6}>
                    <components.TextInputField
                      name={"countryId"}
                      label={couponsConst.COUNTRY}
                      value={getCountryName(values.countryId)}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item sm={12} md={6} lg={6}>
                    <components.SingleSelectInputField
                      name="status"
                      label={couponsConst.STATUS}
                      listData={status}
                      onChange={handleChange}
                      value={values.status}
                    />
                  </Grid>
                  <Grid item sm={12} md={6} lg={6}>
                    <components.TextInputField
                      name={"minBillAmount"}
                      label={couponsConst.MIN_BILL_AMT}
                      placeholder={"Enter Amount"}
                      value={values.minBillAmount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxInputLenth={20}
                      prefixText={
                        <p className={style.currencyStyle}>
                          {getCurrencyType(values.countryId)}
                        </p>
                      }
                      errorText={errors.minBillAmount}
                      error={errors.minBillAmount && touched.minBillAmount}
                    />
                  </Grid>
                  <Grid item sm={12} md={6} lg={6}>
                    <components.TextInputField
                      name={"maxDiscountAmount"}
                      label={couponsConst.MAX_DISCOUNT}
                      placeholder={"Enter Amount"}
                      value={values.maxDiscountAmount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxInputLenth={20}
                      prefixText={
                        <p className={style.currencyStyle}>
                          {getCurrencyType(values.countryId)}
                        </p>
                      }
                      errorText={errors.maxDiscountAmount}
                      error={errors.maxDiscountAmount && touched.maxDiscountAmount}
                    />
                  </Grid>
                  <Grid item sm={12} md={12} lg={12}>
                    <components.TextInputField
                      name={"description"}
                      label={couponsConst.DESCRIPTION}
                      placeholder={"Enter Description"}
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxInputLenth={50}
                      errorText={errors.description}
                      error={errors.description && touched.description}
                      multiline
                      rows={2}
                    />
                  </Grid>
                  <Grid item sm={12} md={12} lg={12}>
                    <components.TextInputField
                      name={"termsAndCondition"}
                      label={couponsConst.TERMS_CONDITIONS}
                      placeholder={"Enter Terms & Conditions"}
                      value={values.termsAndCondition}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxInputLenth={50}
                      errorText={errors.termsAndCondtionsList}
                      error={errors.termsAndCondtionsList && touched.termsAndCondition}
                      disabled={values.termsAndCondtionsList.length >= 10 ? true : false}
                      endIcon={
                        values.termsAndCondition?.toString().trim() && IMAGES.addIcon
                      }
                      onIconClick={() => {
                        const val = [
                          ...values.termsAndCondtionsList,
                          values.termsAndCondition,
                        ];
                        setFieldValue("termsAndCondition", "");
                        setFieldValue("termsAndCondtionsList", val);
                      }}
                    />
                    {Array.isArray(values.termsAndCondtionsList) &&
                      values.termsAndCondtionsList.map((terms: any, index: number) => (
                        <div
                          draggable={true}
                          onDragOver={(ev) => ev.preventDefault()}
                          onDragStart={() => {
                            changeColor(`draggable-${index}`, "#F0F7F8");
                            handleOnDragFunction(index, setDragBoxId);
                          }}
                          onDrop={() => {
                            changeColor(`draggable-${dragBoxId}`, "#FFFFFF");
                            const responseAfterDragDrop = handleDropFunction2(
                              index,
                              values.termsAndCondtionsList,
                              dragBoxId
                            );
                            setFieldValue("termsAndCondtionsList", responseAfterDragDrop);
                          }}
                          onMouseOut={() =>
                            changeColor(`draggable-${dragBoxId}`, "#FFFFFF")
                          }
                        >
                          <components.TextDeleteComponent
                            index={index}
                            text={terms}
                            prefixIcon={IMAGES.blueRoundIcon}
                            handleDeleteClick={() => {
                              values.termsAndCondtionsList.splice(index, 1);
                              setFieldValue(
                                "termsAndCondtionsList",
                                values.termsAndCondtionsList
                              );
                            }}
                            boxStyle={style.textDeleteBoxStyle}
                          />
                        </div>
                      ))}
                  </Grid>
                  <Grid item sm={12} md={12} lg={12}>
                    <Divider sx={{ mt: 2 }} />
                    <components.ButtonsListComponent
                      isLoading={isUpdating}
                      buttonsIndexForLoader={[0]}
                      buttonsArray={twoButtonList("Update")}
                      onButtonClick={(data: any) => {
                        if (data.id === "cancel") {
                          navigate("/admin/coupons-listing", {
                            state: { tabIndex: 0 },
                            replace: true,
                          });
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}
      {editData.isLoading && (
        <Box className={style.progressBarContainer}>
          <components.CircularProgressBar loadername={"Loading data, please wait..."} />
        </Box>
      )}
    </>
  );
}
