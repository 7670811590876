import * as React from "react";
import { Box, Divider, Grid, Tooltip, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import * as components from "../../../components";
import style from "./classes.module.scss";
import {
  explorerTableHeader,
  guideTableHeader,
} from "../../../utils/reusable-functions/classes/view-class/viewClassPageTableHeaders";
import {
  fetchedViewClassData,
  fetchedViewClassLocationData,
} from "../../../utils/reusable-functions/classes/view-class/getViewClassData";
import { classDetailsConst, errorConst } from "../../../utils/text-constants";
import { getSingleClassData } from "../../../utils/reusable-functions/classes/view-class/viewClassApiFunctions";
import useMessage from "../../../hooks/customHooks/useMessage";
import { checkDateLessThan48hrs } from "../../../utils/reusable-functions/data-validation/dateTimeValidation";
import { IMAGES } from "../../../assets/images";

export default function ViewClassDetailsPage() {
  const navigate = useNavigate();
  const location: any = useLocation();
  const { setMsg } = useMessage();

  const [classData, setClassData] = React.useState({
    data: null,
    isLoading: false,
  });

  const [pageSize, setPageSize] = React.useState(10);

  React.useEffect(() => {
    const classId = location?.state?.class_id;
    classId && getSingleClassData(classId, setClassData, "", "", "", setMsg);
  }, []);

  function checkClassStatus(classVal: any) {
    if (
      classVal.classStatus === "Expired" ||
      classVal.classStatus === "Cancelled" ||
      classVal.classStatus === "Completed"
    ) {
      return true;
    } else {
      return false;
    }
  }

  function isHideFooter() {
    if (classData.data.explorers?.length <= 0) {
      return true;
    }
    return false;
  }

  return (
    <>
      {classData.isLoading ? (
        <Box className={style.progressBarContainer}>
          <components.CircularProgressBar
            loadername={classDetailsConst.PROGRESS_BAR_TEXT}
          />
        </Box>
      ) : (
        <Grid container spacing={3} p={2.5} pl={3}>
          {classData.data && Object.keys(classData.data)?.length > 0 && (
            <>
              <Grid item>
                <Typography className={style.mediumFontHeader}>
                  {classDetailsConst.COVER_IMAGE}
                </Typography>
                <components.ImageWithContainer
                  imageData={{ image: classData.data?.coverImageUrl, alt: "alt text" }}
                  imageStyle={{ width: "375px", height: "295px" }}
                />
              </Grid>
              {classData.data.otherImages?.length > 0 && (
                <Grid item>
                  <components.LabelImagesListingComponent
                    label={classDetailsConst.OTHER_IMAGE}
                    imagesList={classData.data.otherImages || []}
                    imgAttribute="url"
                  />
                </Grid>
              )}
              <Grid item sm={12} lg={12} md={12}>
                <Divider />
              </Grid>
              {fetchedViewClassData(classData.data).map(
                (classDetails: any, i: number) => {
                  if (classDetails.id === "id-tag") {
                    return (
                      <Grid item sm={12} lg={6} md={6} key={i}>
                        <Typography title={classDetails.label} className={style.label}>
                          {classDetails.label}
                        </Typography>
                        <components.SimpleTagList tagsList={classDetails.data} />
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid item sm={12} lg={6} md={6} key={i}>
                        <components.SimpleLableDataComponent
                          label={classDetails.label}
                          value={classDetails.data}
                        />
                      </Grid>
                    );
                  }
                }
              )}
              <Grid item sm={12} lg={12} md={12}>
                <Divider />
              </Grid>
              <Grid item sm={12} lg={12} md={12}>
                <Typography className={style.locationHeader}>Class Location</Typography>
              </Grid>
              {fetchedViewClassLocationData(classData.data?.classLocation).map(
                (locationData: any, i: number) => {
                  if (locationData.label === classDetailsConst.LOCATION_IMAGES) {
                    if (locationData.data?.length) {
                      return (
                        <Grid item sm={12} md={12} lg={12}>
                          <components.LabelImagesListingComponent
                            label={classDetailsConst.LOCATION_IMAGES}
                            imagesList={locationData.data}
                            imageContainerClassName={style.locationImageContainer}
                            labelClassName={style.label}
                            imgAttribute="url"
                          />
                        </Grid>
                      );
                    } else {
                      return <></>;
                    }
                  }
                  return (
                    <Grid item sm={12} lg={6} md={6} key={i}>
                      <components.SimpleLableDataComponent
                        label={locationData.label}
                        value={locationData.data}
                      />
                    </Grid>
                  );
                }
              )}
              <Grid item sm={12} lg={12} md={12}>
                <Divider sx={{ mb: 3 }} />
                <Tooltip
                  title={
                    checkDateLessThan48hrs(classData.data?.schedule?.startTimestamp) ||
                    checkClassStatus(classData.data) ? (
                      <Typography style={{ fontSize: "15px", padding: "10px" }}>
                        {errorConst.HIDE_EDIT_CLASS}
                      </Typography>
                    ) : (
                      ""
                    )
                  }
                >
                  <span>
                    <components.LoadingButtonComponent
                      disabled={
                        checkDateLessThan48hrs(
                          classData.data?.schedule?.startTimestamp
                        ) || checkClassStatus(classData.data)
                      }
                      buttonClassName={style.editButton}
                      buttonLabel="Edit"
                      labelImg={
                        checkDateLessThan48hrs(
                          classData.data?.schedule?.startTimestamp
                        ) || checkClassStatus(classData.data)
                          ? IMAGES.editDisbledPencil
                          : IMAGES.editPencilIcon
                      }
                      handleClick={() =>
                        navigate("/admin/edit-class", {
                          state: { class_id: location?.state?.class_id },
                        })
                      }
                    />
                  </span>
                </Tooltip>
                <components.LoadingButtonComponent
                  buttonLabel="Back"
                  buttonClassName={style.backbutton}
                  handleClick={() => navigate(-1)}
                />
              </Grid>
              <Grid container p={1} pl={3} mt={8}>
                <Typography className={style.tableLabel}>
                  {classDetailsConst.CHECKIN_DETAILS_GUIDE}
                </Typography>
                <Grid item sm={12} lg={12} md={12}>
                  <components.DataGridComponent
                    columns={guideTableHeader()}
                    rows={[
                      {
                        id: 1,
                        guideFullName: classData.data.guideFullName,
                        check_in: classData.data.scheduledTimeStamps[0]?.isGuideCheckedIn
                          ? "Yes"
                          : "No",
                      },
                    ]}
                    pageSize={1}
                    hideFooter={true}
                  />
                </Grid>
              </Grid>
              <Grid container p={1} pl={3} mt={5}>
                <Typography className={style.tableLabel}>
                  {classDetailsConst.CHECKIN_DETAILS_EXPLORER}
                </Typography>
                <Grid item sm={12} lg={12} md={12}>
                  <components.DataGridComponent
                    paginationMode="client"
                    rowCount={classData.data?.explorers?.length}
                    columns={explorerTableHeader()}
                    rows={
                      classData.data.explorers?.map((explorer: any) => ({
                        ...explorer,
                        seatCount: explorer?.seatCount || 'NA',
                        isExplorerCheckedIn: explorer.isExplorerCheckedIn ? "Yes" : "No",
                      })) || []
                    }
                    pageSize={pageSize}
                    onPageSizeChange={(pageSize1: any) => {
                      setPageSize(pageSize1);
                    }}
                    hideFooter={isHideFooter()}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
}
