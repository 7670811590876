import { Box, Stack, Typography } from "@mui/material";
import * as React from "react";

import { IMAGES } from "../../assets/images";
import style from "./taglist.module.scss";

function EmailTags(props: any) {
  const { onClick, text1, text2 } = props;
  const [switchIcon, setSwitchIcon] = React.useState(0);

  return (
    <Box
      className={style.emailTagsContainer}
      onClick={() => {
        if (switchIcon === 0) {
          setSwitchIcon(1);
          setTimeout(() => setSwitchIcon(0), 1000);
        }
        onClick(text2 || "");
      }}
    >
      <Box className={style.emailTextContainer}>
        <Typography className={style.emailTagTxt1}>{text1}</Typography>
        <Typography className={style.emailTagTxt2}>{`{{${text2 || ""}}}`}</Typography>
      </Box>
      {switchIcon === 0 ? (
        <img
          src={IMAGES.copySymbol}
          title={"Click to copy"}
          className={style.copyIconStyle}
        />
      ) : (
        <Typography className={style.copiedText}>Copied!</Typography>
      )}
    </Box>
  );
}

function EmailTagsList(props: any) {
  const { emailTagsListArray, onClick } = props;
  return (
    <Stack direction="row" gap={2} sx={{ display: "flex", flexWrap: "wrap" }}>
      {Array.isArray(emailTagsListArray) &&
        emailTagsListArray.map((tags: any) => (
          <EmailTags
            text1={tags.name}
            text2={tags.value}
            onClick={() => onClick(tags.value)}
          />
        ))}
    </Stack>
  );
}

export { EmailTags, EmailTagsList };
