import { PATH_PARAMS } from "../constants";
import { SuccessResponse } from "../types/parameters.type";
import { ApiClient, ErrorHandler, SuccessHandler } from "../services/data.service";

export const fetchBroadCastMessageController = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params?: any
) => {
  ApiClient.makeGetRequest(
    `${PATH_PARAMS.GET_BROADCAST_MESSAGES_LIST}`,
    successHandler,
    errorHandler,
    params
  );
};

export const fetchViewBroadCastData = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  id?: any
) => {
  ApiClient.makeGetRequest(
    `${PATH_PARAMS.GET_VIEW_BROADCAST_DATA}${id}`,
    successHandler,
    errorHandler
  );
};

export const deleteBroadcastController = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  id?: any
) => {
  ApiClient.makeDeleteRequest(
    `${PATH_PARAMS.DELETE_BROADCAST_MESSAGE}${id}`,
    successHandler,
    errorHandler
  );
};

export const createBroadcastController = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  postParams?: any
) => {
  ApiClient.makePostRequest(
    PATH_PARAMS.CREATE_BROADCAST_MESSAGE,
    successHandler,
    errorHandler,
    postParams
  );
};
