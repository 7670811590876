import * as React from "react";
import { CheckboxComponent, ButtonsListComponent, CustomDateRangePicker } from "../index";
import { Container, Divider, FormGroup, FormControlLabel } from "@mui/material";

import styles from "./filterComponent.module.scss";
import { filterTextConst } from "../../utils/text-constants";
import { applyCancelButtonsList } from "../../utils/reusable-functions/buttonFunction";

/**
 * Filter using date and country
 * this filter component has the default functionalities, else you can pass custom data
 */

function FilterComponent(props: any) {
  const {
    filters,
    onButtonClick,
    clearFilters,
    initialDates,
    isCountryHide = false,
  } = props;

  const filterObject: any = {
    selectedDates: initialDates || [
      new Date(new Date().getFullYear(), 0, 1),
      new Date(new Date().getFullYear(), 11, 31),
    ],
    country: [
      {
        id: "TW",
        name: "Taiwan",
        isChecked: false,
      },
      {
        id: "AU",
        name: "Australia",
        isChecked: false,
      },
      {
        id: "US",
        name: "U.S.A",
        isChecked: false,
      },
    ],
  };

  const [filterState, setFilterState] = React.useState(filters || filterObject);

  const clearCountryFilter = () => {
    setFilterState({
      ...filterObject,
    });
  };

  function handleDateSelect(value: any) {
    setFilterState((prev: any) => ({
      ...prev,
      selectedDates: [value.selection.startDate, value.selection.endDate],
    }));
  }

  const handleCountryCheckbox = (countryValue: any, value: boolean) => {
    const newCountryArray = filterState.country.map((countryData: any) => {
      if (countryData.id === countryValue.id) {
        return {
          ...countryValue,
          isChecked: value,
        };
      } else {
        return {
          ...countryData,
          isChecked: false,
        };
      }
    });

    setFilterState((prev: any) => ({
      ...prev,
      country: [...newCountryArray],
    }));
  };

  return (
    <Container sx={{ mb: "15px" }}>
      <h4 className={styles.filterHeader}>{filterTextConst.FILTER_HEADER}</h4>
      <Divider />
      <h5 className={styles.filterCountryDateHeader}>{filterTextConst.DATE}</h5>
      <Container sx={{ display: "flex", justifyContent: "center" }}>
        <CustomDateRangePicker
          onDateSelect={(value: any) => handleDateSelect(value)}
          selectedDate={
            filterState?.selectedDates ? filterState.selectedDates : [null, null]
          }
        />
      </Container>
      {!isCountryHide && (
        <>
          <Divider />
          <h5 className={styles.filterCountryDateHeader}>{filterTextConst.COUNTRY}</h5>
          <FormGroup row>
            {Array.isArray(filterState.country) &&
              filterState.country.map((country: any, index: number) => {
                return (
                  <FormControlLabel
                    key={index}
                    sx={{ padding: "5px" }}
                    control={
                      <CheckboxComponent
                        checked={country.isChecked}
                        onChange={() =>
                          handleCountryCheckbox(country, !country.isChecked)
                        }
                      />
                    }
                    label={country.name}
                  />
                );
              })}
          </FormGroup>
        </>
      )}

      <Divider />
      <p
        className={styles.clearFilters}
        onClick={() => {
          clearFilters && clearFilters();
          clearCountryFilter();
        }}
      >
        {filterTextConst.CLEAR_FILTERS}
      </p>
      <ButtonsListComponent
        isLoading={false}
        buttonsIndexForLoader={[0]}
        buttonsArray={applyCancelButtonsList()}
        onButtonClick={(data: any) => onButtonClick(data, filterState)}
      />
    </Container>
  );
}

export { FilterComponent };
