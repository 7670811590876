import * as React from "react";
import { CircularProgress, Box, Stack, Typography } from "@mui/material";

function CircularProgressBar(props: any) {
  const { loadername } = props;
  return (
    <Stack
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      spacing={1}
    >
      <Box sx={{ position: "relative" }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: "#B2DF64",
          }}
          size={40}
          thickness={3}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: "#0D3334",
            animationDuration: "500ms",
            position: "absolute",
            left: 0,
            strokeLinecap: "round",
          }}
          size={40}
          thickness={3}
          {...props}
        />
      </Box>
      <Typography sx={{ color: "#0D3334" }}>{loadername}</Typography>
    </Stack>
  );
}

export { CircularProgressBar };
