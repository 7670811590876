/**This file contains text constants related to alert messages */

export const alertConst = {
  ADD_NEW_ADMIN_NOTE:
    "Note : Auto Generated password will be sent to their respective emails once the Admin is added.",
  DELETE_CONFIRM_HEADER: "Delete Confirmation",
  DELETE_SUB_TEXT: "Are you sure you want to delete the admin ",
  PROMOTE_CLASS_HEADER: "Promote Class",
  UNPUBLISH_CONFIRM_HEADER: "Class Cancel Confirmation",
  UNPUBLISH_SUB_TEXT: "Are you sure you want to cancel the class ",
  SELECT_IMAGE_FILE: "Please select the Image file",
  SELECT_ONLY_IMAGE_FILE: "Please select only image file (jpg, jpeg or png)",
  NO_CHANGES_FOUND: "No changes found to update the guide!",
  NO_CHANGES_FOUND_EXPLORER: "No changes found to update the explorer!",
  MISSING_COVER_IMG: "Cover Image is missing, Please upload.",
  DELETE_BROADCAST_TITLE: "Are you sure you want to delete the message",
  CITY_FIELD_CANT_EMPTY: "This field cannot be empty",
  NO_CHANGES_FOUND_IN_CLASS: "No new changes found to update the class!",
  NOTE_EMAIL_TEMPLATE_BUTTON_STYLE:
    "Note: Button ui will be similar to as of before. You can just change the text of button",
};

export const emailTemplateEditPageAlert = [
  "Button colors and texts are non editable",
  "Below tags are used for dynamic fields that are present on the email. Please maintain the same format as mentioned below",
];
