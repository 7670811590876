import { Box, Divider, Grid } from "@mui/material";
import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Form, Formik } from "formik";

import * as components from "../../../components/index";
import {
  addEditAdminsConst as editAdminConst,
  countriesList,
  userRoleList,
} from "../../../utils/text-constants";
import style from "./adminsettings.module.scss";
import {
  fetchSingleAdmin,
  handleEditAdmin,
} from "../../../utils/reusable-functions/addEditAdminFunctions";
import useMessage from "../../../hooks/customHooks/useMessage";
import { validateAdminDetails } from "../../../utils/reusable-functions/data-validation/yupValidationSchema";
import { twoButtonList } from "../../../utils/reusable-functions/buttonFunction";

const EditAdminPage = (): JSX.Element => {
  const location: any = useLocation();
  const initialValues = {
    firstName: "",
    lastName: "",
    role: "",
    countryId: "",
    email: "",
  };

  const navigate = useNavigate();
  const { setMsg } = useMessage();

  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [editPageData, setEditPageData] = React.useState(initialValues);
  const [isAdminDataLoading, setIsAdminDataLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setIsAdminDataLoading(true);
    if (location?.state?.id) {
      fetchSingleAdmin(location.state.id, setIsAdminDataLoading, setEditPageData, setMsg);
    }
  }, []);

  function handleButtonClick(data: any) {
    if (data.id === "cancel") {
      navigate(-1);
    }
  }

  const submitForm = (values: {
    firstName: string;
    lastName: string;
    role: string;
    countryId: string;
    email: string;
  }) => {
    setIsUploading(true);
    handleEditAdmin(values, location?.state?.id, setIsUploading, setMsg, navigate);
  };

  return (
    <>
      <Box p={2} mb={10}>
        {!isAdminDataLoading && (
          <Formik
            initialValues={editPageData}
            validationSchema={validateAdminDetails}
            onSubmit={(values) => submitForm(values)}
            validateOnChange
          >
            {({ errors, touched, handleChange, handleBlur, values }) => {
              return (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={6} lg={6}>
                      <components.TextInputField
                        name="email"
                        label={editAdminConst.EMAIL}
                        placeholder={editAdminConst.ENTER_EMAIL}
                        errorText={errors.email}
                        onChange={handleChange}
                        error={errors.email && touched.email}
                        onBlur={handleBlur}
                        disabled={true}
                        value={values.email}
                      />
                    </Grid>
                    <Grid item sm={12} md={3} lg={3}>
                      <components.TextInputField
                        name={"firstName"}
                        label={editAdminConst.FIRST_NAME}
                        placeholder={editAdminConst.ENTER_FIRST_NAME}
                        errorText={errors.firstName}
                        value={values.firstName}
                        onChange={handleChange}
                        error={errors.firstName && touched.firstName}
                        onBlur={handleBlur}
                        maxInputLenth={20}
                      />
                    </Grid>
                    <Grid item sm={12} md={3} lg={3}>
                      <components.TextInputField
                        name={"lastName"}
                        label={editAdminConst.LAST_NAME}
                        placeholder={editAdminConst.ENTER_LAST_NAME}
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxInputLenth={20}
                        errorText={errors.lastName}
                        error={errors.lastName && touched.lastName}
                      />
                    </Grid>
                    <Grid item sm={12} md={6} lg={6}>
                      <components.SingleSelectInputField
                        name="role"
                        label={editAdminConst.ROLE}
                        listData={userRoleList}
                        onChange={handleChange}
                        value={values.role}
                      />
                    </Grid>
                    <Grid item sm={12} md={6} lg={6}>
                      <components.SingleSelectInputField
                        name="countryId"
                        label={editAdminConst.COUNTRY}
                        listData={countriesList}
                        onChange={handleChange}
                        value={values.countryId}
                        error={errors.countryId && touched.countryId}
                        errorText={"Please select the country"}
                      />
                    </Grid>
                  </Grid>
                  <Divider sx={{ mt: 2 }} />
                  <components.ButtonsListComponent
                    isLoading={isUploading}
                    buttonsIndexForLoader={[0]}
                    buttonsArray={twoButtonList()}
                    onButtonClick={(data: any) => handleButtonClick(data)}
                  />
                </Form>
              );
            }}
          </Formik>
        )}
        {isAdminDataLoading && (
          <Box className={style.loaderContainer}>
            <components.CircularProgressBar
              loadername={editAdminConst.EDIT_ADMIN_LOADER}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default EditAdminPage;
