import * as React from "react";
import style from "./imageFileSelect.module.scss";
import uploadimage from "../../assets/images/Frame.png";

function InputImageFile(props: any) {
  const { src, handleImageChange } = props;

  return (
    <>
      <div className={style.upload}>
        <img src={src} className={style.roundImgContainer} />
        <label>
          <input
            type="file"
            style={{ display: "none" }}
            onChange={handleImageChange}
            accept={"*/images"}
          ></input>
          <img title="Edit" src={uploadimage} className={style.round} />
        </label>
      </div>
    </>
  );
}

export { InputImageFile };
