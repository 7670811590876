import { getCurrencyType } from "../../helper-functions";
import { getDateFormat } from "../dateTimeFormatFunction";
import { getCountryName } from "../getCountryName";

export const viewCouponData = (data: any, categoryIndex: number) => {
  return [
    {
      label: "Title",
      data: data.couponTitle,
    },
    ...(categoryIndex === 1
      ? [
          {
            label: "Coupon Code",
            data: data.code,
          },
        ]
      : []),
    {
      label: "Discount (%)",
      data: data.discount,
    },
    ...(categoryIndex === 1
      ? [
          {
            label: "Start Date & Time",
            data: getDateFormat(data.startDate),
          },
          {
            label: "End Date & Time",
            data: getDateFormat(data.endDate),
          },
        ]
      : []),
    ...(categoryIndex === 0
      ? [{ label: "Duration (months)", data: data.durationInMonths }]
      : []),
    {
      label: "Country",
      data: getCountryName(data.country),
    },
    {
      label: "Status",
      data: data.status,
    },
    {
      label: "Minimum Transaction Amount",
      data: `${getCurrencyType(data.country)} ${data.minAmount}`,
    },
    {
      label: "Maximum Discount",
      data: `${getCurrencyType(data.country)} ${data.maxDiscount}`,
    },
    ...(categoryIndex === 1 ? [{
      label: "Notified users via email?",
      data: data.enableEmail ? "Yes": "No"
    }]: []),
    {
      label: "Description",
      data: data.description,
    },
    {
      label: "Term & Condition",
      data: data.termsAndConditions,
      breakPoints: { sm: 12, md: 12, lg: 12 },
      uiType: "listing",
    },
  ];
};
