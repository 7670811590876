export const guideListConst = {
  DOWNLOAD_REPORT: "Download Report",
  FILTER: "Filter",
  SEARCH_GUIDE: "Search Guide",
};

export const guideDetailsConst = {
  COVER_IMAGE: "Cover image",
  OTHER_IMAGE: "Other images",
  LOCATION_IMAGES: "Location Images",
  CHECKIN_DETAILS_GUIDE: "Check In Details - Guide",
  CHECKIN_DETAILS_EXPLORER: "Check In Details - Explorer",
  LOADER_TEXT: "Guide details loading, please wait...",
};

export const guideEditConst = {};

export const userToBeCalled = [
  { label: "She/Her", value: "She/Her" },
  { label: "He/Him", value: "He/Him" },
  { label: "They/Them", value: "They/Them" },
  { label: "Other", value: "Other" },
];

export const guideReview = {
  reviewProfile : 'Review profile request for guide ',
  selectFromDropdown: 'Please select from the dropdown',
  selectFromDropdownAlert: 'Please submit after selecting from the dropdown.'
}

export const experienceReview = {
  reviewExperience: 'Review experience request for ',
  selectFromDropdown: 'Please select from the dropdown',
  selectFromDropdownAlert: 'Please submit after selecting from the dropdown.'
}