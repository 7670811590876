import * as React from "react";
import { Stack, TextField, Typography, InputAdornment } from "@mui/material";

function TextInputField(props: any) {
  const {
    label,
    name,
    type = "text",
    id,
    placeholder,
    errorText = "",
    endIcon,
    disabled = false,
    onChange,
    value,
    error = false,
    onBlur,
    onFocus,
    autoComplete = "on",
    onIconClick,
    stackStyle = { mb: "15px" },
    maxInputLenth,
    endIconTitle = "",
    endIconStyle,
    multiline = false,
    rows = 4,
    min,
    max,
    readOnly = false,
    onInputFieldClick,
    prefixText,
    onEnterKeyPress,
    fieldEndText,
    onSelect
  } = props;

  return (
    <Stack spacing={1} sx={stackStyle}>
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: "16px",
          color: disabled ? "#A19191" : "#353535",
        }}
      >
        {label}
      </Typography>
      <TextField
        id={id || `id-${name}`}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        error={error}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder}
        helperText={error ? errorText : ""}
        disabled={disabled}
        autoComplete={autoComplete}
        onSelect={onSelect}
        FormHelperTextProps={{
          style: {
            fontStyle: "italic",
            fontWeight: 400,
            fontSize: "15px",
            color: "#F73030",
            margin: "4px 0 0",
            lineHeight: "22.5px",
          },
        }}
        InputProps={{
          inputProps: {
            ...(maxInputLenth && { maxLength: maxInputLenth }),
            max,
            min,
            readOnly: readOnly || false,
          },
          style: {
            backgroundColor: disabled && "#E7E1E1",
            fontSize: "15px",
            fontWeight: 400,
            color: "#353535",
          },
          startAdornment: (
            <InputAdornment position="start" style={{ fontWeight: 700 }}>
              {prefixText}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {fieldEndText && (
                <InputAdornment position="start" style={{ fontWeight: 700 }}>
                  {fieldEndText}
                </InputAdornment>
              )}
              {endIcon && (
                <img
                  src={endIcon}
                  style={{ cursor: "pointer", ...endIconStyle }}
                  alt="icon"
                  onClick={onIconClick}
                  title={endIconTitle}
                />
              )}
            </InputAdornment>
          ),
        }}
        multiline={multiline}
        rows={rows}
        onClick={onInputFieldClick}
        onKeyPress={(e) =>
          (e.key === "Enter" || e.key === "NumpadEnter") && onEnterKeyPress(e)
        }
      />
    </Stack>
  );
}

export { TextInputField };
