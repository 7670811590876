import * as React from "react";
import Checkbox from "@mui/material/Checkbox";

function CheckboxComponent(props: any) {
  const { checked = false, onChange, name, title } = props;
  return (
    <Checkbox
      name={name}
      checked={checked}
      onChange={onChange}
      title={title}
      sx={{
        opacity: 0.7,
        color: "#353535",
        "&.Mui-checked": {
          color: "#0D3334",
          opacity: 10,
        },
      }}
    />
  );
}

export { CheckboxComponent };
