import { getDateFormat } from "./dateTimeFormatFunction";

export const getExportBookingsData = (bookingsData: any) => {
  return bookingsData.map((eachBooking: any) => {
    return {
      "Booking ID": eachBooking.bookingId ?? "NA",
      "Class ID": eachBooking.classId ?? "NA",
      "Schedule ID": eachBooking.scheduleId ?? "NA",
      "Guide ID": eachBooking.guideId ?? "NA",
      "Explorer ID": eachBooking.explorerId ?? "NA",
      "Created On": eachBooking.createdOn ? getDateFormat(eachBooking.createdOn) : "NA",
      "Updated On": eachBooking.updatedOn ? getDateFormat(eachBooking.updatedOn) : "NA",
      "Booking Status": eachBooking.bookingStatus ?? "NA",
      "Payment Status": eachBooking.paymentStatus ?? "NA",
      "Stripe Order Id": eachBooking.stripeOrderId ?? "NA",
      "Stripe Intent": eachBooking.stripeIntent ?? "NA",
      ...subData1(eachBooking),
      ...subData2(eachBooking),
    };
  });
};

function subData1(eachBooking: any) {
  return {
    "Guide Phone": getGuideDetail(eachBooking.guide, "phone") ?? "NA",
    "Guide Email": getGuideDetail(eachBooking.guide, "email") ?? "NA",
    "Guide Firstname": getGuideDetail(eachBooking.guide, "firstName") ?? "NA",
    "Guide Lastname": getGuideDetail(eachBooking.guide, "lastName") ?? "NA",
    "Class Title":
      getClassDetails(eachBooking.bookingClass, "classTitle") ?? "NA",
    "Class Start Date & Time": eachBooking.startTimeStamp
      ? getDateFormat(eachBooking.startTimeStamp)
      : "NA",
    "Class End Date & Time": eachBooking.endTimeStamp
      ? getDateFormat(eachBooking.endTimeStamp)
      : "NA",
    "Guide price": eachBooking.guidePrice,
    "Service charge": eachBooking.serviceCharge,
    Tax: eachBooking.tax,
    "Spot(s) Booked": eachBooking?.seatCount ? eachBooking?.seatCount : 1,
    "Total amount": eachBooking.total,
    Currency: eachBooking.priceCurrency,
  };
}

function subData2(eachBooking: any) {
  return {
    "Discount Applied (%)":
      eachBooking.discount !== undefined && eachBooking.discount !== null
        ? eachBooking.discount
        : "NA",
    "Coupon Discount":
      eachBooking.deductedAmount !== null && eachBooking.deductedAmount !== undefined
        ? eachBooking.deductedAmount
        : "NA",
    Subtotal:
      eachBooking.subTotal !== null && eachBooking.subTotal !== undefined
        ? eachBooking.subTotal
        : "NA",
    "Coupon code": eachBooking.code ?? "NA",
  };
}

function getGuideDetail(guideList: any[] = [], dataAttribute = "") {
  if (Array.isArray(guideList) && guideList?.length) {
    return guideList[0][dataAttribute] ?? "NA";
  } else {
    return "NA";
  }
}

function getClassDetails(bookingClass: any[], classAttribute: string) {
  if (Array.isArray(bookingClass) && bookingClass?.length) {
    return bookingClass.map((details: any) => details[classAttribute])?.join(", ");
  } else {
    return "";
  }
}
