import * as React from "react";
import style from "./imageFileSelect.module.scss";

function CustomFileInput(props: any) {
  const { handleImageChange, fileInputContainerClassName, label } = props;
  return (
    <label id="input">
      <input
        type="file"
        style={{ display: "none" }}
        onChange={handleImageChange}
        accept={"*/images"}
      ></input>
      <div className={`${style.customFileInputContainer} ${fileInputContainerClassName}`}>
        {label || "Replace Image"}
      </div>
    </label>
  );
}

export { CustomFileInput };
