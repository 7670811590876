import * as Yup from "yup";
import { errorConst } from "../../text-constants";

export const validateAdminDetails = () =>
  Yup.object({
    firstName: Yup.string()
      .trim()
      .required(errorConst.FIRST_NAME_REQUIRED)
      .min(2, errorConst.FULL_NAME_MIN_TWO_CHAR),
    role: Yup.string().required(),
    countryId: Yup.string().required(),
    email: Yup.string()
      .email(errorConst.ENTER_VALID_EMAIL)
      .required(errorConst.EMAIL_ID_REQUIRED),
    lastName: Yup.string()
      .trim()
      .required(errorConst.LAST_NAME_REQUIRED)
      .min(1, errorConst.LAST_NAME_MIN_TWO_CHAR),
  });
