import { Stack, Typography } from "@mui/material";
import * as React from "react";

import { IMAGES } from "../../assets/images";
import { ImageComponent } from "..";
import style from "./taglist.module.scss";

function SimpleTag(props: any) {
  const { text, prefixText, isDeleteIconPresent = false, onDeleteClick, title } = props;
  return (
    <Stack className={style.tagContainer} direction="row" spacing={2.5}>
      <Typography className={style.font} title={`${prefixText || ""} ${text}`}>
        {`${prefixText || ""}${text}`}
      </Typography>
      {isDeleteIconPresent && (
        <ImageComponent
          image={IMAGES.crossRedIcon}
          className={style.crossIcon}
          onClick={onDeleteClick}
          title={title || "Remove tag"}
        />
      )}
    </Stack>
  );
}

export { SimpleTag };
