import * as React from "react";
import { Dialog } from "@mui/material";

function SimpleDialogue(props: any) {
  const { openPopup, dialogInlineStyle } = props;
  return (
    <Dialog
      onClose={() => {
        return;
      }}
      open={openPopup}
      PaperProps={{
        style: { borderRadius: "8px", ...dialogInlineStyle},
      }}
    >
      {props.children}
    </Dialog>
  );
}

export { SimpleDialogue };
