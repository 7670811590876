import * as React from "react";
import { Box, Grid, Stack } from "@mui/material";

import {
  addPromotedClass,
  getClassList,
  getPromotedClassList,
  promotedClassSwapSequence,
} from "../../controllers/promotedClassController";
import * as components from "../../components/index";
import PromotionClassFormComponent from "./PromotionClassFormComponent";
import PromotedClassConfirmationComponent from "./PromotedClassConfirmationComponent";
import { adminsLisingConst } from "../../utils/text-constants/adminSettingsConstants";
import { getDateFormat } from "../../utils/reusable-functions/dateTimeFormatFunction";
import { getSelectedFilters } from "../../utils/reusable-functions/filterFunctions";
import {
  handleDropFunction,
  handleOnDragFunction,
} from "../../utils/reusable-functions/dragDropFunction";
import { IMAGES } from "../../assets/images";
import { checkStringLength } from "../../utils/reusable-functions/dataLengthCheck";
import style from "./promoted-class.module.scss";

const columns = [
  { headerName: "No.", field: "id", width: 50 },
  { headerName: "Class ID", field: "class_id", width: 150 },
  {
    headerName: "Title",
    field: "class_title",
    width: 330,
    renderCell: (params: any) => (
      <p title={params.row.class_title}>{checkStringLength(params.row.class_title)}</p>
    ),
  },
  {
    headerName: "Guide Name",
    field: "guide_name",
    width: 290,
    renderCell: (params: any) => (
      <p title={params.row.guide_name}>{checkStringLength(params.row.guide_name, 25)}</p>
    ),
  },
  {
    headerName: "City",
    field: "city",
    width: 200,
    renderCell: (params: any) => (
      <p title={params.row.city}>{checkStringLength(params.row.city, 20)}</p>
    ),
  },
  { headerName: "Sequence No.", field: "sequence_no", width: 200 },
  {
    headerName: "Scheduled Date",
    field: "schedule_start_datetime",
    minWidth: 220,
  },
];

type IPromotedClassListComponentProps = { countryId: string };
const PromotedClassListComponent: React.FC<IPromotedClassListComponentProps> = ({
  countryId,
}) => {
  const [searchText, setSearchText] = React.useState("");
  const [openFilter, setOpenFilter] = React.useState<boolean>(false);
  const [selectedFilters, setSelectedFilters] = React.useState();
  const [selectedRow, setSelectedRow] = React.useState<any>();
  const [promotedClassList, setPromotedClassList] = React.useState<any[]>([]);
  const [promotedClassCount, setPromotedClassCount] = React.useState<number>(0);
  const [selectionModel, setSelectionModel] = React.useState<any>([]);
  const [dragBoxId, setDragBoxId] = React.useState(null);
  const [openPromotionForm, setOpenPromotionForm] = React.useState<boolean>(false);
  const [openConfirmationModel, setOpenConfirmationModel] =
    React.useState<boolean>(false);
  const [sequenceNo, setSequenceNo] = React.useState([]);

  const fixedSeqNo = [1, 2, 3, 4, 5];

  const [pageState, setPageState] = React.useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 10,
    countryId: countryId,
    firstClassCreatedOn: "",
    latestScheduledClassStartTime: "",
  });

  React.useEffect(() => {
    getPaginateData(pageState.page, pageState.pageSize);
  }, [pageState.page, pageState.pageSize, countryId, searchText, selectedFilters]);

  React.useEffect(() => {
    getPromotedClassData(countryId);
  }, [countryId]);

  const getPaginateData = async (page: any, pageSize: any) => {
    setPageState({ ...pageState, isLoading: true });

    getClassList(
      (response: any) => {
        const sequenceArray: number[] = [];
        const data = response.data.map((item: any, i: number) => {
          item.sequence_no &&
            item.is_promoted_class &&
            sequenceArray.push(item.sequence_no);

          const id = i + 1 + page * pageSize;
          item.sequence_no = item.is_promoted_class ? item.sequence_no : "";
          item.scheduleStart = item.schedule_start_datetime;
          item.schedule_start_datetime = item.schedule_start_datetime
            ? getDateFormat(item.schedule_start_datetime)
            : "";

          return { ...item, id };
        });
        setSequenceNo(sequenceArray);

        if (response.promotedClassCount) {
          setPromotedClassCount(response.promotedClassCount);
        } else {
          setPromotedClassCount(0);
        }
        setPageState({
          ...pageState,
          isLoading: false,
          data,
          total: response.count,
          latestScheduledClassStartTime: response.latestScheduledClassStartTime,
          firstClassCreatedOn: response.firstClassCreatedOn,
        });
        setSelectionModel(
          data
            .filter((row: any) => row.is_promoted_class === true)
            .map((row: any) => row.id)
        );
      },
      () => {
        console.log("error handler");
      },
      {
        skip: page + 1,
        limit: pageSize,
        countryId,
        module: "promoted",
        ...(searchText && { search: searchText }),
        ...getSelectedFilters(selectedFilters),
      }
    );
  };

  const getPromotedClassData = async (countryId1: any) => {
    getPromotedClassList(
      (response: any) => {
        const classList: any[] = response.data.sort((a: any, b: any) => {
          return a.sequence_no - b.sequence_no;
        });
        setPromotedClassList(classList);
      },
      (err: any) => {
        console.log(err);
      },
      { countryId: countryId1 }
    );
  };

  const handleFilterClick = () => {
    setOpenFilter((prev) => !prev);
  };

  const handleSearchChange = (event: any) => {
    setSearchText(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  const handleSearchClick = () => {
    console.log("searchText", searchText);
  };

  const onApplyOrCancelButtonClick = (data: any, filterData: any) => {
    if (data.id === "apply") {
      setSelectedFilters({ ...filterData });
    }
    setOpenFilter(false);
  };

  const handlePromotionFormSubmit = () => {
    getPaginateData(pageState.page, pageState.pageSize);
    getPromotedClassData(pageState.countryId);
  };

  const handleConfirmClick = (data: any) => {
    if (data && data.id === "id-Confirm") {
      addPromotedClass(
        (res: any) => {
          console.log("addPromotedClass: Success", res);
          getPaginateData(pageState.page, pageState.pageSize);
          getPromotedClassData(pageState.countryId);
          setOpenConfirmationModel(false);
        },
        (err: any) => {
          console.log("addPromotedClass: Error", err);
        },
        { classId: selectedRow.class_id, promote: false }
      );
    } else {
      setOpenConfirmationModel(false);
    }
  };

  const handleCloseConfirmationModel = () => {
    setOpenConfirmationModel(false);
  };

  function getDropdownData() {
    const sequenceDropdownArray: any[] = [];
    fixedSeqNo.forEach(
      (sNo: any) =>
        !sequenceNo.includes(sNo) &&
        sequenceDropdownArray.push({ label: sNo, value: sNo })
    );
    return [{ label: "Select Carousel Position", value: "" }, ...sequenceDropdownArray];
  }

  return (
    <>
      <Box sx={{ display: "flex", margin: "10px 0" }}>
        <Grid container spacing={1}>
          <Grid item>
            <Stack spacing={2} direction="row" flexWrap="wrap">
              {promotedClassList.map((classItem: any) => {
                return (
                  <components.PromoBannerComponent
                    data={{
                      imageUrl: classItem.cover_image_url,
                      text1: classItem.class_title,
                      text2: `Started on : ${classItem.promoted_start_date}`,
                      text3: `Ends on: ${classItem.promoted_end_date}`,
                      text4: `Location : ${classItem.city}, ${classItem.country}`,
                    }}
                    handleDrag={() => {
                      handleOnDragFunction(classItem.class_id, setDragBoxId);
                    }}
                    handleDrop={() => {
                      const params = {
                        swapClassId: classItem.class_id,
                        withClassId: dragBoxId,
                      };

                      promotedClassSwapSequence(
                        (res: any) => {
                          console.log("res", res);
                        },
                        (err) => {
                          console.log("err", err);
                        },
                        params
                      );

                      handleDropFunction(
                        classItem.class_id,
                        promotedClassList,
                        setPromotedClassList,
                        dragBoxId
                      );
                    }}
                  />
                );
              })}
            </Stack>
          </Grid>

          <Grid item xs={12}>
            {promotedClassList.length > 0 && (
              <p className={style.bannerSubText}>Maximam 5 classes can be added</p>
            )}
            {promotedClassList.length === 0 && (
              <p className={style.bannerSubText}>No class selected for promotion</p>
            )}
            <hr />
          </Grid>
          <Grid item md={12} lg={12} justifyContent="end" display={"flex"}>
            <Stack direction="row" spacing={1}>
              <components.SearchInputField
                value={searchText}
                onChange={handleSearchChange}
                onSearchClick={handleSearchClick}
                onClearClick={handleClearSearch}
              />
              <components.LoadingButtonComponent
                buttonLabel={adminsLisingConst.FILTER}
                handleClick={handleFilterClick}
                endIcon={<img src={IMAGES.filterIcon} />}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <components.DataGridComponent
              columns={columns}
              rows={pageState.data}
              rowCount={pageState.total}
              loading={pageState.isLoading}
              page={pageState.page}
              pageSize={pageState.pageSize}
              selectAllCheckbox={false}
              checkboxSelection
              selectionModel={selectionModel}
              isRowSelectable={() => promotedClassCount < 5}
              onCellClick={(params: any) => {
                if (params.field === "__check__") {
                  if (params.row && params.row.is_promoted_class === true) {
                    setSelectedRow(params.row);
                    setOpenPromotionForm(false);
                    setOpenConfirmationModel(true);
                  } else if (promotedClassCount < 5) {
                    setSelectedRow(params.row);
                    setOpenPromotionForm(true);
                    setOpenConfirmationModel(false);
                  }
                }
              }}
              onPageChange={(page: any) => {
                setPageState({ ...pageState, page });
              }}
              onPageSizeChange={(pageSize: any) => {
                setPageState({ ...pageState, pageSize });
              }}
            />
          </Grid>
        </Grid>
      </Box>
      {openPromotionForm && (
        <PromotionClassFormComponent
          dropdownbox={getDropdownData() || []}
          item={selectedRow}
          openPopup={openPromotionForm}
          setOpenPopup={setOpenPromotionForm}
          onButtonClick={handlePromotionFormSubmit}
        />
      )}
      {openConfirmationModel && (
        <PromotedClassConfirmationComponent
          openPopup={openConfirmationModel}
          promotedClassName={selectedRow.class_title}
          handleConfirmClick={handleConfirmClick}
          handleClose={handleCloseConfirmationModel}
        />
      )}
      {openFilter && (
        <components.SideDrawer
          openState={openFilter}
          onCloseDrawer={() => setOpenFilter(!openFilter)}
        >
          <components.FilterComponent
            isCountryHide={true}
            initialDates={
              pageState.firstClassCreatedOn
                ? [
                    new Date(pageState.firstClassCreatedOn) ||
                      new Date(new Date().getFullYear(), 0, 1),
                    new Date(pageState.latestScheduledClassStartTime),
                  ]
                : ""
            }
            filters={selectedFilters}
            setFilters={setSelectedFilters}
            onButtonClick={(data: any, filterData: any) =>
              onApplyOrCancelButtonClick(data, filterData)
            }
          />
        </components.SideDrawer>
      )}
    </>
  );
};

export default PromotedClassListComponent;
