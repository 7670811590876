import { Divider, Grid, Stack, Typography } from "@mui/material";
import * as React from "react";

import ClassDataSummaryComponent from "./data-view/ClassDataSummaryComponent";
import UserDataSummaryComponent from "./data-view/UserDataSummaryComponent";
import {
  FilterComponent,
  SideDrawer,
  LoadingButtonComponent,
  SearchInputField,
  TabComponent,
} from "../../components";
import { dashboardConst } from "../../utils/text-constants";
import { IMAGES } from "../../assets/images";
import { exportDataApiCalls } from "../../utils/reusable-functions/exportDataFunction";
import style from "./dashboard.module.scss";
import {
  getFilteredDate,
  getSelectedFilters,
} from "../../utils/reusable-functions/filterFunctions";
import BookingsDataSummaryComponent from "./data-view/BookingsDataSummaryComponent";
import { getSearchPlaceholderText } from "../../utils/helper-functions";

type DataSummaryViewComponentProps = {
  onChangeView: (viewType: string) => void;
};

const DataSummaryViewComponent: React.FC<DataSummaryViewComponentProps> = ({
  onChangeView,
}) => {
  const [value, setValue] = React.useState(0);
  const [openFilter, setOpenFilter] = React.useState<boolean>(false);
  const [isReportDownloading, setIsReportDownloading] = React.useState<boolean>(false);
  const [selectedFilters, setSelectedFilters] = React.useState<any>(null);
  const [searchText, setSearchText] = React.useState<string>("");
  const [search, setSearch] = React.useState<string>("");

  const handleChange = (newValue: number) => {
    setValue(newValue);
    setSelectedFilters(null);
    setSearchText("");
    setSearch("");
  };

  const handleViewChange = (event: any) => {
    onChangeView("graphView");
  };

  const handleDownloadReport = () => {
    let filterOptions: any = {
      module: "dashboard",
    };
    if (selectedFilters) {
      filterOptions = getSelectedFilters(selectedFilters);
    }
    if (searchText?.toString()?.trim()) {
      filterOptions = {
        ...filterOptions,
        search: searchText,
      };
    }
    if (value === 0) {
      exportDataApiCalls("class", setIsReportDownloading, filterOptions);
    } else if (value === 1) {
      exportDataApiCalls("users", setIsReportDownloading, filterOptions);
    } else if (value === 2) {
      exportDataApiCalls("bookings", setIsReportDownloading, filterOptions);
    }
  };

  const handleFilterClick = () => {
    setOpenFilter(!openFilter);
  };

  const onApplyOrCancelButtonClick = (data: any, filterData: any) => {
    if (data.id === "apply") {
      setSelectedFilters({ ...filterData });
    }
    setOpenFilter(false);
  };

  const handleSearch = (event: { target: { value: React.SetStateAction<string> } }) => {
    setSearchText(event.target.value);
    if (event.target.value === "") {
      setSearch("");
    }
  };

  const handleSearchClick = () => {
    setSearch(searchText);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setSearch("");
  };

  return (
    <>
      <Grid
        container
        p={1}
        pl={3}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid
          item
          paddingRight={10}
          lg={7}
          md={6}
          sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <Typography className={style.switchToDataSummery} onClick={handleViewChange}>
            {dashboardConst.SWITCH_TO_GRAPH}
          </Typography>
          <LoadingButtonComponent
            isLoading={isReportDownloading}
            handleClick={handleDownloadReport}
            buttonLabel={dashboardConst.DOWNLOAD_REPORT}
            endIcon={<img src={IMAGES.downloadReportIcon} />}
          />
        </Grid>
        <Grid item lg={5} md={6} sx={{ display: "flex", justifyContent: "end" }}>
          <Stack spacing={1} direction={"row"}>
            <SearchInputField
              value={searchText}
              onChange={handleSearch}
              onSearchClick={handleSearchClick}
              onClearClick={handleClearSearch}
              placeholder={getSearchPlaceholderText(value)}
            />
            <LoadingButtonComponent
              buttonLabel={dashboardConst.FILTER}
              handleClick={handleFilterClick}
              endIcon={<img src={IMAGES.filterIcon} />}
            />
          </Stack>
        </Grid>
      </Grid>
      <Divider sx={{ padding: "2px", ml: 3 }} />
      <Grid container sx={{ paddingTop: "10px" }}>
        <Typography variant="h3" className={`${style.yearText} ${style.marginLeft}`}>
          {getFilteredDate(selectedFilters)}
        </Typography>
        <Grid item xs={12} paddingTop={"10px"}>
          <TabComponent
            tabsData={[
              {
                label: "Classes",
                tabComponent: (
                  <ClassDataSummaryComponent
                    filterOptions={selectedFilters}
                    searchText={search}
                  />
                ),
              },
              {
                label: "Users",
                tabComponent: (
                  <UserDataSummaryComponent
                    filterOptions={selectedFilters}
                    searchText={search}
                  />
                ),
              },
              {
                label: "Bookings",
                tabComponent: (
                  <BookingsDataSummaryComponent
                    filterOptions={selectedFilters}
                    searchText={search}
                  />
                ),
              },
            ]}
            currentTabIndexValue={value}
            setCurrentTabIndexValue={setValue}
            handleTabChange={(newTabValue: number) => handleChange(newTabValue)}
          />
        </Grid>
      </Grid>
      {openFilter && (
        <SideDrawer
          openState={openFilter}
          onCloseDrawer={() => setOpenFilter(!openFilter)}
        >
          <FilterComponent
            filters={selectedFilters}
            onButtonClick={(data: any, filterData: any) =>
              onApplyOrCancelButtonClick(data, filterData)
            }
          />
        </SideDrawer>
      )}
    </>
  );
};

export default DataSummaryViewComponent;
