import { Divider, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import {
  handleBroadCastSubmit,
  handleCategoryCheckboxClick,
  handleChangeCategory,
  handleCitiySelectButtonClick,
  handleCityDeleteClick,
  handleCitySelectAllCheckbox,
  handleCitySelectButtonClick,
  handleCountryChange,
  handleDeleteLocation,
  initialCreateBroadCastData,
} from "../../../utils/reusable-functions/broadcast-messages/createBroadcast";
import { twoButtonList } from "../../../utils/reusable-functions/buttonFunction";
import { validateCreateBroadcast } from "../../../utils/reusable-functions/data-validation/broadcastValidation";
import {
  classCategoryList,
  countriesList,
  createBroadCastConst,
} from "../../../utils/text-constants";
import useMessage from "../../../hooks/customHooks/useMessage";
import * as components from "../../../components";
import { IMAGES } from "../../../assets/images";

export default function AddBroadcastMessage() {
  const navigate = useNavigate();
  const { setMsg } = useMessage();

  const [openPopup, setOpenPopup] = React.useState({
    citySelect: false,
    locationIndex: null,
    countryId: "",
  });

  const [isUploading, setIsUploading] = React.useState<boolean>(false);

  function handleAddMoreCountryClick(setFieldValue: any, values: any) {
    const addedLocations: any[] = [
      ...values.location,
      {
        countriesList: countriesList,
        countryId: getSelectedCountry(values)[0].value,
        countryName: getSelectedCountry(values)[0].label,
        city: [],
      },
    ];
    setFieldValue("location", addedLocations);
  }

  function getSelectedCountry(values: any) {
    return countriesList.filter(
      (staticCountry: any) =>
        !values.location?.some(
          (selectedLoc: any) => selectedLoc.countryId === staticCountry.value
        )
    );
  }

  return (
    <Formik
      initialValues={JSON.parse(JSON.stringify(initialCreateBroadCastData))}
      validationSchema={validateCreateBroadcast}
      onSubmit={(values: any) =>
        handleBroadCastSubmit(values, setMsg, navigate, setIsUploading)
      }
      validateOnChange
    >
      {({ errors, touched, handleChange, handleBlur, values, setFieldValue }: any) => {
        return (
          <Form>
            <Grid container spacing={2} p={2.5} pl={3}>
              <Grid item sm={12} md={6} lg={6}>
                <components.TextInputField
                  name={"messageTitle"}
                  label={createBroadCastConst.MESSAGE_TITLE_48_CHAR}
                  placeholder={createBroadCastConst.ENTER_MESSAGE_TITLE}
                  errorText={errors.messageTitle}
                  onChange={handleChange}
                  error={errors.messageTitle && touched.messageTitle}
                  onBlur={handleBlur}
                  maxInputLenth={48}
                  value={values.messageTitle}
                />
              </Grid>
              <Grid item sm={12} md={12} lg={12}>
                <components.TextInputField
                  name={"messageBody"}
                  label={createBroadCastConst.MESSAGE_BODY_90_CHAR}
                  placeholder={createBroadCastConst.ENTER_MESSAGE}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxInputLenth={90}
                  errorText={errors.messageBody}
                  error={errors.messageBody && touched.messageBody}
                  multiline
                  rows={3}
                  value={values.messageBody}
                />
              </Grid>
              <Grid item sm={12} md={12} lg={12}>
                <components.MultiSelectInputField
                  id={"id"}
                  name={"classCategories"}
                  label={createBroadCastConst.SELECT_CLASS_CATEGORY}
                  placeholder={createBroadCastConst.SELECT_CLASS_CATEGORY}
                  errorText={errors.classCategories}
                  error={errors.classCategories && touched.classCategories}
                  onBlur={handleBlur}
                  value={values.classCategories}
                  listData={classCategoryList.map(
                    (classCategory: any) => classCategory.value
                  )}
                  handleChange={(e: any) =>
                    handleChangeCategory(
                      e?.target?.value,
                      setFieldValue,
                      classCategoryList
                    )
                  }
                />
                <components.CheckboxAndText
                  text={createBroadCastConst.SELECT_ALL_CLASS_CATEGORIES}
                  isChecked={values.isSelectedAllCategory}
                  onChange={(event: any) =>
                    handleCategoryCheckboxClick(
                      event.target.checked,
                      setFieldValue,
                      classCategoryList
                    )
                  }
                />
                <Divider sx={{ margin: "15px 0px" }} />
              </Grid>

              <Grid item sm={12} lg={12} md={12}>
                <Typography sx={{ fontWeight: 600, fontSize: "20px" }}>
                  {createBroadCastConst.TARGETED_LOCATION}
                </Typography>
              </Grid>
              {values.location?.map((eachLocation: any, index: number) => {
                return (
                  <Grid item sm={12} lg={12} md={12}>
                    <components.TargetedLocationComponent
                      text1={`Location ${index + 1}`}
                      error={
                        errors?.location &&
                        errors?.location[index]?.city &&
                        !eachLocation.isSelectAll
                      }
                      errorText={
                        errors?.location &&
                        errors?.location[index]?.city &&
                        !eachLocation.isSelectAll
                      }
                      isDeleteIconVisible={index === 0 ? false : true}
                      eachLocation={eachLocation}
                      singleSelectField={{
                        name: eachLocation.name,
                        label: "Country",
                        listData: eachLocation.countriesList,
                        value: eachLocation.countryId,
                        onChange: (e: any) => {
                          handleCountryChange(e, values, setFieldValue, index, setMsg);
                        },
                      }}
                      handleAddCitiesClick={() =>
                        handleCitySelectButtonClick(setOpenPopup, index, eachLocation)
                      }
                      handleSelectAllCitiesClick={(e: any) =>
                        handleCitySelectAllCheckbox(
                          e.target.checked,
                          setFieldValue,
                          index
                        )
                      }
                      onCityDeleteClick={(data: any) =>
                        handleCityDeleteClick(data, setFieldValue, index, values)
                      }
                      handleDeleteLocationClick={() =>
                        handleDeleteLocation(index, setFieldValue, values)
                      }
                    />
                  </Grid>
                );
              })}
              {values.location.length < 3 && (
                <Grid item sm={12} lg={12} md={12}>
                  <components.LoadingButtonComponent
                    isLoading={false}
                    buttonLabel={"Add More"}
                    handleClick={() => handleAddMoreCountryClick(setFieldValue, values)}
                    endIcon={<img src={IMAGES.addIcon} />}
                  />
                </Grid>
              )}
              <Grid item sm={12} lg={12} md={12}>
                <Divider />
              </Grid>
              <Grid item sm={12} lg={12} md={12}>
                <components.CheckboxAndText
                  name={"isScheduleSend"}
                  text={createBroadCastConst.SEND_SCHEDULE}
                  isChecked={values.isScheduleSend}
                  onChange={handleChange}
                />
              </Grid>
              {values.isScheduleSend && (
                <Grid item sm={12} lg={6} md={6}>
                  <components.DateTimePickerComponent
                    name="scheduleDate"
                    onBlur={handleBlur}
                    value={values.scheduleDate}
                    onChange={(value: any) => setFieldValue("scheduleDate", value)}
                    error={errors.scheduleDate && touched.scheduleDate}
                    errorText={errors.scheduleDate}
                    minDate={new Date()}
                  />
                </Grid>
              )}
              <Grid item sm={12} lg={12} md={12}>
                <Divider />
                <components.ButtonsListComponent
                  isLoading={isUploading}
                  buttonsIndexForLoader={[0]}
                  buttonsArray={twoButtonList("Send", "Cancel")}
                  onButtonClick={(data: any) => {
                    if (data.id === "id-Cancel") {
                      navigate(-1);
                    }
                  }}
                />
              </Grid>
            </Grid>
            {openPopup.citySelect && (
              <components.CitySelectDialog
                isMultiSelect
                maxCount={30}
                subHeading={createBroadCastConst.CITY_SELECT_DIALOG_SUB_TEXT}
                selectedCityId={values.location[openPopup.locationIndex]?.city || ""}
                countryId={openPopup.countryId || ""}
                openPopup={openPopup.citySelect}
                handleButtonClick={(id: string, data: any) =>
                  handleCitiySelectButtonClick(
                    id,
                    data,
                    setOpenPopup,
                    setFieldValue,
                    openPopup
                  )
                }
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
}
