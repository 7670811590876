import { Box, Divider, Grid, Typography } from "@mui/material";
import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik, Form } from "formik";

import * as components from "../../../components/index";
import { emailTemplateEditButtons } from "../../../utils/reusable-functions/buttonFunction";
import {
  handleButtonClick,
  handleEmailTemplateUpdateClick,
} from "../../../utils/reusable-functions/email-templates/editEmailPageFunctions";
import { validateTemplateDetails } from "../../../utils/reusable-functions/data-validation/editEmailTemplateValidation";
import { fetchEmailTemplateDetailsController } from "../../../controllers/emailTemplateController";
import style from "./editEmailTemplate.module.scss";
import {
  errorConst,
  emailTemplateConst,
  emailTemplateEditPageAlert,
} from "../../../utils/text-constants";
import useMessage from "../../../hooks/customHooks/useMessage";
import { showToast } from "../../../utils/helper-functions/helperFunction";

export default function EditEmailTemplatesPage() {
  const location: any = useLocation();
  const { setMsg } = useMessage();

  const [isUpdating, setIsUpdating] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const navigate = useNavigate();

  function handleButtonClickFunction(data1: any, values: any) {
    handleButtonClick(
      data1,
      navigate,
      setIsUpdating,
      values,
      location.state?.id,
      setMsg,
      data
    );
  }

  React.useEffect(() => {
    setLoading(true);
    fetchEmailTemplateDetailsController(
      (response: any) => {
        setData(response);
        setLoading(false);
      },
      (error: any) => {
        showToast(
          setMsg,
          error.message || error.data.message || errorConst.UNKNOW_ERROR,
          "error"
        );
      },
      location.state?.id
    );
  }, []);

  return (
    <>
      {!loading && data && (
        <Formik
          initialValues={data}
          validationSchema={validateTemplateDetails}
          onSubmit={(values) => {
            handleEmailTemplateUpdateClick(
              values,
              location.state?.id,
              setMsg,
              setIsUpdating,
              navigate,
              data
            );
          }}
          validateOnChange
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
          }: any) => {
            return (
              <Form>
                <Grid container p={2.5}>
                  <Grid item sm={12} md={12} lg={12}>
                    <components.TextInputField
                      name={"templateName"}
                      label={emailTemplateConst.TEMPLATE_NAME}
                      placeholder={emailTemplateConst.TEMPLATE_NAME}
                      value={values.templateName}
                      errorText={errors.templateName}
                      onChange={handleChange}
                      error={errors.templateName && touched.templateName}
                      onBlur={handleBlur}
                      stackStyle={{ mb: "25px" }}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item sm={12} md={12} lg={12}>
                    <components.TextInputField
                      name={"templateSubject"}
                      label={emailTemplateConst.TEMPLATE_SUBJECT}
                      placeholder={emailTemplateConst.TEMPLATE_SUBJECT}
                      value={values.templateSubject}
                      errorText={errors.templateSubject}
                      onChange={handleChange}
                      error={errors.templateSubject && touched.templateSubject}
                      onBlur={handleBlur}
                      stackStyle={{ mb: "25px" }}
                    />
                  </Grid>
                  <Grid item sm={12} md={12} lg={12} spacing={2}>
                    <Typography className={style.templateBodyText}>
                      {emailTemplateConst.TEMPLATE_BODY}
                    </Typography>
                    <div className={style.textEditorContainer}>
                      <components.TextEditorComponent
                        values={values.templateBody}
                        handleChange={(value: any) => {
                          setFieldValue("templateBody", value);
                        }}
                      />
                    </div>
                    <div className={style.instructionsContainer}>
                      Note:
                      {
                        <ul>
                          {emailTemplateEditPageAlert.map((alert: any) => {
                            return <li style={{ marginBottom: "10px" }}>{alert}</li>;
                          })}
                        </ul>
                      }
                    </div>
                  </Grid>
                  {Array.isArray(values.templateVariables) &&
                  values.templateVariables?.length ? (
                    <div style={{ marginTop: "10px" }}>
                      <components.EmailTagsList
                        emailTagsListArray={values.templateVariables}
                        onClick={(text: any) => {
                          navigator.clipboard.writeText(`{{${text}}}`);
                        }}
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <Grid item sm={12} md={12} lg={12} mt={3}>
                    <Divider />
                  </Grid>
                  <components.ButtonsListComponent
                    isLoading={isUpdating}
                    buttonsIndexForLoader={[0, 1]}
                    buttonsArray={emailTemplateEditButtons()}
                    onButtonClick={(data3: any) =>
                      handleButtonClickFunction(data3, values)
                    }
                  />
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}
      {loading && !data && (
        <Box className={style.progressBarContainer}>
          <components.CircularProgressBar loadername={"Loading data, please wait..."} />
        </Box>
      )}
    </>
  );
}
