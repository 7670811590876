export const adminsLisingConst = {
  ADD_NEW_ADMIN: "Add New Admin",
  FILTER: "Filter",
  CHANGE_PASSWORD: "Change Password",
};

export const addEditAdminsConst = {
  FIRST_NAME: "First Name*",
  LAST_NAME: "Last Name*",
  ROLE: "Role",
  COUNTRY: "Country",
  EMAIL: "Email*",
  ENTER_FIRST_NAME: "Enter first name",
  ENTER_LAST_NAME: "Enter last name",
  SELECT_ROLE: "Select Role",
  SELECT_COUNTRY: "Select Country",
  ENTER_EMAIL: "Enter Email",
  EDIT_ADMIN_LOADER: "Loading admin details, please wait...",
  SEQUENCE_NUMBER: "Sequence Number",
  POSITION: "Carousel Position*",
  START_DATE: "Start Date & Time*",
  END_DATE: "End Date & Time*",
};

export const countriesList = [
  // { label: "Taiwan", value: "TW" },
  // { label: "Australia", value: "AU" },
  { label: "USA", value: "US" },
];

export const userRoleList = [{ label: "Admin", value: "admin" }];

export const promotionClassSquenceList: any[] = [
  { label: "Select Carousel Position", value: "" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
]
