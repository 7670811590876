import { Grid } from "@mui/material";
import * as React from "react";
import * as components from "../../components";

function CheckForInputFields(props: any) {
  const {
    inputFieldType,
    label,
    placeholder,
    errorText,
    onChange,
    error,
    handleBlur,
    isDisabled,
    value,
    name,
    listData,
    onInputFieldClick,
    readOnly,
    isFieldVisible,
    tagsListArray = [],
    onIconClick,
    endIcon,
    eachField,
    isMultilineInput,
    onTagDelete
  } = props;

  return (
    <>
      {isFieldVisible && (
        <Grid
          item
          sm={eachField?.gridBreakpoints?.sm || 12}
          lg={eachField?.gridBreakpoints?.lg || 6}
          md={eachField?.gridBreakpoints?.md || 6}
        >
          {inputFieldType === "SIMPLE" && (
            <components.TextInputField
              id={name}
              name={name}
              label={label}
              placeholder={placeholder}
              onChange={onChange}
              error={error}
              errorText={error && errorText}
              onBlur={handleBlur}
              disabled={isDisabled}
              value={value}
              onInputFieldClick={onInputFieldClick}
              readOnly={readOnly}
              onIconClick={onIconClick}
              endIcon={endIcon}
              multiline={isMultilineInput}
              maxInputLenth={eachField?.maxCharacter}
            />
          )}
          {inputFieldType === "SINGLE_SELECT" && (
            <components.SingleSelectInputField
              id={name}
              name={name}
              label={label}
              listData={listData}
              onChange={onChange}
              value={value}
              error={error}
              errorText={errorText}
            />
          )}
          {inputFieldType === "MULTI_SELECT" && (
            <components.MultiSelectInputField
              id={name}
              name={name}
              label={label}
              placeholder={placeholder}
              errorText={errorText}
              handleChange={onChange}
              error={error}
              onBlur={handleBlur}
              listData={listData}
              value={value}
            />
          )}
          {tagsListArray?.length > 0 && (
            <div style={{ marginTop: "10px" }}>
              <components.SimpleTagList
                tagsList={tagsListArray}
                isDeleteIconPresent={true}
                onDeleteClick={(tag: any) => onTagDelete(tag)}
              />
            </div>
          )}
        </Grid>
      )}
    </>
  );
}

export { CheckForInputFields };
