import * as React from "react";
import { IMAGES } from "../../assets/images";
import style from "./imageFileSelect.module.scss";

function ImageFileInput(props: any) {
  const { className, handleImageChange } = props;
  return (
    <label>
      <input
        onChange={handleImageChange}
        style={{ display: "none" }}
        type="file"
        accept={"*/images"}
      />
      <div className={`${style.fileInputContainer} ${className}`}>
        <img src={IMAGES.uploadIcon} />
        <p>Add Image</p>
      </div>
    </label>
  );
}

export { ImageFileInput };
