import * as Yup from "yup";
import { errorConst } from "../../text-constants";

export const validateCreateBroadcast = () =>
  Yup.object({
    messageTitle: Yup.string()
      .trim()
      .required(errorConst.BROADCAST_MESSAGE_TITLE_REQUIRED)
      .min(1, errorConst.BROADCAST_MESSAGE_TITLE)
      .max(48),
    messageBody: Yup.string()
      .required(errorConst.MESSAGE_BODY_REQUIRED)
      .min(1, errorConst.MESSAGE_BODY_MIN_CHAR)
      .max(90),
    classCategories: Yup.array()
      .min(0),
    location: Yup.array().of(
      Yup.object({
        city: Yup.array().when(["isSelectAll"], {
          is: (isSelectAll: any) => {
            if (isSelectAll) {
              return false;
            } else if (!isSelectAll) {
              return true;
            } else {
              return false;
            }
          },
          then: Yup.array().min(1).required("required"),
          otherwise: Yup.array().min(0),
        }),
      })
    ),
    scheduleDate: Yup.date().when(["isScheduleSend"], {
      is: (isScheduleSend: boolean) => {
        if (isScheduleSend) {
          return true;
        } else {
          return false;
        }
      },
      then: Yup.date()
        .min(new Date(), errorConst.MIN_DATE_TIME)
        .required(errorConst.DATE_REQUIRED)
        .typeError(errorConst.ENTER_VALID_DATE),
      otherwise: Yup.date().min(0),
    }),
  });
