import { alertConst } from "../../text-constants";
import { showToast } from "../toastFunction";

function checkFileIsSelected(e: any) {
  return e.target?.files && e.target?.files[0] ? true : false;
}

function checkSelectedFileIsImage(e: any) {
  return e.target?.files && e.target?.files[0]?.name.match(/\.(jpg|jpeg|png)$/)
    ? true
    : false;
}

function validateImage(e: any, setMsg: any) {
  if (!checkFileIsSelected(e)) {
    showToast(setMsg, alertConst.SELECT_IMAGE_FILE, "warning");
    return false;
  } else if (!checkSelectedFileIsImage(e)) {
    showToast(setMsg, alertConst.SELECT_ONLY_IMAGE_FILE, "warning");
    return false;
  } else {
    return true;
  }
}

export { checkFileIsSelected, checkSelectedFileIsImage, validateImage };
