import { Dispatch } from "react";
import { fetchSingleGuideDetailsController } from "../../../../controllers/guidesController";
import { editGuideOrExplorerData } from "../edit-guideOrExplorer/editGuideOrExplorerPageData";
import { viewGuideOrExplorerData } from "./viewPageData";

export function getGuideData(
  guideId: string | number,
  setViewData: Dispatch<any>,
  isEditPage?: any
) {
  setViewData((prev: any) => ({
    ...prev,
    isLoading: true,
  }));

  fetchSingleGuideDetailsController(
    (response: any) => successGuideFetch(response, setViewData, isEditPage),
    (error: any) => errorGuideFetch(error, setViewData),
    guideId
  );
}

function successGuideFetch(response: any, setViewData: Dispatch<any>, isEditPage?: any) {
  let modifiedResponse: any;
  if (isEditPage) {
    modifiedResponse = response && editGuideOrExplorerData(response, "guide");
  } else {
    modifiedResponse = response && viewGuideOrExplorerData(response, "guide");
  }

  setViewData((previousVal: any) => ({
    ...previousVal,
    data: modifiedResponse,
    selectedCountry: response.countryId,
    ...(isEditPage && { copyData: response }),
    ...(isEditPage
      ? { guideImage: { binaryFile: "", url: response?.guideProfilePic } }
      : { guideImage: response?.guideProfilePic }),
    guideName: `${response?.firstName} ${response?.lastName}`,
    isLoading: false,
    cityId: response.cityId,
  }));
}

function errorGuideFetch(error: any, setViewData: Dispatch<any>) {
  setViewData((previousVal: any) => ({
    ...previousVal,
    data: null,
    guideImage: null,
    guideName: null,
    isLoading: false,
  }));
}
