import { Grid } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import { IMAGES } from "../../../assets/images";
import * as components from "../../../components/index";
import useMessage from "../../../hooks/customHooks/useMessage";
import { handleDeleteAdmin } from "../../../utils/reusable-functions/addEditAdminFunctions";
import {
  adminsListingColumnHeaders,
  getAdminsListData,
} from "../../../utils/reusable-functions/adminSettingsListingFunctions";
import { alertConst } from "../../../utils/text-constants";
import { adminsLisingConst } from "../../../utils/text-constants/adminSettingsConstants";

const AdminsListingPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { setMsg } = useMessage();

  const [toggleToFetchList, setToggleToFetchList] = React.useState<boolean>(false);
  const [openDeletePopup, setOpenDeletePopup] = React.useState({
    id: "",
    adminName: "",
    isClicked: false,
    isDeleting: false,
  });
  const [pageState, setPageState] = React.useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 10,
  });

  React.useEffect(() => {
    getAdminsListData(setPageState, pageState, setMsg);
  }, [pageState.page, pageState.pageSize, toggleToFetchList]);

  const handleAddAdminClick = () => {
    navigate("/admin/add-new-admin");
  };

  async function handleButtonClick(button: any) {
    if (button.id === "id-Delete") {
      handleDeleteAdmin(
        openDeletePopup,
        setOpenDeletePopup,
        setMsg,
        setToggleToFetchList,
        navigate
      );
    } else {
      setOpenDeletePopup({
        id: "",
        adminName: "",
        isClicked: false,
        isDeleting: false,
      });
    }
  }

  const navigateTo = () => {
    navigate("/admin/change-password");
  };

  return (
    <>
      <Grid container spacing={3} p={1}>
        <Grid item md={12} lg={12} justifyContent="space-between" display={"flex"}>
          <components.LoadingButtonComponent
            buttonLabel={adminsLisingConst.ADD_NEW_ADMIN}
            handleClick={handleAddAdminClick}
            endIcon={<img src={IMAGES.addIcon} />}
          />
          <components.LoadingButtonComponent
            buttonLabel={adminsLisingConst.CHANGE_PASSWORD}
            handleClick={navigateTo}
            endIcon={<img src={IMAGES.lockOrangeIcon} />}
          />
        </Grid>
        <Grid item md={12} lg={12} sx={{ mb: 10 }}>
          <components.DataGridComponent
            columns={adminsListingColumnHeaders(setOpenDeletePopup, navigate)}
            rows={pageState.data}
            rowCount={pageState.total}
            loading={pageState.isLoading}
            page={pageState.page}
            pageSize={pageState.pageSize}
            onPageChange={(page: any) => {
              setPageState({ ...pageState, page });
            }}
            onPageSizeChange={(pageSize: any) => {
              setPageState({ ...pageState, pageSize });
            }}
          />
        </Grid>
      </Grid>
      {openDeletePopup.isClicked && (
        <components.DeleteAlertDialogue
          isLoading={openDeletePopup.isDeleting}
          openPopup={openDeletePopup.isClicked}
          onButtonClick={handleButtonClick}
          subHeading={`${alertConst.DELETE_SUB_TEXT} "${openDeletePopup.adminName}" ? This action cannot be undone.`}
        />
      )}
    </>
  );
};

export default AdminsListingPage;
