export const IMAGES = {
  searchIcon: require("./searchImage.svg").default,
  dropdownIcon: require("./dropdownicon.svg").default,
  downloadReportIcon: require("./downloadreporticon.svg").default,
  filterIcon: require("./filter.svg").default,
  upArrowIcon: require("./uparrow.svg").default,
  closeIcon: require("./Close.svg").default,
  addIcon: require("./Add.svg").default,
  deleteFilledOrangeIcon: require("./DeleteFilledOrange.svg").default,
  editFilledGreenIcon: require("./EditFilledGreen.svg").default,
  lineIcon: require("./Line 7.svg").default,
  lockOrangeIcon: require("./Lock.svg").default,
  uploadIcon: require("./uploadIcon.svg").default,
  editPencilIcon: require("./editpencil.svg").default,
  roundDeleteOrangeIcon: require("./roundOrangeDelete.svg").default,
  backArrowIcon: require("./backArrow.svg").default,
  crossRedIcon: require("./crossRedIcon.svg").default,
  mapPinIcon: require("./mapPinIcon.svg").default,
  linkExpiredImg: require("./link-expired.svg").default,
  emptyProfileImg: require("./emptyProfileImg.png"),
  editDisbledPencil: require("./editDisabled.png"),
  calendarIcon: require("./calendar.svg").default,
  trashIcon: require("./trash.svg").default,
  blueRoundIcon: require("./blueRoundIcon.svg").default,
  copySymbol: require("./Copy.svg").default
};

export const NAV_ICONS = {
  Moonlight_White: require("./Moonlight_White.svg").default,

  dashboardInActiveIcon: require("./dashboardwhite.svg").default,
  dashboardActiveIcon: require("./dashboard-green.svg").default,

  classInActiveIcon: require("./classeswhite.svg").default,
  classActiveIcon: require("./class-green.svg").default,

  explorerInActiveIcon: require("./explorerwhite.svg").default,
  explorerActiveIcon: require("./explorergreen.svg").default,

  guideInActiveIcon: require("./guidewhite.svg").default,
  guideActiveIcon: require("./guidegreen.svg").default,

  broadcastInActiveIcon: require("./broadcastmsgwhite.svg").default,
  broadcastActiveIcon: require("./broadcastmsggreen.svg").default,

  emailInActiveIcon: require("./emailwhite.svg").default,
  emailActiveIcon: require("./emailgreen.svg").default,

  promotedClassInActiveIcon: require("./promotedclasswhite.svg").default,
  promotedClassActiveIcon: require("./promotedclassgreen.svg").default,

  communicationchatInActiveIcon: require("./communicationchatwhite.svg").default,
  communicationchatActiveIcon: require("./communicationchatgreen.svg").default,

  couponActiveIcon: require("./couponActive.svg").default,
  couponInActiveIcon: require("./couponInActive.svg").default,

  adminSettingInActiveIcon: require("./setting-2.svg").default,
  adminSettingActiveIcon: require("./settings-green.svg").default,

  logoutIcon: require("./logout-icon.svg").default,
};
