import { Box, Divider, Grid, Typography } from "@mui/material";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import * as components from "../../../components";
import { fetchEachCouponDetailsController } from "../../../controllers/couponsController";
import { viewCouponData } from "../../../utils/reusable-functions/coupons/viewCouponsData";
import style from "./coupons.module.scss";
import { IMAGES } from "../../../assets/images";

export default function ViewCouponDetails() {
  const location: any = useLocation();
  const navigate = useNavigate();

  const [viewData, setViewData] = React.useState({ isLoading: true, data: null });

  React.useEffect(() => {
    if (location.state?.couponId) {
      fetchEachCouponDetailsController(
        (response: any) => {
          const data = viewCouponData(response, location.state.pageIndex);
          setViewData({ isLoading: false, data });
        },
        () => {
          setViewData({ isLoading: false, data: null });
        },
        location.state.couponId
      );
    }
  }, []);

  return (
    <>
      {viewData.isLoading && (
        <Box className={style.progressBarContainer}>
          <components.CircularProgressBar loadername={"Loading data, please wait..."} />
        </Box>
      )}
      <Grid container spacing={4} p={2.5} pl={3}>
        {!viewData.isLoading &&
          viewData.data &&
          Array.isArray(viewData.data) &&
          viewData.data.map((eachField: any, index: number) => {
            return (
              <Grid
                key={index}
                item
                sm={eachField.breakPoints?.sm || 12}
                md={eachField.breakPoints?.md || 6}
                lg={eachField.breakPoints?.lg || 6}
              >
                {eachField.uiType === "listing" ? (
                  <>
                    <Typography title={eachField.label} className={style.label}>
                      {eachField.label}
                    </Typography>
                    {eachField.data.map((eachItem: any) => (
                      <div className={style.dotAndTextStyle}>
                        <img src={IMAGES.blueRoundIcon} style={{ marginRight: "12px" }} />
                        <Typography className={style.value}>{` ${eachItem}`}</Typography>
                      </div>
                    ))}
                  </>
                ) : (
                  <components.SimpleLableDataComponent
                    label={eachField.label}
                    value={eachField.data}
                  />
                )}
              </Grid>
            );
          })}
        <Grid item lg={12} md={12} sm={12}>
          <Divider />
        </Grid>
        <Grid item sm={12} lg={12} md={12}>
          {location.state?.pageIndex === 0 && (
            <components.LoadingButtonComponent
              buttonClassName={style.editButton}
              buttonLabel="Edit"
              labelImg={IMAGES.editPencilIcon}
              handleClick={() =>
                navigate("/admin/edit-coupon", {
                  state: { couponId: location?.state?.couponId },
                })
              }
            />
          )}
          <components.LoadingButtonComponent
            buttonLabel="Back"
            buttonClassName={style.backbutton}
            handleClick={() =>
              navigate("/admin/coupons-listing", {
                state: { tabIndex: location.state?.pageIndex },
                replace: true,
              })
            }
          />
        </Grid>
      </Grid>
    </>
  );
}
