import { PATH_PARAMS } from "../constants";
import { SuccessResponse } from "../types/parameters.type";
import { ApiClient, ErrorHandler, SuccessHandler } from "../services/data.service";

export const login = async (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  postParameter: any
) => {
  ApiClient.makePostRequest(
    PATH_PARAMS.LOGIN,
    successHandler,
    errorHandler,
    postParameter
  );
};

export const forgotPassword = async (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  postParameter: any
) => {
  ApiClient.makePostRequest(
    PATH_PARAMS.FORGOT_PASSWORD,
    successHandler,
    errorHandler,
    postParameter
  );
};

export const resetPassword = async (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  postParameter: any
) => {
  ApiClient.makePostRequest(
    PATH_PARAMS.RESET_PASSWORD,
    successHandler,
    errorHandler,
    postParameter
  );
};

export const verifyToken = async (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  postParameter: any
) => {
  ApiClient.makePostRequest(
    PATH_PARAMS.VERIFY_TOKEN,
    successHandler,
    errorHandler,
    postParameter
  );
};
