import { Stack } from "@mui/material";
import * as React from "react";
import { ClassesLineChart, ColorAndTextComponent } from "../index";
import customTheme from "../../themes/theme";

function ClassLineDoughnutChart(props: any) {
  const { chartData, totalClasses, totalEachStatusCount } = props;

  const colorText = [
    {
      borderColor: customTheme.palette.yellow.light,
      color: customTheme.palette.yellow.light,
      text: "Published",
      count: totalEachStatusCount.published,
    },
    {
      borderColor: customTheme.palette.teal.main,
      color: customTheme.palette.teal.main,
      text: "Completed",
      count: totalEachStatusCount.completed,
    },
    {
      borderColor: customTheme.palette.orange.main,
      color: customTheme.palette.orange.main,
      text: "Cancelled",
      count: totalEachStatusCount.cancelled,
    },
    {
      borderColor: "#0275e0",
      color: "#0275e0",
      text: "Expired",
      count: totalEachStatusCount.expired,
    },
    {
      borderColor: "#fac402",
      color: "#fac402",
      text: "Unpublished",
      count: totalEachStatusCount.unpublished,
    },
  ];
  return (
    <>
      <ClassesLineChart totalClasses={totalClasses} classesData={chartData} />
      <Stack direction="row" spacing={2} justifyContent="center" mt={5} mb={3}>
        {Array.isArray(colorText) &&
          colorText?.map((data: any, index: number) => {
            return (
              <React.Fragment key={index}>
                <ColorAndTextComponent
                  borderColor={data.borderColor}
                  color={data.color}
                  text={data.text}
                  count={data.count}
                />
              </React.Fragment>
            );
          })}
      </Stack>
    </>
  );
}

export { ClassLineDoughnutChart };
