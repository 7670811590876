import * as React from "react";
import { BarChartComponent } from "./baseGraphs/BarChartComponent";
import { Box, Stack, Typography } from "@mui/material";
import theme from "../../themes/theme";

function UsersBarChart(props: any) {
  const {
    guideChartData,
    explorerChartData,
    usersCount = 0,
    exlorersCount = 0,
    guideCounts = 0,
  } = props;

  const sx = {
    backgroundColor: " #F0F7F8",
    p: 5,
    borderRadius: "20px",
    cursor: "pointer",
    marginTop: "10px",
  };

  return (
    <Box sx={sx}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: 600,
          fontSize: "22px",
          color: theme.palette.secondary.contrastText,
        }}
      >{`Users - ${exlorersCount}`}</Typography>
      <Stack sx={{ margin: "30px 0" }} spacing={2}>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "16px",
            color: theme.palette.secondary.contrastText,
          }}
        >{`Explorers - ${exlorersCount}`}</Typography>
        <BarChartComponent chartData={explorerChartData} />
      </Stack>
      <Stack sx={{ margin: "30px 0" }} spacing={2}>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "16px",
            color: theme.palette.secondary.contrastText,
          }}
        >{`Guides - ${guideCounts}`}</Typography>
        <BarChartComponent chartData={guideChartData} />
      </Stack>
    </Box>
  );
}

export { UsersBarChart };
