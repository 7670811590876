import { Box, Typography } from "@mui/material";
import * as React from "react";
import { ButtonsListComponent } from "./ButtonsListComponent";

function LoadingButtonAndTextLink(props: any) {
  const { isLoading = false, buttonArray = [], onButtonClick, text, onTextClick } = props;
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
      <ButtonsListComponent
        isLoading={isLoading}
        buttonsIndexForLoader={[0]}
        buttonsArray={buttonArray}
        onButtonClick={onButtonClick}
      />
      <Typography
        sx={{
          textDecoration: "underline",
          color: "#FD6E3F",
          fontSize: "14px",
          fontWeight: "500",
          cursor: "pointer",
          width: "fit-content",
        }}
        onClick={onTextClick}
      >
        {text}
      </Typography>
    </Box>
  );
}

export { LoadingButtonAndTextLink };
