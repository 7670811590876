import { Box, Grid } from "@mui/material";
import * as React from "react";
import { TabComponent } from "../../components";
import PromotedClassListComponent from "./PromotedClassListComponent";

const PromotedClassComponent: React.FC = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const [countryId, setCountryId] = React.useState("TW");

  const handleTabChange = (index: number) => {
    setTabIndex(index);
    const id = getCountryIdByIndex(index);
    setCountryId(id);
  };

  const getCountryIdByIndex = (index: number) => {
    switch (index) {
      case 0:
        return "TW";
      case 1:
        return "AU";
      case 2:
        return "US";
      default:
        return "TW";
    }
  };

  return (
    <Box sx={{ padding: "10px" }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TabComponent
            tabsData={[
              {
                label: "Taiwan",
                tabComponent: <PromotedClassListComponent countryId={countryId} />,
              },
              {
                label: "Australia",
                tabComponent: <PromotedClassListComponent countryId={countryId} />,
              },
              {
                label: "USA",
                tabComponent: <PromotedClassListComponent countryId={countryId} />,
              },
            ]}
            currentTabIndexValue={tabIndex}
            setCurrentTabIndexValue={setTabIndex}
            handleTabChange={(newTabValue: number) => handleTabChange(newTabValue)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default PromotedClassComponent;
