/** class tab api calls */
import { PATH_PARAMS } from "../constants";
import { SuccessResponse } from "../types/parameters.type";
import { ApiClient, ErrorHandler, SuccessHandler } from "../services/data.service";

export const fetchGuidesController = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params?: any
) => {
  ApiClient.makeGetRequest(
    PATH_PARAMS.GET_GUIDES_LIST,
    successHandler,
    errorHandler,
    params
  );
};

export const fetchSingleGuideDetailsController = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  guideId?: string | number
) => {
  ApiClient.makeGetRequest(
    `${PATH_PARAMS.GET_SINGLE_GUIDE_DETAILS}${guideId}`,
    successHandler,
    errorHandler
  );
};

export const fetchGuideClassController = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  guideId?: string | number,
  params?: any
) => {
  ApiClient.makeGetRequest(
    `${PATH_PARAMS.GET_GUIDE_CLASS}${guideId}`,
    successHandler,
    errorHandler,
    params
  );
};

export const updateGuideController = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  guideId?: string | number,
  params?: any
) => {
  ApiClient.makePutRequest(
    `${PATH_PARAMS.UPDATE_GUIDE}${guideId}`,
    successHandler,
    errorHandler,
    params
  );
};
