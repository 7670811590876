import * as React from "react";
import { OutlinedInput, InputAdornment, Tooltip } from "@mui/material";
import { IMAGES } from "../../assets/images";
import { checkStringLength } from "../../utils/reusable-functions/dataLengthCheck";

function SearchInputField(props: any) {
  const {
    style,
    value,
    onChange,
    endIcon = IMAGES.searchIcon,
    placeholder = "Search",
    name,
    onSearchClick,
    onClearClick,
  } = props;

  return (
    <OutlinedInput
      title={value || placeholder}
      name={name}
      placeholder={checkStringLength(placeholder || "Search", 28)}
      value={value}
      onChange={onChange}
      onKeyPress={(e) => e.key === "Enter" && onSearchClick(e)}
      endAdornment={
        <InputAdornment position="end">
          {value && (
            <Tooltip title="Clear Search">
              <img
                src={IMAGES.closeIcon}
                onClick={onClearClick}
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          )}
          <Tooltip title="Search">
            <img style={{ cursor: "pointer" }} onClick={onSearchClick} src={endIcon} />
          </Tooltip>
        </InputAdornment>
      }
      style={{ fontSize: "15px", fontWeight: 400 }}
      sx={{
        minWidth: "320px",
        height: "47px",
        "&.Mui-focused": {
          border: "1px solid #0D3334",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        },
        ...style,
      }}
    />
  );
}

export { SearchInputField };
