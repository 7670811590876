import * as React from "react";
import { ImageCropperComponent } from "../imageCropper/ImageCropperComponent";
import { SimpleDialogue } from "./SimpleDialogue";

function ImageCropDialog(props: any) {
  const { openPopup, selectedFile, handleButtonClick, aspectRatioValue } =
    props;
  return (
    <SimpleDialogue dialogInlineStyle={{ minWidth: "50%" }} openPopup={openPopup}>
      <ImageCropperComponent
        selectedFile={selectedFile}
        handleButtonClick={handleButtonClick}
        aspectRatioValue={aspectRatioValue}
      />
    </SimpleDialogue>
  );
}

export { ImageCropDialog };
