import { Divider, Stack } from "@mui/material";
import * as React from "react";
import { DoughnutChartAndText } from "./DoughnutChartAndText";

function ClassCompleteUpComingDougnutChart(props: any) {
  const { firstChartData, secondChartData, percentageData } = props;

  const sx = {
    p: 2,
    borderRadius: "20px",
    overflow: "auto",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center"
  };

  return (
    <Stack sx={sx} direction="row">
      <DoughnutChartAndText
        chartData={firstChartData}
        percentageData={percentageData.first}
      />
      <Divider
        orientation="vertical"
        sx={{ height: "150px", padding: "0.25px", color: "#C3C3C3" }}
      />
      <DoughnutChartAndText
        chartData={secondChartData}
        percentageData={percentageData.second}
      />
    </Stack>
  );
}

export { ClassCompleteUpComingDougnutChart };
