import * as Yup from "yup";
import { errorConst } from "../../text-constants";

/**validation for the edit class page */

export const validateEditClassDetails = () =>
  Yup.array().of(
    Yup.object({
      value: Yup.string()
        .trim()
        .when("name", (name, schema) => {
          return subEditClassValidation1(name, schema);
        }),
      // tags: Yup.array().when("isTagList", {
      //   is: true,
      //   then: Yup.array()
      //     .min(1, errorConst.ATLEAST_ONE_TAG)
      //     .required(errorConst.TAGS_REQUIRED),
      // }),
    })
  );

//validate cancelclass inputs

export const validateClassCancelSchema = () =>
  Yup.object({
    staticReason: Yup.string().required(),
    otherReason: Yup.string()
      .trim()
      .when("staticReason", (staticReason, schema) => {
        return staticReason === "others"
          ? schema
              .min(1, errorConst.MIN_CHAR_FOR_OTHER_REASON)
              .required(errorConst.PLEASE_MENTION_REASON)
          : schema.min(0);
      }),
  });

function checkForMandatoryFields(name: any) {
  return (
    // name === "whatExplorerBring" ||
    name === "country"
    // name === "city" ||
    // name === "ApartmentNo" ||
    // name === "addressLine1" ||
    // name === "zipCode"
  );
}

function subEditClassValidation1(name: string, schema: any) {
  if (checkForMandatoryFields(name)) {
    return schema.min(1).required(errorConst.FIELD_CANNOT_EMPTY);
  } else if (name === "aboutClass") {
    return schema
      .min(20, errorConst.MIN_20_CHAR_REQUIRED)
      .required(errorConst.MIN_20_CHAR_REQUIRED);
  } else {
    return schema.min(0);
  }
}
