import { Button } from "@mui/material";
import * as React from "react";
import { checkStringLength } from "../dataLengthCheck";
import { getCountryName } from "../getCountryName";
import { ToggleSwitchComponent } from "../../../components";
import { getStatusText } from "../../helper-functions/helperFunction";
import { buttonStyle } from "../buttonFunction";

export function couponsTableHeader(
  pageIndex: string | number,
  viewCouponDetailsFunction: any,
  handleStatusToggleClick: any
) {
  return [
    { headerName: "No.", field: "id", width: 10 },
    {
      field: "Actions",
      headerName: "More Info",
      sortable: false,
      disableClickEventBubbling: true,
      width: 170,
      renderCell: (params: any) => {
        return (
          <>
            <Button
              sx={buttonStyle}
              variant="outlined"
              onClick={() => viewCouponDetailsFunction(params.row.baseCouponId)}
            >
              {"Details"}
            </Button>
          </>
        );
      },
    },
    {
      headerName: "Status",
      field: "status",
      width: 170,
      renderCell: (params: any) => {
        return (
          <>
            <ToggleSwitchComponent
              checked={params.row.status === "Active" ? true : false}
              onChange={(e: any) =>
                handleStatusToggleClick(params.row.baseCouponId, e.target.checked)
              }
            />
            <p>{getStatusText(params.row.status)}</p>
          </>
        );
      },
    },
    ...(pageIndex === 1
      ? [
          {
            headerName: "Coupon Code",
            field: "code",
            width: 250,
            renderCell: (params: any) => (
              <p title={params.row.code}>{checkStringLength(params.row.code, 25)}</p>
            ),
          },
        ]
      : []),
    {
      headerName: "Country",
      field: "country",
      width: 145,
      renderCell: (params: any) => (
        <p title={params.row.country}>
          {checkStringLength(getCountryName(params.row.country), 40)}
        </p>
      ),
    },
    {
      headerName: "Title",
      field: "couponTitle",
      width: 300,
      renderCell: (params: any) => {
        return (
          <p title={params.row.couponTitle}>
            {checkStringLength(params.row.couponTitle)}
          </p>
        );
      },
    },
    ...(pageIndex === 1
      ? [{ headerName: "Start date & time", field: "startDate", width: 250 }]
      : [{ headerName: "Duration (months)", field: "durationInMonths", width: 200 }]),
    ...(pageIndex === 1
      ? [{ headerName: "End date & time", field: "endDate", width: 250 }]
      : []),
    { headerName: "Discount (%)", field: "discount", width: 145 },
  ];
}
