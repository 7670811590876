import { Box, Divider, Grid, Typography } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";

import * as components from "../../../components/index";
import {
  addEditAdminsConst as addAdminConst,
  countriesList,
  userRoleList,
  alertConst,
} from "../../../utils/text-constants";
import style from "./adminsettings.module.scss";
import useMessage from "../../../hooks/customHooks/useMessage";
import { handleCreateAdmin } from "../../../utils/reusable-functions/addEditAdminFunctions";
import { validateAdminDetails } from "../../../utils/reusable-functions/data-validation/yupValidationSchema";
import { twoButtonList } from "../../../utils/reusable-functions/buttonFunction";
import { verifyEmailIdAndGetData } from "../../../controllers/adminSettingsController";
import { emailValidationSchema } from "../../../utils/reusable-functions/data-validation/emailValidation";

const AddNewAdminPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { setMsg } = useMessage();

  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [emailEndText, setEmailEndText] = React.useState("");

  const initialValues = {
    firstName: "",
    lastName: "",
    role: userRoleList[0].value,
    countryId: countriesList[0].value,
    email: "",
  };

  function handleButtonClick(data: any) {
    if (data.id === "cancel") {
      navigate(-1);
    }
  }

  const submitForm = (values: {
    firstName: string;
    lastName: string;
    role: string;
    countryId: string;
    email: string;
  }) => {
    setIsUploading(true);
    handleCreateAdmin(values, setIsUploading, setMsg, navigate);
  };

  function verifyEmailId(emailId: string, setFieldValue: any) {
    setEmailEndText("Checking...");
    verifyEmailIdAndGetData(
      (response: any) => {
        if (response?.userExists) {
          setFieldValue("firstName", response.firstName);
          setFieldValue("lastName", response.lastName);
          if (response.countryId) {
            setFieldValue("countryId", response.countryId);
          }
        } else {
          setFieldValue("firstName", "");
          setFieldValue("lastName", "");
        }
        setEmailEndText("");
      },
      () => {
        setEmailEndText("");
      },
      { email: emailId }
    );
  }

  return (
    <>
      <Box p={2} mb={25}>
        <Formik
          initialValues={initialValues}
          validationSchema={validateAdminDetails}
          onSubmit={(values) => submitForm(values)}
          validateOnChange
        >
          {({ errors, touched, handleChange, handleBlur, values, setFieldValue }) => {
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item sm={12} md={6} lg={6}>
                    <components.TextInputField
                      name="email"
                      label={addAdminConst.EMAIL}
                      placeholder={addAdminConst.ENTER_EMAIL}
                      errorText={errors.email}
                      onChange={async (event: any) => {
                        setFieldValue("email", event.target.value);
                        const isValid = await emailValidationSchema.isValid({
                          email: event.target.value,
                        });
                        if (isValid && event.target.value) {
                          verifyEmailId(event.target.value, setFieldValue);
                        }
                      }}
                      error={errors.email && touched.email}
                      onBlur={handleBlur}
                      fieldEndText={emailEndText}
                      value={values.email}
                    />
                  </Grid>
                  <Grid item sm={12} md={3} lg={3}>
                    <components.TextInputField
                      name={"firstName"}
                      label={addAdminConst.FIRST_NAME}
                      placeholder={addAdminConst.ENTER_FIRST_NAME}
                      errorText={errors.firstName}
                      onChange={handleChange}
                      error={errors.firstName && touched.firstName}
                      onBlur={handleBlur}
                      maxInputLenth={20}
                      value={values.firstName}
                    />
                  </Grid>
                  <Grid item sm={12} md={3} lg={3}>
                    <components.TextInputField
                      name={"lastName"}
                      label={addAdminConst.LAST_NAME}
                      placeholder={addAdminConst.ENTER_LAST_NAME}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxInputLenth={20}
                      errorText={errors.lastName}
                      error={errors.lastName && touched.lastName}
                      value={values.lastName}
                    />
                  </Grid>
                  <Grid item sm={12} md={6} lg={6}>
                    <components.SingleSelectInputField
                      name="role"
                      label={addAdminConst.ROLE}
                      listData={userRoleList}
                      onChange={handleChange}
                      value={values.role}
                    />
                  </Grid>
                  <Grid item sm={12} md={6} lg={6}>
                    <components.SingleSelectInputField
                      name="countryId"
                      label={addAdminConst.COUNTRY}
                      listData={countriesList}
                      onChange={handleChange}
                      value={values.countryId}
                    />
                  </Grid>
                </Grid>
                <Divider sx={{ mt: 2 }} />
                <components.ButtonsListComponent
                  isLoading={isUploading}
                  buttonsIndexForLoader={[0]}
                  buttonsArray={twoButtonList("Add New")}
                  onButtonClick={(data: any) => handleButtonClick(data)}
                />
              </Form>
            );
          }}
        </Formik>
      </Box>
      <Box className={style.bottomBoxContainer}>
        <Divider sx={{ mb: 4 }} />
        <Typography className={style.bottomTextAddadmin}>
          {alertConst.ADD_NEW_ADMIN_NOTE}
        </Typography>
      </Box>
    </>
  );
};

export default AddNewAdminPage;
