import { Box, Divider, Grid, Typography } from "@mui/material";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import style from "./broadcast-messages.module.scss";
import * as components from "../../../components";
import { fetchViewBroadCastData } from "../../../controllers/broadcastController";
import { getDateFormat } from "../../../utils/reusable-functions/dateTimeFormatFunction";

export default function ViewBroadcastMessage(props: any) {
  const navigate = useNavigate();
  const [viewData, setViewData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const location: any = useLocation();

  React.useEffect(() => {
    setLoading(true);
    fetchViewBroadCastData(
      (response: any) => {
        setViewData(response);
        setLoading(false);
      },
      () => {
        setLoading(false);
      },
      location?.state?.broadCastId
    );
  }, []);

  function getClassCategories() {
    if (!viewData.classCategory?.length) {
      return "-";
    }
    let listNumber = 0;
    return (
      <div className={style.categoryContainer}>
        {Array.isArray(viewData.classCategory) &&
          viewData.classCategory.map((eachCategory: any) => {
            listNumber = eachCategory.categoryName ? listNumber + 1 : listNumber;
            return (
              eachCategory.categoryName && (
                <Typography className={style.classCateoryText} mb={1}>
                  {listNumber}. {eachCategory.categoryName}
                </Typography>
              )
            );
          })}
      </div>
    );
  }

  return (
    <>
      {loading && (
        <Box className={style.loaderContainer}>
          <components.CircularProgressBar loadername="Loading data, please wait..." />
        </Box>
      )}
      {!loading && viewData && (
        <>
          <Grid container p={2} pl={3} pt={2} spacing={1}>
            <Grid item sm={12} md={12} lg={12} justifyContent="flex-start">
              <Typography className={style.heading}>Date & Time</Typography>
              <Typography className={style.subheading}>
                {getDateFormat(viewData.schedule?.scheduleDate)}
              </Typography>
              <br />
              <br />

              <Typography className={style.heading}>Message Title</Typography>
              <Typography className={style.subheading}>
                {viewData.messageTitle}
              </Typography>
              <br />
              <br />

              <Typography className={style.heading}>Message Body</Typography>
              <Typography className={style.subheading}>{viewData.messageBody}</Typography>
              <br />
              <br />

              <Typography className={style.heading}>Class</Typography>
              {getClassCategories()}
              <br />
              <br />

              <Typography className={style.heading}>Targeted location</Typography>
              {viewData.location.map((eachLocation: any, locIndex: number) => {
                return (
                  <div
                    style={{
                      ...(locIndex !== 0 && { paddingTop: "15px" }),
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography className={style.countryNameStyle}>
                      {" "}
                      {locIndex + 1}. {eachLocation.countryName}
                    </Typography>
                    {eachLocation.sendAll ? (
                      <Typography
                        className={style.subheading}
                        sx={{ marginTop: "-10px" }}
                      >
                        {"All cities selected."}
                      </Typography>
                    ) : (
                      <div className={style.citiesContainer}>
                        {Array.isArray(eachLocation.city) && (
                          <components.SimpleTagList
                            tagsList={eachLocation.city.map((eachCity: any) => ({
                              name: eachCity.cityName,
                            }))}
                          />
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
              <br />
              <br />
              <Divider orientation="horizontal" />
              <br />
              <br />
              <components.LoadingButtonComponent
                buttonLabel="Back"
                handleClick={() => navigate("/admin/broadcast-message-listing")}
                buttonClassName={style.button}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
