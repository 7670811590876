import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, Divider } from "@mui/material";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import Button from "@mui/material/Button";

import ShowPasswordIcon from "../../../assets/images/eye-icon.svg";
import HidePasswordIcon from "../../../assets/images/eye-icon-hide.svg";
import useMessage from "../../../hooks/customHooks/useMessage";
import { changePassword } from "../../../controllers/adminSettingsController";
import { clearStorage } from "../../../utils/auth";
import { TextInputField } from "../../../components";

const ChangePassword = (): JSX.Element => {
  const navigate = useNavigate();
  const { setMsg } = useMessage();

  const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [oldPasswordErrorMessage, setOldPasswordErrorMessage] = React.useState("");

  const validationSchema = Yup.object({
    oldPassword: Yup.string().required("Password is required"),
    newPassword: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password does not meet the requirements"
      ),
    confirmPassword: Yup.string()
      .required("Password is required")
      .oneOf([Yup.ref("newPassword"), null], "Passwords do not match"),
  });
  const onSuccess = async (response: any) => {
    setMsg({
      show: true,
      type: "success",
      msg: "New password has been created successfully",
      errorType: "default",
    });
    clearStorage();
    navigate("/login");
  };
  const onError = (error: any) => {
    console.log("Error => ", error);
    if (error.data.errors.code === 1013) {
      setOldPasswordErrorMessage("Incorrect current password");
    }
  };

  const submitPassword = (values: any) => {
    const userId: any = localStorage.getItem("userId");
    const postParameter = {
      userId: userId,
      oldPassword: window.btoa(values.oldPassword),
      newPassword: window.btoa(values.newPassword),
    };
    changePassword(onSuccess, onError, postParameter);
  };

  const navigateTo = () => {
    navigate(-1);
  };

  return (
    <Grid container spacing={3} p={2}>
      <Grid item md={12} lg={12}>
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => submitPassword(values)}
          validateOnChange
        >
          {({ errors, touched, handleChange }) => (
            <Form style={{ width: "55%" }}>
              <TextInputField
                label="Current Password"
                id="oldPassword"
                type={showCurrentPassword ? "text" : "password"}
                placeholder="Enter Password"
                name="oldPassword"
                error={
                  (touched.oldPassword && Boolean(errors.oldPassword)) ||
                  oldPasswordErrorMessage
                }
                errorText={errors.oldPassword || oldPasswordErrorMessage}
                onChange={(e: any) => {
                  handleChange(e);
                  setOldPasswordErrorMessage("");
                }}
                endIcon={showCurrentPassword ? ShowPasswordIcon : HidePasswordIcon}
                onIconClick={() => setShowCurrentPassword(!showCurrentPassword)}
              />
              <TextInputField
                label="New Password"
                id="newPassword"
                type={showNewPassword ? "text" : "password"}
                placeholder="Enter Password"
                name="newPassword"
                error={touched.newPassword && Boolean(errors.newPassword)}
                errorText={errors.newPassword}
                onChange={handleChange}
                endIcon={showNewPassword ? ShowPasswordIcon : HidePasswordIcon}
                onIconClick={() => setShowNewPassword(!showNewPassword)}
              />
              <div style={{ margin: "16px 0" }}>
                <Typography
                  variant="overline"
                  color="brown.dark"
                  sx={{ fontStyle: "italic" }}
                >
                  Password must contain 8 characters minimum, including atleast one
                  uppercase letter, one number and one special character
                </Typography>
              </div>
              <TextInputField
                label="Confirm Password"
                id="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Enter Password"
                name="confirmPassword"
                error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                errorText={errors.confirmPassword}
                onChange={handleChange}
                endIcon={showConfirmPassword ? ShowPasswordIcon : HidePasswordIcon}
                onIconClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
              <Divider sx={{ borderColor: "brown.main", margin: "30px 0" }} />
              <Grid container spacing={4}>
                <Grid item md={6} lg={5}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      my: 1,
                      padding: "12px 70px",
                      backgroundColor: "#FD6E3F",
                      textTransform: "capitalize",
                      "&:hover": {
                        backgroundColor: "#FD6E3F",
                      },
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
                {/* <Grid md={2} /> */}
                <Grid item md={6} lg={5}>
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={navigateTo}
                    sx={{
                      my: 1,
                      padding: "12px 70px",
                      height: "50px",
                      borderColor: "#FD6E3F",
                      color: "#FD6E3F",
                      textTransform: "capitalize",
                      "&:hover": {
                        backgroundColor: "#FFF",
                        borderColor: "#FD6E3F",
                      },
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default ChangePassword;
