import { Box, Stack, Typography } from "@mui/material";
import * as React from "react";
import { LineChartComponent } from "../index";
import theme from "../../themes/theme";

function ClassesLineChart(props: any) {
  const { totalClasses, classesData } = props;

  const sx = {
    padding: "35px 35px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.12)",
    borderRadius: "20px",
    cursor: "pointer",
    border: "1px solid rgba(161, 145, 145, 0.2)",
  };

  return (
    <Stack spacing={3}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: 600,
          fontSize: "22px",
          color: theme.palette.secondary.contrastText,
        }}
      >{`Classes - ${totalClasses}`}</Typography>
      <Box sx={sx}>
        <LineChartComponent chartData={classesData} />
      </Box>
    </Stack>
  );
}

export { ClassesLineChart };
