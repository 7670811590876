import { PATH_PARAMS } from "../constants";
import { SuccessResponse } from "../types/parameters.type";
import { ApiClient, ErrorHandler, SuccessHandler } from "../services/data.service";

export const fetchEmailTemplatesController = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params?: any
) => {
  ApiClient.makeGetRequest(
    `${PATH_PARAMS.GET_EMAIL_TEMPLATES}`,
    successHandler,
    errorHandler,
    params
  );
};

export const fetchEmailTemplateDetailsController = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  id?: any
) => {
  ApiClient.makeGetRequest(
    `${PATH_PARAMS.GET_TEMPLATE_DETAILS}${id}`,
    successHandler,
    errorHandler
  );
};

export const updateEmailTemplateController = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  templateId?: string | number,
  params?: any
) => {
  ApiClient.makePutRequest(
    `${PATH_PARAMS.UPDATE_EMAIL_TEMPLATE}${templateId}`,
    successHandler,
    errorHandler,
    params
  );
};

export const previewEmailController = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params?: any
) => {
  ApiClient.makePostRequest(
    `${PATH_PARAMS.PREVIEW_EMAIL}`,
    successHandler,
    errorHandler,
    params
  );
};
