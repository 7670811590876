import { Stack, Box, Typography } from "@mui/material";
import * as React from "react";
import { LineChartComponent, ColorAndTextComponent } from "../index";
import theme from "../../themes/theme";

function BookingsLineChart(props: any) {
  const {
    bookingsData,
    bookingsTotalCount = "",
    totalEachStatusCount,
  } = props;

  const colorText = [
    { borderColor: theme.palette.yellow.light, color: theme.palette.yellow.light, text: "Registered", total: totalEachStatusCount.registered },
    {
      borderColor: theme.palette.orange.main,
      color: theme.palette.orange.main,
      text: "Cancelled",
      total: totalEachStatusCount.cancelled
    },
  ];
  return (
    <Stack spacing={6} sx={{ cursor: "pointer" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            fontSize: "22px",
            color: theme.palette.secondary.contrastText,
          }}
        >{`Bookings - ${bookingsTotalCount}`}</Typography>
        <Stack direction="row" spacing={2}>
          {Array.isArray(colorText) &&
            colorText?.map((data: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  <ColorAndTextComponent
                    borderColor={data.borderColor}
                    color={data.color}
                    text={data.text}
                    count={data.total}
                  />
                </React.Fragment>
              );
            })}
        </Stack>
      </Box>
      <LineChartComponent chartData={bookingsData} />
    </Stack>
  );
}

export { BookingsLineChart };
