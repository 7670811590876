import * as React from "react";
import { Tabs, Tab, Box, Grid } from "@mui/material";

function TabComponent(props: any) {
  const {
    tabsData = [],
    currentTabIndexValue,
    setCurrentTabIndexValue,
    handleTabChange,
  } = props;

  function handleChange(event: any, newIndexValue: number) {
    if (handleTabChange) {
      handleTabChange(newIndexValue);
    } else {
      setCurrentTabIndexValue(newIndexValue);
    }
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Tabs
        TabIndicatorProps={{ style: { paddingLeft: "10px" } }}
        sx={{ pl: 3, width: "fit-content", m: 0 }}
        textColor="secondary"
        indicatorColor="secondary"
        value={currentTabIndexValue}
        onChange={handleChange}
        aria-label="tabs component"
      >
        {tabsData.map((eachTab: any, index: number) => {
          return (
            <Tab
              key={index}
              label={eachTab.label}
              {...a11yProps(index)}
              sx={{ fontSize: "20px", fontWeight: "500" }}
            />
          );
        })}
      </Tabs>
      <Grid container>
        <Grid item sm={12} lg={12} md={12}>
          {tabsData.map((eachTabForPanel: any, tabIndex: number) => {
            return (
              <TabPanel value={currentTabIndexValue} index={tabIndex} key={tabIndex}>
                {eachTabForPanel.tabComponent}
              </TabPanel>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export { TabComponent };
