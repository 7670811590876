/** class tab api calls */
import { PATH_PARAMS } from "../constants";
import { SuccessResponse } from "../types/parameters.type";
import { ApiClient, ErrorHandler, SuccessHandler } from "../services/data.service";

export const fetchClassesController = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params?: any
) => {
  ApiClient.makeGetRequest(
    PATH_PARAMS.FETCH_CLASSES_LIST,
    successHandler,
    errorHandler,
    params
  );
};

export const fetchSingleClassDetailsController = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params?: any
) => {
  ApiClient.makeGetRequest(
    `${PATH_PARAMS.FETCH_EACH_CLASS_DATA}${params}`,
    successHandler,
    errorHandler
  );
};

export const cancelClassController = async (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  postParameter: any
) => {
  ApiClient.makePostRequest(
    PATH_PARAMS.CANCEL_CLASS,
    successHandler,
    errorHandler,
    postParameter
  );
};

//GET CLASS CANCELLATION REASON

export const getCancelClassReasonController = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params?: any
) => {
  ApiClient.makeGetRequest(
    `${PATH_PARAMS.GET_CANCEL_CLASS_REASON}`,
    successHandler,
    errorHandler
  );
};

//UPDATE CLASS

export const updateClassController = async (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params: any,
  putParameter: any
) => {
  ApiClient.makePutRequest(
    `${PATH_PARAMS.UPDATE_CLASS}${params}`,
    successHandler,
    errorHandler,
    putParameter
  );
};
