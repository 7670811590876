import * as React from "react";
import { IMAGES } from "../../assets/images";
import { ImageComponent } from "..";
import style from "./imageComponent.module.scss";

function ImageWithContainer(props: any) {
  const {
    containerStyle,
    imageStyle,
    imageData,
    onDeleteClick,
    isDeleteIconVisible = false,
    imgAttribute,
  } = props;

  return (
    <>
      {!imageData[imgAttribute || "image"] && (
        <div
          className={style.missingImgContainer}
          style={{
            ...containerStyle,
          }}
        ></div>
      )}
      {imageData[imgAttribute || "image"] && (
        <div style={{ position: "relative" }}>
          {isDeleteIconVisible && (
            <ImageComponent
              image={IMAGES.roundDeleteOrangeIcon}
              alt={"remove image"}
              className={style.deleteIcon}
              onClick={onDeleteClick}
              title="Remove Image"
            />
          )}
          <ImageComponent
            styles={imageStyle}
            image={imageData[imgAttribute || "image"]}
            alt={imageData.alt}
          />
        </div>
      )}
    </>
  );
}

export { ImageWithContainer };
