//initial create broadcast data

import { NavigateFunction } from "react-router-dom";

import {
  classCategoryList,
  countriesList,
  errorConst,
} from "../../../utils/text-constants";
import { createBroadcastController } from "../../../controllers/broadcastController";
import { showToast } from "../../helper-functions/helperFunction";
import { getCountryName } from "../getCountryName";

export const initialCreateBroadCastData: any = {
  messageTitle: "",
  messageBody: "",
  classCategories: [],
  isSelectedAllCategory: false,
  location: [
    {
      name: "taiwan",
      countriesList,
      countryId: "TW",
      countryName: "taiwan",
      isSelectAll: false,
      city: [],
    },
  ],
  scheduleDate: "",
  isScheduleSend: true,
};

//handle change class category function
export const handleChangeCategory = (
  value: any,
  setFieldValue: any,
  classCategoryList1: any
) => {
  const selectedCategoriesLength = value?.length;
  setFieldValue("classCategories", value);
  setFieldValue(
    "isSelectedAllCategory",
    selectedCategoriesLength > 0
      ? classCategoryList1.length === selectedCategoriesLength
      : false
  );
};

//handle class category select all checkbox click

export const handleCategoryCheckboxClick = (
  checkedValue: boolean,
  setFieldValue: any,
  classCategoryList2: any
) => {
  if (checkedValue) {
    setFieldValue("isSelectedAllCategory", true);
    setFieldValue(
      "classCategories",
      classCategoryList2.map((eachCategory: any) => eachCategory.value)
    );
  } else {
    setFieldValue("isSelectedAllCategory", false);
    setFieldValue("classCategories", []);
  }
};

export const handleCitySelectButtonClick = (
  setOpenPopup: any,
  index: number,
  eachLocation: any
) => {
  setOpenPopup((previous: any) => ({
    ...previous,
    citySelect: true,
    countryId: eachLocation.countryId,
    locationIndex: index,
  }));
};

// City select popup buttons click

export const handleCitiySelectButtonClick = (
  id: string,
  data: any,
  setOpenPopup: any,
  setFieldValue: any,
  openPopup: any
) => {
  if (id === "id-apply") {
    setFieldValue(`location[${openPopup.locationIndex}].city`, data);
  }

  setOpenPopup((previous: any) => ({
    ...previous,
    citySelect: false,
    locationIndex: null,
    countryId: "",
  }));
};

//handle checkbox click of city select

export const handleCitySelectAllCheckbox = (
  checkedValue: boolean,
  setFieldValue: any,
  index: number
) => {
  setFieldValue(`location[${index}].isSelectAll`, checkedValue);
  if (checkedValue) {
    setFieldValue(`location[${index}].city`, []);
  }
};

//handle city remove click

export const handleCityDeleteClick = (
  selectedCity: any,
  setFieldValue: any,
  index: number,
  values: any
) => {
  const filteredValue = values.location[index]?.city?.filter(
    (eachLoc: any) => eachLoc.Id !== selectedCity.id
  );
  setFieldValue(`location[${index}].city`, filteredValue);
};

//handle location delete

export const handleDeleteLocation = (index: number, setFieldValue: any, values: any) => {
  values.location.splice(index, 1);
  setFieldValue(`location`, values.location);
};

//handle country change

export const handleCountryChange = (
  e: any,
  values: any,
  setFieldValue: any,
  index: number,
  setMsg: any
) => {
  const isCountrySelected = values.location.some(
    (loc: any) => loc.countryId === e.target.value
  );

  if (isCountrySelected) {
    showToast(
      setMsg,
      `${getCountryName(e.target.value)} is already selected.`,
      "warning"
    );
  } else {
    setFieldValue(`location[${index}].countryName`, getCountryName(e.target.value));
    setFieldValue(`location[${index}].countryId`, e.target.value);
    setFieldValue(`location[${index}].name`, e.target.value);
    setFieldValue(`location[${index}].city`, []);
  }
};

//handle broadcast submit button click

export const handleBroadCastSubmit = (
  values: any,
  setMsg: any,
  navigate: NavigateFunction,
  setIsUploading: any
) => {
  setIsUploading(true);
  const categories: any[] = [];
  classCategoryList.forEach((category: any) => {
    const val = values.classCategories.find(
      (classCategory: any) => classCategory === category.value
    );
    if (val) {
      categories.push({ categoryId: category.id, categoryName: category.value });
    }
  });

  const modifiedLocation = values.location.map((eachLocation: any) => {
    const modifiedData = {
      ...eachLocation,
      sendAll: eachLocation.isSelectAll,
      city: eachLocation.city.map((eachCity: any) => ({
        cityId: eachCity.Id,
        cityName: eachCity.Name,
      })),
    };
    delete modifiedData.isSelectAll;
    delete modifiedData.countriesList;
    return modifiedData;
  });

  const payload = {
    messageTitle: values.messageTitle,
    messageBody: values.messageBody,
    location: modifiedLocation,
    classCategory: categories,
    ...(values.isScheduleSend && {
      schedule: {
        scheduleDate: values.scheduleDate,
        scheduleTime: values.scheduleDate,
      },
    }),
  };

  createBroadcastController(
    () => {
      const subText: string = values.isScheduleSend ? `created` : `sent`;
      showToast(setMsg, `${values.messageTitle} ${subText} successfully!`, "success");
      setIsUploading(false);
      setTimeout(() => navigate(-1), 1500);
    },
    (error: any) => {
      showToast(
        setMsg,
        error?.message || error?.data?.message || errorConst.UNKNOW_ERROR,
        "error"
      );
      setIsUploading(false);
    },
    payload
  );
};
