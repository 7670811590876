export const classesListConst = {
  DOWNLOAD_REPORT: "Download Report",
  FILTER: "Filter",
  SEARCH_CLASS: "Search Class",
};

export const classDetailsConst = {
  COVER_IMAGE: "Cover image",
  OTHER_IMAGE: "Other images",
  LOCATION_IMAGES: "Location Images",
  CHECKIN_DETAILS_GUIDE: "Check In Details - Guide",
  CHECKIN_DETAILS_EXPLORER: "Check In Details - Explorer",
  PROGRESS_BAR_TEXT: "Class details loading...",
  CLASS_LOCATION: "Class Location",
};

export const classEditConst = {};

export const classCancelReason = [
  { label: "I'm getting sick", value: "I'm getting sick" },
  { label: "Something came up", value: "Something came up" },
  { label: "I changed my mind", value: "I changed my mind" },
  {
    label: "I have found another time that worked better for me",
    value: "I have found another time that worked better for me",
  },
  { label: "Other", value: "Other" },
];
