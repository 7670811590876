import * as React from "react";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { emojisList } from "../../utils/text-constants";

const draftToHtml = require("draftjs-to-html");
const htmlToDraft = require("html-to-draftjs").default;

function TextEditorComponent(props: any) {
  const { values, handleChange } = props;
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(htmlToDraft(values || "<div></div>"))
    )
  );
  return (
    <div>
      <Editor
        editorState={editorState}
        onEditorStateChange={setEditorState}
        onChange={(e) =>
          handleChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
        }
        editorStyle={{ minHeight: "300px", marginTop: "10px", fontSize: "15px" }}
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "colorPicker",
            "link",
            "emoji",
            "history",
            "list",
            "textAlign",
          ],
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: [
              "bold",
              "italic",
              "underline",
              "strikethrough",
              "monospace",
              "superscript",
              "subscript",
            ],
          },
          blockType: {
            inDropdown: true,
            options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6", "Blockquote", "Code"],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
          },
          fontSize: {
            select: 18,
            options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
          },
          fontFamily: {
            options: [
              "Poppins",
              "Arial",
              "Georgia",
              "Impact",
              "Tahoma",
              "Times New Roman",
              "Verdana",
            ],
          },
          list: {
            inDropdown: true,
            options: ["unordered", "ordered", "indent", "outdent"],
          },
          textAlign: {
            inDropdown: true,
            options: ["left", "center", "right", "justify"],
          },
          colorPicker: {
            colors: [
              "#FD6E3F",
              "#B2DF64",
              "#40AFA8",
              "#0D3334",
              "#353535",
              "#A29191",
              "#C3C3C3",
              "#E7E1E1",
              "#FFFFFF",
              "#4EC032",
              "#F73030",
              "#9747FF",
            ],
          },
          link: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            dropdownClassName: undefined,
            showOpenOptionOnHover: true,
            defaultTargetOption: "_self",
            options: ["link", "unlink"],
            linkCallback: undefined,
          },
          emoji: {
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            emojis: emojisList,
          },
          image: {
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            urlEnabled: true,
            uploadEnabled: true,
            alignmentEnabled: true,
            uploadCallback: undefined,
            previewImage: true,
            inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
            alt: { present: false, mandatory: false },
            defaultSize: {
              height: "auto",
              width: "auto",
            },
          },
          history: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ["undo", "redo"],
          },
        }}
      />
    </div>
  );
}

export { TextEditorComponent };
