import * as React from "react";

import { getSelectedFilters } from "../../../utils/reusable-functions/filterFunctions";
import { getBookingsList } from "../../../controllers/dashboardController";
import { CountListAndTableComponent } from "../../../compoundComponents";
import { showToast } from "../../../utils/helper-functions/helperFunction";
import useMessage from "../../../hooks/customHooks/useMessage";
import { errorConst } from "../../../utils/text-constants";
import { bookingsTableColumns } from "../../../utils/reusable-functions/bookings/tableColumns";

const BookingsDataSummaryComponent = (props: any) => {
  const { filterOptions, searchText } = props;
  const { setMsg } = useMessage();

  const [pageState, setPageState] = React.useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 10,
  });

  React.useEffect(() => {
    getPaginateData(pageState.page, pageState.pageSize);
  }, [pageState.page, pageState.pageSize, filterOptions, searchText]);

  const getPaginateData = async (page: any, pageSize: any) => {
    setPageState({ ...pageState, isLoading: true });

    getBookingsList(
      (response: any) => {
        const data = response.data?.map((item: any, i: number) => {
          const bookingId = i + 1 + page * pageSize;
          return { ...item, id: bookingId };
        });
        setPageState({ ...pageState, isLoading: false, data, total: response.count });
      },
      (error: any) => {
        setPageState((prev: any) => ({
          ...prev,
          isLoading: false,
          data: [],
        }));

        showToast(
          setMsg,
          error.message || error.data?.message || errorConst.UNKNOW_ERROR,
          "error"
        );
      },
      {
        skip: page + 1,
        limit: pageSize,
        module: "dashboard",
        ...getSelectedFilters(filterOptions),
        ...(searchText && { search: searchText }),
      }
    );
  };

  return (
    <CountListAndTableComponent
      setPageState={setPageState}
      pageState={pageState}
      countDataList={[]}
      tableColumns={bookingsTableColumns}
    />
  );
};
export default BookingsDataSummaryComponent;
