import { NavigateFunction } from "react-router-dom";
import { updateGuideController } from "../../../../controllers/guidesController";
import { checkFormHasFile, convertArrayToObject } from "../../../helper-functions";
import { showToast } from "../../../helper-functions/helperFunction";
import { alertConst, errorConst } from "../../../text-constants";
import { getOnlyChangedValues } from "./editGuideOrExplorerFunctions";

//handle edit guide button click function

export const handleSubmitEditGuideDetails = (
  values: any,
  editData: any,
  guideId: number | string,
  setMsg: any,
  navigate: NavigateFunction,
  setIsUpdating: any
) => {
  try {
    const objectValue = convertArrayToObject(values);
    const finalPayload: any = getOnlyChangedValues(objectValue, editData, "guide");
    if (checkFormHasFile(finalPayload)) {
      setIsUpdating(true);
      updateGuideController(
        () => {
          showToast(
            setMsg,
            `Guide "${objectValue.firstName} ${
              objectValue.lastName || ""
            }" updated successfully!`,
            "success"
          );
          setTimeout(() => navigate(-1), 1500);
          setIsUpdating(false);
        },
        (error: any) => {
          showToast(
            setMsg,
            error.message || error.data.message || errorConst.UNKNOW_ERROR,
            "error"
          );
          setIsUpdating(false);
        },
        guideId,
        finalPayload
      );
    } else {
      showToast(setMsg, alertConst.NO_CHANGES_FOUND, "warning");
      setIsUpdating(false);
    }
  } catch (error) {
    showToast(setMsg, errorConst.UNKNOW_ERROR, "error");
  }
};
