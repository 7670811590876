import { getDateFormat } from "../../dateTimeFormatFunction";
import { countriesList, userToBeCalled } from "../../../text-constants";
import { checkPronounExist, getSignupDateLabel } from "../../../helper-functions";
import { getSingupDate } from "../../../helper-functions/helperFunction";
const LANGUAGE_OPTIONS = require("../../../text-constants/languagesList.json");

/**
 * Note: Guide edit page yup validation is dependant on 'id' attribute of the below objects
 */
export const editGuideOrExplorerData = (values: any, userType: string) => {
  return [
    ...editGuideExplorerData1(values),
    ...editGuideExplorerData2(values, userType),
    ...editGuideExplorerLanguageData(values),
    ...editGuideExplorerData3(values, userType),
  ];
};

const editGuideExplorerData1 = (values: any) => {
  return [
    {
      id: 1,
      name: "userId",
      label: "User ID",
      isDisabled: true,
      inputFieldType: "SIMPLE",
      data: values.userId || "",
    },
    {
      id: 2,
      name: "userHandle",
      label: "User Name",
      isDisabled: true,
      inputFieldType: "SIMPLE",
      data: values.userHandle || "",
    },
    {
      id: 3,
      name: "firstName",
      label: "First Name",
      isDisabled: true,
      inputFieldType: "SIMPLE",
      data: values.firstName || "",
    },
    {
      id: 4,
      name: "lastName",
      label: "Last Name",
      isDisabled: true,
      inputFieldType: "SIMPLE",
      data: values.lastName || "",
    },
    {
      id: 5,
      name: "dateOfBirth",
      label: "Date of Birth",
      isDisabled: true,
      inputFieldType: "SIMPLE",
      data: getDateFormat(values.dateOfBirth) || "",
    },
    {
      id: 6,
      name: "phone",
      label: "Phone Number",
      isDisabled: true,
      inputFieldType: "SIMPLE",
      data: values.phone || "",
    },
    {
      id: 7,
      name: "email",
      label: "Email",
      isDisabled: true,
      inputFieldType: "SIMPLE",
      data: values.email || "",
    },
    {
      id: 8,
      name: "isEmailVerified",
      label: "Is email verified (Yes/No)",
      isDisabled: true,
      inputFieldType: "SIMPLE",
      data: values.emailVerified?.isVerified ? "Yes" : "No",
    },
    {
      id: 9,
      name: "emailVerifica",
      label: "Email Verification date (If Email is verified)",
      isDisabled: true,
      inputFieldType: "SIMPLE",
      data: values.emailVerified?.verifiedOn
        ? getDateFormat(values.emailVerified?.verifiedOn)
        : "",
    },
    {
      id: 10,
      name: "howDidYouHear",
      label: "How did the user hear about app",
      isDisabled: true,
      inputFieldType: "SIMPLE",
      data: values.howDidYouHear || "",
    },
  ];
};

const editGuideExplorerData2 = (values: any, userType: string) => {
  return [
    {
      id: 11,
      name: userType === "guide" ? "guideSignUpDate" : "explorerSignUpDate",
      label: getSignupDateLabel(userType),
      isDisabled: true,
      inputFieldType: "SIMPLE",
      data: getSingupDate(values, userType),
    },
    {
      id: 12,
      name: "agreedForRecievingMarketingCampaign",
      label: "Agreed For Recieving Marketing Campaign",
      isDisabled: true,
      inputFieldType: "SIMPLE",
      data: values.agreedForRecievingMarketingCampaign ? "Yes" : "No",
    },
    {
      id: 13,
      name: "countryId",
      label: "Country",
      isDisabled: false,
      listData: countriesList,
      inputFieldType: "SINGLE_SELECT",
      data: values.countryId || "",
    },
    {
      id: 14,
      name: "cityName",
      label: "City",
      isDisabled: false,
      readOnly: true,
      inputFieldType: "SIMPLE",
      data: values.cityName || "",
    },
  ];
};

const editGuideExplorerLanguageData = (values: any) => {
  return [
    {
      id: 15,
      name: "languagesKnown",
      label: "I Speak",
      isDisabled: false,
      listData: LANGUAGE_OPTIONS.map((language: any) => language.lang),
      inputFieldType: "MULTI_SELECT",
      gridBreakpoints: { sm: 12, lg: 12, md: 12 },
      data: getLanguageData(values),
    },
    {
      id: 16,
      name: "otherLanguages",
      label: "Other Language(s)",
      isDisabled: false,
      inputFieldType: "SIMPLE",
      isDataPresent: true,
      isFieldVisible: getLanguageData(values).includes("Other") ? true : false,
      gridBreakpoints: { sm: 12, lg: 12, md: 12 },
      data: getLanguageData(values).includes("Other")
        ? Array.isArray(values.languagesKnown) && values.languagesKnown[0]
        : "",
    },
  ];
};

const editGuideExplorerData3 = (values: any, userType: string) => {
  const pronounData = userType === "guide" ? values.guidePronoun : values.pronoun;
  return [
    {
      id: 17,
      name: userType === "guide" ? "guideBio" : "explorerBio",
      label: "Bio",
      isDisabled: false,
      inputFieldType: "SIMPLE",
      data: userType === "guide" ? values.guideBio || "" : values.explorerBio || "",
      isMultilineInput: true,
      gridBreakpoints: { sm: 12, lg: 12, md: 12 },
    },
    {
      id: 18,
      name: "pronoun",
      label: "I would like to be called",
      isDisabled: false,
      listData: userToBeCalled,
      inputFieldType: "SINGLE_SELECT",
      data: checkForPronounInitialValue(pronounData),
      gridBreakpoints: getPronounBreakpoints(pronounData),
    },
    {
      id: 19,
      name: "pronounOther",
      label: "What would you like to be called?",
      isDisabled: false,
      isFieldVisible: checkOtherPronounFieldVisible(pronounData),
      inputFieldType: "SIMPLE",
      data: getOtherPronounData(pronounData),
      gridBreakpoints: { sm: 12, lg: 6, md: 6 },
      maxCharacter: 20,
    },
  ];
};

function getLanguageData(values: any) {
  const languagesList = values?.languagesKnown?.filter(
    (val: any) =>
      !LANGUAGE_OPTIONS.some(
        (lang: any) =>
          lang.lang.toString().toLowerCase() === val?.toString().toLowerCase()
      )
  );
  if (languagesList?.length) {
    return ["Other"];
  } else {
    return values?.languagesKnown;
  }
}

const checkForPronounInitialValue = (initialPronoun: string) => {
  if (initialPronoun) {
    if (checkPronounExist(initialPronoun)) {
      if (initialPronoun?.toLowerCase() === "other") {
        return "Other";
      } else {
        return checkPronounExist(initialPronoun)?.value;
      }
    } else {
      return "Other";
    }
  } else {
    return "";
  }
};

const getPronounBreakpoints = (pronoun: string) => {
  if (pronoun) {
    if (checkPronounExist(pronoun)) {
      if (pronoun?.toLowerCase() === "other") {
        return { sm: 12, lg: 6, md: 6 };
      } else {
        return { sm: 12, lg: 12, md: 12 };
      }
    } else {
      return { sm: 12, lg: 6, md: 6 };
    }
  } else {
    return { sm: 12, lg: 12, md: 12 };
  }
};

const checkOtherPronounFieldVisible = (pronoun1: string) => {
  if (pronoun1) {
    if (checkPronounExist(pronoun1)) {
      if (pronoun1.toLowerCase() === "other") {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  } else {
    return false;
  }
};

const getOtherPronounData = (pronoun2: string) => {
  if (pronoun2) {
    if (checkPronounExist(pronoun2)) {
      if (pronoun2.toLowerCase() === "other") {
        return pronoun2;
      } else {
        return "";
      }
    } else {
      return pronoun2;
    }
  } else {
    return "";
  }
};
