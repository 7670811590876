import * as React from "react";
import { Divider, Grid, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";

import * as components from "../../../components";
import style from "./classes.module.scss";
import {
  alertConst,
  classDetailsConst,
  countriesList,
} from "../../../utils/text-constants";
import { twoButtonList } from "../../../utils/reusable-functions/buttonFunction";
import { getSingleClassData } from "../../../utils/reusable-functions/classes/view-class/viewClassApiFunctions";
import { validateEditClassDetails } from "../../../utils/reusable-functions/data-validation/editClassValidationSchema";
import {
  getIsErrorValue,
  handleButtonClick,
  handleRemoveImage,
  setClassDataFunction,
  removeTags,
  setLocationDataFunction,
  verifyTagsInput,
  handleCropperButtonClick,
  handleCoverImageChange,
  getErrorText,
} from "../../../utils/reusable-functions/classes/edit-class/editClassFunctionalities";
import { showToast } from "../../../utils/reusable-functions/toastFunction";
import useMessage from "../../../hooks/customHooks/useMessage";
import { handleEditClassSubmit } from "../../../utils/reusable-functions/classes/edit-class/editClassApiFunction";
import { IMAGES } from "../../../assets/images";
import { validateImage } from "../../../utils/reusable-functions/data-validation/imageFileValidation";
import { onKeyDown } from "../../../utils/helper-functions";

export default function EditClassDetailsPage() {
  const navigate = useNavigate();
  const location: any = useLocation();
  const { setMsg } = useMessage();

  const [images, setImages] = React.useState<any>({
    coverImage: { url: "", binaryFile: "" },
    otherImages: [],
    locationImages: [],
  });

  const [classData, setClassData] = React.useState({
    data: null,
    copyData: null,
    isLoading: false,
  });

  const [locationData, setLocationData] = React.useState({
    data: null,
  });

  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [openCropper, setOpenCropper] = React.useState<any>({
    isOpen: false,
    imageType: "",
  });
  const [selectedImage, setSelectedImage] = React.useState(null);

  React.useEffect(() => {
    const classId = location?.state?.class_id;
    classId &&
      getSingleClassData(classId, setClassData, "EDIT_CLASS", setImages, setLocationData);
  }, []);

  function handleInutChange(
    inputValue: any,
    values: any,
    index: any,
    valueAttribute?: string
  ) {
    setClassDataFunction(setClassData, values, index, inputValue, valueAttribute);
  }

  function handleLocationData(locIndex: any, values: any, currentValue: any) {
    setLocationDataFunction(setLocationData, classData, locIndex, values, currentValue);
  }

  function handleFilePick(e: any, imageType: string) {
    validateImage(e, setMsg) && setOpenCropper({ isOpen: true, imageType: imageType });
    setSelectedImage(e);
  }

  return (
    <>
      {!classData.isLoading && classData.data && (
        <Formik
          initialValues={[...classData.data, ...locationData.data]}
          validationSchema={validateEditClassDetails}
          onSubmit={(values) => {
            if (!images.coverImage.binaryFile && !images.coverImage.url) {
              showToast(setMsg, alertConst.MISSING_COVER_IMG, "warning");
            } else {
              handleEditClassSubmit(
                values,
                images,
                location?.state?.class_id,
                setMsg,
                navigate,
                setIsUpdating,
                classData.copyData
              );
            }
          }}
          validateOnChange
        >
          {({ errors, handleBlur, values }) => {
            return (
              <Form onKeyDown={onKeyDown}>
                <Grid container spacing={3} p={2.5} pl={3}>
                  <Grid item>
                    <Typography
                      title={"Cover image required"}
                      className={style.mediumFontHeader}
                    >
                      {`${classDetailsConst.COVER_IMAGE}*`}
                    </Typography>
                    {images.coverImage?.url ? (
                      <components.ImageWithContainer
                        imageData={{
                          image: images.coverImage?.url,
                          alt: "alt text",
                        }}
                        imageStyle={{ width: "390px", height: "295px" }}
                        isDeleteIconVisible
                        onDeleteClick={() =>
                          handleCoverImageChange("", setImages, setMsg)
                        }
                      />
                    ) : (
                      <components.ImageFileInput
                        handleImageChange={(e: any) => handleFilePick(e, "coverImage")}
                        className={style.imageUploadContainer}
                      />
                    )}
                  </Grid>
                  <Grid item>
                    <components.LabelImagesListingComponent
                      label={classDetailsConst.OTHER_IMAGE}
                      imagesList={images.otherImages || []}
                      isDeleteIconVisible
                      imgAttribute="url"
                      showUploadImageContainer={
                        images.otherImages.length < 4 ? true : false
                      }
                      onDeleteClick={(data: any) =>
                        handleRemoveImage(data, "otherImages", setImages)
                      }
                      handleImageSelect={(e: any) => handleFilePick(e, "otherImages")}
                    />
                  </Grid>
                  <Grid item sm={12} lg={12} md={12}>
                    <Divider />
                  </Grid>
                  {classData.data?.map((editField: any, i: number) => {
                    return (
                      <Grid
                        item
                        sm={editField.gridBreakpoints?.sm || 12}
                        lg={editField.gridBreakpoints?.lg || 6}
                        md={editField.gridBreakpoints?.md || 6}
                        key={i}
                      >
                        <components.TextInputField
                          label={editField.label}
                          name={editField.name}
                          placeholder={editField.label}
                          errorText={getErrorText(
                            errors,
                            i,
                            values[i].value,
                            editField.name
                          )}
                          onChange={(e: any) =>
                            handleInutChange(e.target.value, values, i)
                          }
                          onIconClick={() => {
                            const newTags = verifyTagsInput(
                              editField.value,
                              values[i].tags
                            );
                            handleInutChange(newTags, values, i, "tags");
                            handleInutChange("", values, i);
                          }}
                          error={getIsErrorValue(
                            errors,
                            i,
                            values[i].value,
                            values[i].tags,
                            editField.name,
                            editField.name
                          )}
                          onBlur={handleBlur}
                          disabled={editField.isDisabled || false}
                          stackStyle={{ mb: 0 }}
                          value={values[i].value}
                          isEndButton={editField.isEndIconOnInputField || false}
                          endIcon={
                            editField.isEndIconVisible &&
                            editField.value?.toString().trim()?.length >= 3 &&
                            IMAGES.addIcon
                          }
                          endIconStyle={{ width: "35px", height: "35px" }}
                          endIconTitle="Add Tag"
                          multiline={editField.isMultilineInput}
                          rows={7}
                        />

                        {editField.isTagList && (
                          <div style={{ marginTop: "10px" }}>
                            <components.SimpleTagList
                              tagsList={values[i].tags}
                              isDeleteIconPresent={true}
                              onDeleteClick={(tag: any) =>
                                removeTags(tag, setClassData, values, i)
                              }
                            />
                          </div>
                        )}
                      </Grid>
                    );
                  })}
                  <Grid item sm={12} lg={12} md={12}>
                    <Divider />
                  </Grid>
                  <Grid item sm={12} lg={12} md={12}>
                    <Typography className={style.locationHeader}>
                      {classDetailsConst.CLASS_LOCATION}
                    </Typography>
                  </Grid>
                  {classData.data &&
                    locationData.data &&
                    locationData.data?.map((locData: any, locIndex1: number) => {
                      const locIndex = locIndex1 + classData.data?.length;
                      if (locData.isSelectDropdownField) {
                        return (
                          <Grid
                            item
                            sm={locData.gridBreakpoints?.sm || 12}
                            lg={locData.gridBreakpoints?.lg || 6}
                            md={locData.gridBreakpoints?.md || 6}
                            key={locIndex}
                          >
                            <components.SingleSelectInputField
                              name={locData.name}
                              label={locData.label}
                              listData={countriesList}
                              onChange={(e: any) =>
                                handleLocationData(locIndex, values, e.target?.value)
                              }
                              value={values[locIndex].value}
                            />
                          </Grid>
                        );
                      } else if (locData.isCheckboxField) {
                        return (
                          <Grid
                            item
                            sm={locData.gridBreakpoints?.sm || 12}
                            lg={locData.gridBreakpoints?.lg || 6}
                            md={locData.gridBreakpoints?.md || 6}
                            key={locIndex}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: "-10px",
                              }}
                            >
                              <components.CheckboxComponent
                                checked={values[locIndex].value}
                                onChange={(e: any) =>
                                  handleLocationData(locIndex, values, e.target?.checked)
                                }
                              />
                              <Typography sx={{ fontWeight: 400, fontSize: "15px" }}>
                                {locData.label}
                              </Typography>
                            </div>
                          </Grid>
                        );
                      } else {
                        return (
                          <Grid
                            item
                            sm={locData.gridBreakpoints?.sm || 12}
                            lg={locData.gridBreakpoints?.lg || 6}
                            md={locData.gridBreakpoints?.md || 6}
                            key={locIndex}
                          >
                            <components.TextInputField
                              label={locData.label}
                              name={locData.name}
                              placeholder={locData.label}
                              errorText={getErrorText(
                                errors,
                                locIndex,
                                values[locIndex].value,
                                locData.name
                              )}
                              onChange={(e: any) =>
                                handleLocationData(locIndex, values, e.target?.value)
                              }
                              error={getIsErrorValue(
                                errors,
                                locIndex,
                                values[locIndex].value,
                                "",
                                locData.name,
                                locData.name
                              )}
                              onBlur={handleBlur}
                              disabled={locData.isDisabled || false}
                              stackStyle={{ mb: 0 }}
                              value={values[locIndex].value}
                              multiline={locData.isMultilineInput ? true : false}
                              rows={7}
                            />
                          </Grid>
                        );
                      }
                    })}
                  <Grid item>
                    <components.LabelImagesListingComponent
                      label={classDetailsConst.LOCATION_IMAGES}
                      imagesList={images.locationImages || []}
                      imageContainerClassName={style.locationImageContainer}
                      labelClassName={style.label}
                      imgAttribute="url"
                      isDeleteIconVisible
                      showUploadImageContainer={
                        images.locationImages.length < 4 ? true : false
                      }
                      onDeleteClick={(data: any) =>
                        handleRemoveImage(data, "locationImages", setImages)
                      }
                      handleImageSelect={(e: any) => handleFilePick(e, "locationImages")}
                    />
                  </Grid>
                  <Grid item sm={12} lg={12} md={12}>
                    <Divider />
                    <components.ButtonsListComponent
                      isLoading={isUpdating}
                      buttonsIndexForLoader={[0]}
                      buttonsArray={twoButtonList()}
                      onButtonClick={(data: any) => handleButtonClick(data, navigate)}
                    />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}
      {classData.isLoading && (
        <div className={style.progressBarContainer}>
          <components.CircularProgressBar
            loadername={classDetailsConst.PROGRESS_BAR_TEXT}
          />
        </div>
      )}
      {openCropper.isOpen && (
        <components.ImageCropDialog
          openPopup={openCropper.isOpen}
          selectedFile={selectedImage}
          handleButtonClick={(data: any) =>
            handleCropperButtonClick(
              data,
              setOpenCropper,
              setImages,
              setMsg,
              openCropper.imageType,
              images
            )
          }
        />
      )}
    </>
  );
}
