/**
 * Show toast
 */

export const showToast = (setMsg: any, message: string | number, type: any) => {
  setMsg({
    show: true,
    type: type,
    msg: message,
    errorType: "default",
  });
};
