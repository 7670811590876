import {
  checkForWholeYear,
  dateForDashboard,
  filterDateFormat,
} from "./dateTimeFormatFunction";

export const checkCountrySelected = (countryArray: any) => {
  let selectedCountry = "";
  Array.isArray(countryArray) &&
    countryArray.forEach((country: any) => {
      if (country.isChecked) {
        selectedCountry = country.id;
      }
    });
  return selectedCountry;
};

export const getSelectedFilters = (selectedFilters: any) => {
  const selectedCountry: any = checkCountrySelected(selectedFilters?.country);
  return {
    ...(selectedFilters?.selectedDates[0] && {
      startDate: filterDateFormat(selectedFilters?.selectedDates[0]),
    }),
    ...(selectedFilters?.selectedDates[1] && {
      endDate: filterDateFormat(selectedFilters?.selectedDates[1]),
    }),
    ...(selectedCountry && {
      countryId: selectedCountry,
    }),
  };
};

export const getFilteredDate = (
  selectedFilters: { selectedDates: any[] },
  isNotDefaultCurrentYear?: any
) => {
  if (selectedFilters) {
    if (checkForWholeYear(selectedFilters.selectedDates)) {
      return `Year ${selectedFilters.selectedDates[0]?.getFullYear()}`;
    }
    return dateForDashboard(selectedFilters.selectedDates);
  } else {
    if (!isNotDefaultCurrentYear) {
      return `Year ${new Date().getFullYear()}`;
    } else {
      return "";
    }
  }
};
