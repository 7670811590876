import { NavigateFunction } from "react-router-dom";
import { Dispatch, SetStateAction } from "react";

import { modifyEditClassPayload } from "./modifyPayload";
import { updateClassController } from "../../../../controllers/classesController";
import { errorConst, successConst } from "../../../text-constants";

export const handleEditClassSubmit = (
  values: any,
  images: any,
  classId: number | string,
  setMsg: any,
  navigate: NavigateFunction,
  setIsUpdating: Dispatch<SetStateAction<boolean>>,
  copyData: any
) => {
  setIsUpdating(true);
  var object = values?.reduce(
    (obj: any, item: any) => (
      (obj[item.name] =
        item.name === "tagsForClass" ? item.tags : item.value === " " ? "" : item.value),
      obj
    ),
    {}
  );

  const modifiedPayload = modifyEditClassPayload(object, images, copyData, setMsg);

  if (modifiedPayload) {
    updateClassController(
      () => {
        setIsUpdating(false);
        successUpdateClass(setMsg, navigate, object.classTitle);
      },
      (error: any) => {
        setIsUpdating(false);
        errorUpdateClass(error, setMsg);
      },
      classId,
      modifiedPayload
    );
  } else {
    setIsUpdating(false);
  }
};

function successUpdateClass(setMsg: any, navigate: NavigateFunction, classTitle = "") {
  setMsg({
    show: true,
    type: "success",
    msg: `Class "${classTitle}" ${successConst.UPDATE_SUCCESSFUL}`,
    errorType: "default",
  });

  setTimeout(() => navigate(-1), 1500);
}

function errorUpdateClass(error: any, setMsg: any) {
  setMsg({
    show: true,
    type: "error",
    msg: error?.data?.message || error?.message || errorConst.UNKNOW_ERROR,
    errorType: "default",
  });
}
