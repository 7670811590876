export const BASE_API_URL = `${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_VERSION}/admin`;
export const API_TIMEOUT = `5000`;

export const PATH_PARAMS = {
  LOGIN: `${BASE_API_URL}/login`,
  FORGOT_PASSWORD: `${BASE_API_URL}/forgotPassword`,
  RESET_PASSWORD: `${BASE_API_URL}/resetPassword`,
  VERIFY_TOKEN: `${BASE_API_URL}/verify-token`,

  FETCH_CLASS_GRAPH_DATA: `${BASE_API_URL}/graph-class`,
  FETCH_USER_GRAPH_DATA: `${BASE_API_URL}/graph-user`,
  FETCH_PERCENT_CLASS_DATA: `${BASE_API_URL}/graph-complete-class`,
  FETCH_BOOKINGS_GRAPH_DATA: `${BASE_API_URL}/graph-bookings`,

  GET_DASHBOARD_USER_LIST: `${BASE_API_URL}/dashboard/user/list`,
  GET_DASHBOARD_USER_COUNT: `${BASE_API_URL}/dashboard/user/count`,
  GET_DASHBOARD_CLASS_LIST: `${BASE_API_URL}/dashboard/class/list`,

  FETCH_CLASSES: `${BASE_API_URL}/dashboard/class/list`,
  FETCH_PROMOTED_CLASSES: `${BASE_API_URL}/promoted/class/list`,
  ADD_PROMOTED_CLASS: `${BASE_API_URL}/promoted/addupdateclass`,
  PROMOTED_CLASS_SWAP_SEQUENCE: `${BASE_API_URL}/promoted/swapsequence`,

  FETCH_ALL_CLASSES_DATA: `${BASE_API_URL}/export/dashboard/class/list`,
  FETCH_ALL_USERS_DATA: `${BASE_API_URL}/export/dashboard/user/list`,
  FETCH_ALL_GUIDES_DATA: `${BASE_API_URL}/export/guide/list`,
  FETCH_ALL_EXPLORER_DATA: `${BASE_API_URL}/export/explorer/list`,
  FETCH_EACH_CLASS_DATA: `${BASE_API_URL}/class/`,
  EXPORT_ALL_BOOKINGS_DATA: `${BASE_API_URL}/export/dashboard/booking/list`,
  GET_BOOKINGS_LIST: `${BASE_API_URL}/dashboard/booking/list`,

  GET_DASHBOARD_CLASS_COUNT: `${BASE_API_URL}/dashboard/class/count`,

  CHANGE_PASSWORD: `${BASE_API_URL}/change-password`,

  CREATE_NEW_ADMIN: `${BASE_API_URL}/createUser`,
  FETCH_ADMINS_LIST: `${BASE_API_URL}/user/list`,
  FETCH_CLASSES_LIST: `${BASE_API_URL}/dashboard/class/list`,
  FETCH_EXISTING_USER_DATA: `${BASE_API_URL}/checkUser`,

  GET_GUIDES_LIST: `${BASE_API_URL}/guide/list`,

  EDIT_ADMIN_DETAILS: `${BASE_API_URL}/user/update/`,
  DELETE_ADMIN: `${BASE_API_URL}/user/delete/`,
  FETCH_SINGLE_ADMIN: `${BASE_API_URL}/user/`,

  CANCEL_CLASS: `${BASE_API_URL}/class/cancelClassByAdmin`,
  GET_CANCEL_CLASS_REASON: `${BASE_API_URL}/class/getCancellationReasons/Guide`,
  UPDATE_CLASS: `${BASE_API_URL}/class/updateClass/`,

  GET_EXPLORERS_LIST: `${BASE_API_URL}/explorer/list`,
  GET_SINGLE_GUIDE_DETAILS: `${BASE_API_URL}/guide/`,
  GET_GUIDE_CLASS: `${BASE_API_URL}/guide/class-list/`,
  GET_SINGLE_EXPLORER_DETAILS: `${BASE_API_URL}/explorer/`,
  GET_EXPLORER_CLASS: `${BASE_API_URL}/explorer/class-list/`,
  UPDATE_GUIDE: `${BASE_API_URL}/guide/`,
  UPDATE_EXPLORER: `${BASE_API_URL}/explorer/`,

  GET_BROADCAST_MESSAGES_LIST: `${BASE_API_URL}/broadcastMessages/list`,
  GET_VIEW_BROADCAST_DATA: `${BASE_API_URL}/broadcastMessages/`,
  DELETE_BROADCAST_MESSAGE: `${BASE_API_URL}/broadcastMessages/`,
  CREATE_BROADCAST_MESSAGE: `${BASE_API_URL}/broadcastMessages`,

  GET_COUPONS_LIST: `${BASE_API_URL}/coupons`,
  CREATE_GENERIC_COUPON: `${BASE_API_URL}/coupon/createCoupon`,
  GET_EACH_COUPON_DETAILS: `${BASE_API_URL}/coupon/`,
  UPDATE_COUPON: `${BASE_API_URL}/coupons/`,
  GET_EMAIL_TEMPLATES: `${BASE_API_URL}/template/list`,
  GET_TEMPLATE_DETAILS: `${BASE_API_URL}/template/`,
  UPDATE_EMAIL_TEMPLATE: `${BASE_API_URL}/template/`,
  PREVIEW_EMAIL: `${BASE_API_URL}/template/preview`,
};

export const PATH_PARAMS_END = {
  CLASS_END: "",
};
