import { Box, Divider, Grid, Typography } from "@mui/material";
import * as React from "react";
import { IMAGES } from "../../assets/images";
import { useNavigate } from "react-router-dom";

import * as components from "../../components";
import style from "../compoundComponents.module.scss";
import { dashboardConst, errorConst } from "../../utils/text-constants";

/**
 * reference page:
 * view guide (path: /admin/view-guide) or
 * view explorer page (path: /admin/explorer-details)
 */

const DetailsViewPageComponent = (props: any): JSX.Element => {
  const {
    pageState,
    setPageState,
    viewData,
    button1,
    button2,
    tableColumns = [],
    showViews,
  } = props;

  const navigate = useNavigate();

  return (
    <>
      {!viewData?.isLoading && viewData?.data && (
        <Grid container spacing={3} p={2.5} pl={3}>
          <Grid
            item
            sm={12}
            md={12}
            lg={12}
            display="flex"
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems="center"
          >
            <components.RoundImageComponent
              src={viewData?.profileImage || IMAGES.emptyProfileImg}
            />
            <Typography className={style.name}>{viewData?.name}</Typography>
          </Grid>
          <Grid item sm={12} md={12} lg={12}>
            <Divider />
          </Grid>
          {Array.isArray(viewData?.data) &&
            viewData.data.map((eachData: any, index: number) => {
              if (eachData.displayType === "taggings") {
                return (
                  <Grid item sm={12} lg={12} md={12} key={index}>
                    <components.SimpleLableDataComponent label={eachData.label} />
                    <components.SimpleTagList tagsList={eachData.data} />
                  </Grid>
                );
              }
              return (
                <Grid item sm={12} lg={6} md={6} key={index}>
                  <components.SimpleLableDataComponent
                    label={eachData.label}
                    value={eachData.data}
                  />
                </Grid>
              );
            })}
          <Grid item sm={12} md={12} lg={12}>
            <Divider />
          </Grid>
          <Grid item sm={12} lg={12} md={12}>
            {showViews?.button1 && (
              <components.LoadingButtonComponent
                buttonClassName={style.editButton}
                buttonLabel={button1.label || "Edit"}
                labelImg={button1?.image || IMAGES.editPencilIcon}
                handleClick={button1.handleClick}
              />
            )}
            {showViews?.button2 && (
              <components.LoadingButtonComponent
                buttonLabel={button2?.label || "Back"}
                buttonClassName={style.backbutton}
                handleClick={() => button2?.handleClick() || navigate(-1)}
              />
            )}
          </Grid>
          <Grid item sm={12} md={12} lg={12} mt={4}>
            <Typography className={style.mediumFontHeader}>Class Details</Typography>
          </Grid>
          <Grid item sm={12} lg={12} md={12}>
            <components.DataGridComponent
              columns={tableColumns}
              rows={pageState?.data}
              rowCount={pageState?.total}
              loading={pageState?.isLoading}
              page={pageState?.page}
              pageSize={pageState?.pageSize}
              onPageChange={(page: any) => {
                setPageState({ ...pageState, page });
              }}
              onPageSizeChange={(pageSize: any) => {
                setPageState({ ...pageState, pageSize });
              }}
            />
          </Grid>
        </Grid>
      )}
      {viewData?.isLoading && (
        <Box className={style.progressBarContainer}>
          <components.CircularProgressBar loadername={dashboardConst.LOADING_DATA} />
        </Box>
      )}
      {!viewData?.isLoading && !viewData?.data && (
        <Box className={style.errorPageContainer} gap={3}>
          <img
            src={IMAGES.linkExpiredImg}
            style={{ width: "fit-content", height: "fit-content" }}
          />
          <h2>{errorConst.OOPS_SOMETHING_WENT_WRONG}</h2>
        </Box>
      )}
    </>
  );
};

export { DetailsViewPageComponent };
