import * as React from "react";
import DashboardComponent from "../../modules/dashboard/DashboardComponent";

const DashboardPage: React.FC = () => {
  return (
    <>
      <DashboardComponent />
    </>
  );
};

export default DashboardPage;
