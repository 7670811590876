/**
 * function to handle drag and drop feature
 */

export function handleOnDragFunction(
  boxId: number | string,
  setDragId: { (value: any): void; (arg0: string | number): void }
) {
  setDragId(boxId);
}

export function handleDropFunction(
  boxId: number | string,
  dataArray: any,
  setData: any,
  dragBoxId: number | string,
  idAttributeName?: string
) {
  const dragBox = dataArray.findIndex(
    (data: any) => data[idAttributeName || "class_id"] === dragBoxId
  );
  const dropBox = dataArray.findIndex(
    (data: any) => data[idAttributeName || "class_id"] === boxId
  );

  if (
    dragBox >= 0 &&
    dropBox >= 0 &&
    dragBox !== undefined &&
    dragBox !== null &&
    dropBox !== undefined &&
    dropBox !== null
  ) {
    const dragData = dataArray[dragBox];
    dataArray[dragBox] = dataArray[dropBox];
    dataArray[dropBox] = dragData;
  }
  setData(() => [...dataArray]);
}

/**handle drop function for array of string|number */

export function handleDropFunction2(
  boxId: number | string,
  dataArray: any,
  dragBoxId: number | string
) {
  if (
    boxId >= 0 &&
    dragBoxId >= 0 &&
    boxId !== undefined &&
    dragBoxId !== undefined &&
    boxId !== null &&
    dragBoxId !== null
  ) {
    const dragData = dataArray[dragBoxId];
    dataArray[dragBoxId] = dataArray[boxId];
    dataArray[boxId] = dragData;
  }
  return dataArray;
}
