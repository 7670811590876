import * as React from "react";
import style from "./imageComponent.module.scss";

function RoundImageComponent(props: any) {
  const { src } = props;

  return <img src={src} className={style.roundImgContainer} />;
}

export { RoundImageComponent };
