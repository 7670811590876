import { Typography } from "@mui/material";
import * as React from "react";

import style from "./targetedLocation.module.scss";
import * as components from "..";
import { IMAGES } from "../../assets/images";
import { alertConst } from "../../utils/text-constants";

function TargetedCityComponent(props: any) {
  const {
    eachLocation,
    handleAddCitiesClick,
    handleSelectAllCitiesClick,
    onCityDeleteClick,
    error,
    errorText,
  } = props;
  return (
    <div
      style={{
        gap: "10px",
        display: "flex",
        flexDirection: "column",
        marginBottom: "30px",
      }}
    >
      <Typography sx={{ fontWeight: 400, fontSize: "15px", marginTop: "20px" }}>
        Targeted City
      </Typography>
      <div
        className={style.citiesListAddContainer}
        style={{
          gap: eachLocation?.city?.length ? 20 : 0,
          ...(error && { borderColor: "#F73030" }),
        }}
      >
        <components.SimpleTagList
          isDeleteIconPresent
          tagsList={eachLocation?.city.map((eachCity: any) => {
            const stateName = eachCity.stateName ? `(${eachCity.stateName})` : "";
            const combinedName = `${eachCity.Name} ${stateName}`;
            return {
              name: combinedName,
              id: eachCity.Id,
              title: "Remove City",
            };
          })}
          onDeleteClick={onCityDeleteClick}
        />
        {!eachLocation.isSelectAll ? (
          <components.LoadingButtonComponent
            isLoading={false}
            buttonLabel={"Add Cities"}
            handleClick={handleAddCitiesClick}
            buttonStyle={{ width: "fit-content" }}
            endIcon={<img src={IMAGES.addIcon} />}
          />
        ) : (
          <Typography
            className={style.selectedAllCityMsg}
          >{`You have selected all the cities of ${eachLocation.countryName}`}</Typography>
        )}
      </div>
      {error && errorText && (
        <Typography className={style.citiesEmptyErrorText}>
          {alertConst.CITY_FIELD_CANT_EMPTY}
        </Typography>
      )}
      <components.CheckboxAndText
        text={"Select All Cities"}
        isChecked={eachLocation.isSelectAll}
        onChange={handleSelectAllCitiesClick}
      />
    </div>
  );
}

export { TargetedCityComponent };
