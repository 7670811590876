import * as React from "react";
import { LoadingButton } from "@mui/lab";
import style from "./buttons.module.scss";

function LoadingButtonComponent(props: any) {
  const {
    buttonLabel,
    labelImg,
    variant = "outlined",
    isLoading = false,
    endIcon,
    buttonStyle,
    buttonClassName,
    handleClick,
    type = "button",
    disabled = false,
    startIcon
  } = props;
  return (
    <LoadingButton
      type={type}
      sx={{
        ...{
          color: "#FD6E3F",
          borderColor: "#FD6E3F",
          ":hover": { backgroundColor: "rgba(253, 110, 63, 0.1)" },
        },
        ...buttonStyle,
      }}
      startIcon={<img src={startIcon}/>}
      endIcon={endIcon}
      loading={isLoading}
      variant={variant}
      className={`${style.button} ${buttonClassName}`}
      onClick={handleClick}
      loadingPosition="end"
      disabled={disabled}
    >
      {buttonLabel}
      {labelImg && <img style={{ marginLeft: "10px" }} src={labelImg} />}
    </LoadingButton>
  );
}

export { LoadingButtonComponent };
