import { Grid } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import * as components from "../../../../components";
import { getDateFormat } from "../../../../utils/reusable-functions/dateTimeFormatFunction";
import {
  fetchCouponsController,
  updateCouponController,
} from "../../../../controllers/couponsController";
import { couponsTableHeader } from "../../../../utils/reusable-functions/coupons/listingTableHeaders";
import useMessage from "../../../../hooks/customHooks/useMessage";
import { showToast } from "../../../../utils/helper-functions/helperFunction";
import { errorConst } from "../../../../utils/text-constants";
import { IMAGES } from "../../../../assets/images";

export default function CouponsTabsDataComponent(props: any) {
  const { pageIndex } = props;
  const { setMsg } = useMessage();
  const navigate = useNavigate();

  const [pageState, setPageState] = React.useState({
    firstCreatedClassDate: "",
    isLoading: true,
    data: [],
    total: 0,
    page: 0,
    pageSize: 10,
  });

  const [searchText, setSearchText] = React.useState("");
  const [searchTextForApi, setSearchTextForApi] = React.useState("");

  function viewCouponDetailsFunction(id: number | string) {
    navigate("/admin/view-coupon-details", {
      state: { couponId: id, pageIndex: pageIndex === 1 ? 1 : 0 },
    });
  }

  function getPageName() {
    if (pageIndex === 0) {
      return "Referral";
    } else if (pageIndex === 1) {
      return "Generic";
    } else {
      return "";
    }
  }

  function handleStatusToggleClick(couponId: number | string, value: boolean) {
    updateCouponController(
      () => {
        fetchData();
      },
      (error: any) => {
        showToast(
          setMsg,
          error.message || error.data?.message || errorConst.UNKNOW_ERROR,
          "error"
        );
      },
      couponId,
      { status: value ? "Active" : "InActive" }
    );
  }

  React.useEffect(() => {
    fetchData();
  }, [searchTextForApi, pageState.page, pageState.pageSize]);

  function fetchData() {
    setPageState((prev: any) => ({
      ...prev,
      isLoading: true,
    }));

    fetchCouponsController(
      (response: any) => {
        const data = response?.coupons?.map((item: any, i: number) => {
          const id = i + 1 + pageState.page * pageState.pageSize;
          const modifiedFields = {
            ...(item.startDate && { startDate: getDateFormat(item.startDate) }),
            ...(item.endDate && { endDate: getDateFormat(item.endDate) }),
          };
          return { ...item, id, ...modifiedFields };
        });
        setPageState((previous: any) => ({
          ...previous,
          isLoading: false,
          data,
          total: response.count,
        }));
      },
      (error: any) => {
        setPageState((prev: any) => ({
          ...prev,
          isLoading: false,
          data: [],
        }));
        showToast(
          setMsg,
          error.message || error.data?.message || errorConst.UNKNOW_ERROR,
          "error"
        );
      },
      {
        page: pageState.page + 1,
        limit: pageState.pageSize,
        category: getPageName(),
        ...(searchTextForApi && { search: searchTextForApi?.toString()?.trim() }),
      }
    );
  }

  return (
    <Grid container spacing={3} pt={2}>
      <Grid item sm={12} md={12} lg={6} justifyContent="flex-start">
        {pageIndex === 1 && (
          <components.LoadingButtonComponent
            buttonLabel={"Create Coupon"}
            handleClick={() => navigate("/admin/create-coupon")}
            endIcon={<img src={IMAGES.addIcon} />}
          />
        )}
      </Grid>
      <Grid item sm={12} md={12} lg={6} display={"flex"} justifyContent="flex-end">
        <components.SearchInputField
          value={searchText}
          placeholder={"Search Coupon"}
          onChange={(e: any) => {
            !e.target.value && setSearchTextForApi("");
            setSearchText(e.target.value);
          }}
          onSearchClick={() => {
            setSearchTextForApi(searchText);
          }}
          onClearClick={() => {
            setSearchText("");
            setSearchTextForApi("");
          }}
        />
      </Grid>
      <Grid item sm={12} md={12} lg={12}>
        <components.DataGridComponent
          columns={couponsTableHeader(
            pageIndex,
            viewCouponDetailsFunction,
            handleStatusToggleClick
          )}
          rows={pageState.data}
          rowCount={pageState.total}
          loading={pageState.isLoading}
          page={pageState.page}
          pageSize={pageState.pageSize}
          onPageChange={(page: any) => {
            setPageState({ ...pageState, page });
          }}
          onPageSizeChange={(pageSize: any) => {
            setPageState({ ...pageState, pageSize });
          }}
        />
      </Grid>
    </Grid>
  );
}
