import { getDateFormat } from "./dateTimeFormatFunction";

export const getRequiredUserData = (usersData: any[] = []) => {
  const filteredData = usersData.filter((user) => user.role !== "Admin");
  return filteredData.map((eachUser: any) => {
    return {
      "User ID": eachUser.userId,
      "User Role": eachUser.role,
      "User Handle": eachUser.userHandle,
      "First Name": eachUser.firstName,
      "Last Name": eachUser.lastName,
      "Date of Birth": getDateFormat(eachUser.dateOfBirth),
      "Phone Number": eachUser.phone,
      Email: eachUser.email,
      "Is email verified (Yes / No)": getBooleanValue(eachUser.emailVerified?.isVerified),
      "Email Verification date (If Email is verified)": getDateFormat(
        eachUser.emailVerified?.verifiedOn
      ),
      Language: eachUser.languagesKnown?.join(", "),
      Country: eachUser.countryId,
      City: eachUser.cityName,
      "How did the user hear about app": eachUser.howDidYouHear,
      "Signed Up Date - As Exlplorer (DD/MM/YYY, HH:MM:SS (A/P)M)": getDateFormat(
        getDataBasedOnRole(eachUser.role, eachUser.explorerSignUpDate, "")
      ),
      "Signed Up Date - As Guide (DD/MM/YYY, HH:MM:SS (A/P)M)": getDateFormat(
        getDataBasedOnRole(eachUser.role, "", eachUser.guideSignUpDate)
      ),
      Pronoun: getDataBasedOnRole(eachUser.role, eachUser.pronoun, eachUser.guidePronoun),
      "Explorer Bio": eachUser.explorerBio,
      "Guide Bio": eachUser.guideBio,
      "Agreed For Recieving Marketing Campaign": getBooleanValue(
        eachUser.agreedForRecievingMarketingCampaign
      ),
    };
  });
};

function getDataBasedOnRole(role: string, data1 = "", data2 = "") {
  if (role === "Explorer") {
    return data1;
  } else if (role === "Guide") {
    return data2;
  } else {
    return "";
  }
}

function getBooleanValue(data: any) {
  if (data) {
    return "Yes";
  } else {
    return "No";
  }
}
