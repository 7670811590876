import * as React from "react";
import { Line } from "react-chartjs-2";
import theme from "../../../themes/theme";

function LineChartComponent(props: any) {
  const { options, chartData } = props;

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 3.5
      },
      line: {
        tension: 0, // disables bezier curves
      },
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: theme.palette.brown.main,
          font: {
            size: "12px",
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        min: 0,
        ticks: {
          color: theme.palette.brown.main,
          font: {
            size: "12px",
          },
        },
        grid: {
          drawBorder: false,
          display: true,
        },
      },
    },
    ...options,
  };
  return (
    <div style={{ width: "100%", minHeight: "300px" }}>
      <Line data={chartData} options={chartOptions} />
    </div>
  );
}

export { LineChartComponent };
