import { Box, Typography } from "@mui/material";
import * as React from "react";

import { IMAGES } from "../../assets/images";
import style from "./textDelete.module.scss";

function TextDeleteComponent(props: any) {
  const { text, prefixIcon, handleDeleteClick, boxStyle, index } = props;
  return (
    <Box className={`${style.textDeleteContainer} ${boxStyle}`} id={`draggable-${index}`}>
      <div className={style.dotAndTextStyle}>
        <img src={prefixIcon} style={{ marginRight: "12px" }} />
        <Typography className={style.textStyle}>{` ${text}`}</Typography>
      </div>
      <img
        title={"Remove"}
        src={IMAGES.trashIcon}
        className={style.deleteIcon}
        onClick={handleDeleteClick}
      />
    </Box>
  );
}

export { TextDeleteComponent };
