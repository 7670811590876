/**this page is for handling functionality of listing classes page */

import { errorConst } from "../../../text-constants";
import {
  fetchClassesController,
  getCancelClassReasonController,
} from "../../../../controllers/classesController";

export const getClassesList = (setState: any, state: any, setMsg: any, params?: any) => {
  setState((prev: any) => ({
    ...prev,
    isLoading: true,
  }));

  fetchClassesController(
    (response: any) => successFetch(response, setState, state, setMsg),
    (error: any) => errorFetch(error, setState, setMsg),
    {
      skip: state.page + 1,
      limit: state.pageSize,
      ...params,
    }
  );
};

function successFetch(response: any, setState: any, state: any, setMsg: any) {
  const data = response.data.map((item: any, i: number) => {
    const id = i + 1 + state.page * state.pageSize;
    const classStatus = () => {
      if (item.class_status === "Scheduled") {
        if (item.is_active) {
          return "Published";
        } else {
          return "Unpublished";
        }
      } else {
        return item.class_status;
      }
    };
    return { ...item, id, class_status1: classStatus() };
  });

  setState((prev: any) => ({
    ...prev,
    isLoading: false,
    data,
    total: response.count,
    firstCreatedClassDate: response.firstClassCreatedOn,
    latestScheduledClassStartTime: response.latestScheduledClassStartTime
  }));
}

function errorFetch(error: any, setState: any, setMsg: any) {
  setState((prev: any) => ({
    ...prev,
    isLoading: false,
  }));
  setMsg({
    show: true,
    type: "error",
    msg: getErrorMsg(error),
    errorType: "default",
  });
}

function getErrorMsg(error: any) {
  return error.message || error?.data?.message || errorConst.UNKNOW_ERROR;
}

//get class cancel reason api

export const getCancelClassReason = (setState: any) => {
  getCancelClassReasonController(
    (response: any) => successReasonFetch(response, setState),
    (error: any) => errorReasonFetch(error)
  );
};

function successReasonFetch(response: any, setState: any) {
  const modifiedResponse = response.map((reason: any) => {
    return { label: reason.reasonDisplay, value: reason.reasonId };
  });

  modifiedResponse.push({ label: "Other", value: "others" });
  setState(modifiedResponse);
}

function errorReasonFetch(error: any) {
  console.log(error);
}
