//check whether the date time less than 48 hrs

export function checkDateLessThan48hrs(date: any) {
  const msBetweenDates = Math.abs(new Date(date).getTime() - new Date().getTime());
  const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000 );

  if (new Date(date).getTime() >= new Date().getTime() && hoursBetweenDates < 24) {
    return true;
  } else if(new Date(date).getTime() <= new Date().getTime()) {
    return true;
  } else {
    return false
  }
}
