import { Box, Typography } from "@mui/material";
import * as React from "react";
import theme from "../../themes/theme";

function ColorAndTextComponent(props: any) {
  const { color, text, borderColor, count } = props;
  return (
    <>
      <Box display={"flex"} flexDirection={"row"} gap={1}>
        <Box
          bgcolor={color || "white"}
          component="div"
          sx={{ width: "14px", height: "14px", border: `1px solid ${borderColor}` }}
        />
        <Box display={"flex"} flexDirection={"row"} gap={0.3}>
          <Typography sx={{ color: theme.palette.secondary.contrastText }}>
            {text}
          </Typography>
          <Typography sx={{ fontWeight: 600 }}>({count})</Typography>
        </Box>
      </Box>
    </>
  );
}

export { ColorAndTextComponent };
