const getItem = (key: any, defaultValue: any) => {
  const value = localStorage.getItem(key);
  return value || defaultValue;
};

export const getIdToken = () => getItem("token", "");

export const setIdToken = (value: any) => {
  localStorage.setItem("token", value);
};

export const setUserDetails = (value: any) => {
  localStorage.setItem("user", JSON.stringify(value));
};

export const setUserId = (value: any) => {
  localStorage.setItem("userId", value);
};

export const clearStorage = async () => {
  localStorage.clear();
};
