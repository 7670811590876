import { createTheme } from "@mui/material/styles";

const customTheme = createTheme({
  palette: {
    // sx={{ color: 'themename.main' }}
    primary: {
      main: "#000000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#FADF88",
      contrastText: "#353535",
    },
    success: {
      main: "#2e7d32",
      light: "#4caf50",
      dark: "#1b5e20",
      contrastText: "#FFFFFF",
    },
    info: {
      main: "#0288d1",
      light: "#03a9f4",
      dark: "#01579b",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#ed6c02",
      light: "#ff9800",
      dark: "#e65100",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#F73030",
      light: "#ef5350",
      dark: "#c62828",
      contrastText: "#FFFFFF",
    },
    darkGrey: {
      main: "#0D3334",
    },
    yellow: {
      main: "#FFB985",
      light: "#FADF88",
    },
    brown: {
      main: "#A19191",
      light: "#353535",
      dark: "#646464",
    },
    teal: {
      main: "#2D3178",
      light: "#475FA8",
    },
    orange: {
      main: "#FFB985",
    },
  },
  typography: {
    allVariants: {
      fontFamily: "'Poppins', Arial, sans-serif",
      textTransform: "none",
      fontSize: 12,
    },
    h1: {
      fontSize: "3rem",
      fontWeight: 400,
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 400,
    },
    h3: {
      fontSize: "1.875rem",
      lineHeight: "2.25rem",
      fontWeight: 600,
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 400,
    },
    h5: {
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: 400,
    },
    h6: {
      fontSize: "15px",
      lineHeight: "22.5px",
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: "14px",
      lineHeight: "22px",
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: "14px",
      lineHeight: "22px",
      fontWeight: 600,
    },
    body1: {
      fontSize: "12px",
      lineHeight: "18px",
      fontWeight: 400,
    },
    body2: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 400,
    },
    button: {
      fontSize: "18px",
      lineHeight: "27px",
      fontWeight: 600,
    },
    caption: {
      fontSize: "14px",
      lineHeight: "22px",
      fontWeight: 500,
    },
    overline: {
      fontSize: "13px",
      lineHeight: "16px",
      fontWeight: 400,
    },
    largerFont: { fontSize: "20px" },
    mediateFont: { fontSize: "16px" },
    mediumFont: { fontSize: "14px" },
    smallFont: { fontSize: "12px", fontWeight: "500", lineHeight: "22px" },
    smallerFont: { fontSize: "10px" },
    extraSmallFont: { fontSize: "9px" },
  },
});

declare module "@mui/material/styles" {
  interface Palette {
    orange: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    orange?: PaletteOptions["primary"];
  }

  interface Palette {
    yellow: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    yellow: PaletteOptions["primary"];
  }

  interface Palette {
    teal: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    teal?: PaletteOptions["primary"];
  }

  interface Palette {
    brown: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    brown?: PaletteOptions["primary"];
  }

  interface Palette {
    darkGrey: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    darkGrey?: PaletteOptions["primary"];
  }

  interface TypographyVariants {
    largerFont: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    largerFont?: React.CSSProperties;
  }

  interface TypographyVariants {
    mediateFont: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    mediateFont?: React.CSSProperties;
  }

  interface TypographyVariants {
    mediumFont: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    mediumFont?: React.CSSProperties;
  }

  interface TypographyVariants {
    smallFont: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    smallFont?: React.CSSProperties;
  }

  interface TypographyVariants {
    smallerFont: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    smallerFont?: React.CSSProperties;
  }

  interface TypographyVariants {
    extraSmallFont: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    extraSmallFont?: React.CSSProperties;
  }
}

export default customTheme;
