export const explorerListConst = {
  DOWNLOAD_REPORT: "Download Report",
  FILTER: "Filter",
  SEARCH_EXPLORER: "Search Explorer",
};

export const explorerDetailsConst = {
  COVER_IMAGE: "Cover image",
  OTHER_IMAGE: "Other images",
  LOCATION_IMAGES: "Location Images",
  CHECKIN_DETAILS_GUIDE: "Check In Details - Guide",
  CHECKIN_DETAILS_EXPLORER: "Check In Details - Explorer",
  LOADER_TEXT: "Explorer details loading, please wait...",
};

export const guideEditConst = {};
