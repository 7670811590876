import * as React from "react";
import { useNavigate } from "react-router-dom";

import { DataGridComponent } from "../../../components";
import { emailTemplateTableHeaders } from "../../../utils/reusable-functions/email-templates/tableHeaders";
import { fetchEmailTemplatesController } from "../../../controllers/emailTemplateController";
import { showToast } from "../../../utils/helper-functions/helperFunction";
import useMessage from "../../../hooks/customHooks/useMessage";
import { errorConst } from "../../../utils/text-constants";

export default function EmailTemplateListingPage() {
  const navigate = useNavigate();
  const { setMsg } = useMessage();

  const [pageState, setPageState] = React.useState({
    isLoading: true,
    data: [],
    total: 0,
    page: 0,
    pageSize: 10,
  });

  function editButtonClickFunction(editData: any) {
    console.log({ editData });
    navigate("/admin/edit-email-template", { state: { id: editData.id } });
  }

  React.useEffect(() => {
    fetchTemplates();
  }, [pageState.page, pageState.pageSize]);

  function fetchTemplates() {
    fetchEmailTemplatesController(
      (response: any) => {
        const data = response?.data?.map((item: any, i: number) => {
          const id = i + 1 + pageState.page * pageState.pageSize;
          return { ...item, id };
        });

        setPageState((previous: any) => ({
          ...previous,
          isLoading: false,
          data,
          total: response.count,
        }));
      },
      (error: any) => {
        showToast(
          setMsg,
          error.message || error.data?.message || errorConst.UNKNOW_ERROR,
          "error"
        );
      },
      {
        skip: pageState.page + 1,
        limit: pageState.pageSize,
      }
    );
  }

  return (
    <DataGridComponent
      columns={emailTemplateTableHeaders(editButtonClickFunction)}
      rows={pageState.data}
      rowCount={pageState.total}
      loading={pageState.isLoading}
      page={pageState.page}
      pageSize={pageState.pageSize}
      onPageChange={(page: any) => {
        setPageState({ ...pageState, page });
      }}
      onPageSizeChange={(pageSize: any) => {
        setPageState({ ...pageState, pageSize });
      }}
    />
  );
}
