export function getCountryName(countryId: string) {
  switch (countryId) {
    case "TW":
      return "Taiwan";
    case "AU":
      return "Australia";
    case "US":
      return "United States";
    default:
      return "";
  }
}
