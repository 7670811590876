import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import { AdminNavigation } from "../../nav";
import { clearStorage } from "../../utils/auth";
import { getTopAppbarText } from "../../utils/reusable-functions/sideNavbarFunctions";
import { NAV_ICONS, IMAGES } from "../../assets/images";
import theme from "../../themes/theme";

const Sidebar = (props: any): JSX.Element => {
  const { setSelectedPage, drawerWidth } = props;
  const navigate = useNavigate();

  const handleNavigation = (item: any) => {
    navigate(`${item.path}`);
    setSelectedPage(getTopAppbarText());
  };

  const handleLogout = () => {
    clearStorage();
    navigate("/");
  };

  const isCurrentPageSelected = (item: { name: string }) => {
    return getTopAppbarText() === item.name ? true : false;
  };

  return (
    <Drawer
      variant="permanent"
      open={true}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: theme.palette.primary.main,
        },
      }}
      anchor="left"
    >
      <Toolbar
        sx={{
          backgroundColor: theme.palette.primary.main,
          padding: "20px",
        }}
      >
        <img src={NAV_ICONS.Moonlight_White} alt="Logo" />
      </Toolbar>
      <List
        component="nav"
        sx={{ color: theme.palette.common.white, padding: "10px", paddingTop: "40px" }}
      >
        {AdminNavigation.map((item, index) => (
          <React.Fragment key={index}>
            <ListItemButton onClick={() => handleNavigation(item)}>
              <ListItemIcon sx={{ minWidth: "15px", height: "15px", pr: "20px" }}>
                <img
                  src={isCurrentPageSelected(item) ? item.iconActive : item.icon}
                  alt={item.name}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    sx={
                      isCurrentPageSelected(item)
                        ? {
                            fontSize: "16px",
                            fontWeight: 500,
                            color: theme.palette.yellow.light,
                          }
                        : { fontSize: "16px", fontWeight: "400" }
                    }
                  >
                    {item.name}
                  </Typography>
                }
              />
            </ListItemButton>
            {index !== AdminNavigation.length - 1 && (
              <img src={IMAGES.lineIcon} style={{ width: "250px" }} />
            )}
          </React.Fragment>
        ))}
        <div style={{ marginTop: "60px" }}>
          <img src={IMAGES.lineIcon} style={{ width: "250px" }} />
          <ListItemButton onClick={() => handleLogout()}>
            <ListItemIcon sx={{ minWidth: "16px", height: "16px", pr: "15px;" }}>
              <img src={NAV_ICONS.logoutIcon} alt="Logout" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
                  Logout
                </Typography>
              }
            />
          </ListItemButton>
        </div>
      </List>
    </Drawer>
  );
};

export default Sidebar;
