import * as _ from "lodash";
import { sortNumbers } from "../helper-functions";
import { allMonthsList } from "../text-constants";
import { getDatesInRange, getYearsInRange } from "./dateTimeFormatFunction";

interface Month {
  id: number;
  month: string;
}

export const lineChartDataFunction = (
  params: any,
  data: any,
  dataAttributes?: string[],
  graphLabelAttribute?: string[],
  backgroundColors?: string[],
  borderColors?: string[],
  isColorFill?: boolean[],
  tooltipLabels?: string[]
) => {
  return {
    labels: getXAxisLabel(_.flatten([...Object.values(data)]), params),
    datasets: [
      ...Object.entries(data).map(([, value], i) => {
        return {
          label: (tooltipLabels && tooltipLabels[i]) || "Data Count",
          data: functionToGetData(
            value,
            params,
            _.flatten([...Object.values(data)]),
            Array.isArray(dataAttributes) ? dataAttributes[i] : undefined,
            Array.isArray(graphLabelAttribute) ? graphLabelAttribute[i] : undefined
          ),
          fill: (isColorFill && isColorFill[i]) || false,
          backgroundColor: (backgroundColors && backgroundColors[i]) || "",
          borderColor: (borderColors && borderColors[i]) || "",
          barThickness: 13,
          pointHoverRadius: 25,
        };
      }),
    ],
  };
};

const functionToGetData = (
  value: any,
  params: any,
  combinedObject: any,
  dataAttribute = "count",
  monthAttribute = "month"
) => {
  const dateMode = params?.dateMode;
  const arr: number[] = [];
  const getSortedDates = getDatesSorted(combinedObject, params);
  if (dateMode === "date") {
    getSortedDates.forEach((eachDay: any) => {
      const data = value.find(
        (val: any) => val.date === eachDay.date && val.month === eachDay.month
      );
      if (data) {
        arr.push(data["count"]);
      } else {
        arr.push(0);
      }
    });
  } else if (dateMode === "year") {
    getSortedDates.forEach((eachYear: any) => {
      const data = value.find((val: any) => val.year === eachYear.year);
      if (data) {
        arr.push(data["count"]);
      } else {
        arr.push(0);
      }
    });
  } else {
    Array.isArray(value) &&
      allMonthsList.forEach((eachMonth: any) => {
        const data = value.find((val) => val[monthAttribute] === eachMonth.id);
        if (data) {
          arr.push(data[dataAttribute]);
        } else {
          arr.push(0);
        }
      });
  }
  return arr;
};

function getXAxisLabel(value: any, params: any) {
  const dateMode = params?.dateMode;
  const uniqueSortedArray = getDatesSorted(value, params);
  if (dateMode === "date") {
    const xAxisLabelArray: any[] = [];
    allMonthsList.forEach((eachMonth: any) => {
      Array.isArray(uniqueSortedArray) &&
        uniqueSortedArray?.find((val: any) => {
          if (val.month === eachMonth.id) {
            xAxisLabelArray.push(`${val.date || ""} ${eachMonth.month}`);
          }
        });
    });
    return xAxisLabelArray;
  } else if (dateMode === "month") {
    return allMonthsList.map((monthData: Month) => monthData.month);
  } else {
    return uniqueSortedArray.map((eachYear: any) => eachYear.year);
  }
}

//percentage of class completed and upcoming classes

export const percentClassData = (data: any, color: string[]) => {
  return {
    labels: ["Percent", "percent"],
    datasets: [
      {
        label: "Percent",
        data: [100 - data, data],
        backgroundColor: color,
      },
    ],
  };
};

/**get date mode for graph filter (days, month, year) */

export const getDateMode = (startDate: any, endDate: any) => {
  if (startDate && endDate) {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const difference = date2.getTime() - date1.getTime();
    const days = Math.ceil(difference / (1000 * 3600 * 24));
    if (days <= 31) {
      return "date";
    } else if (days <= 364) {
      return "month";
    } else {
      return "year";
    }
  } else {
    return "month";
  }
};

function getDatesSorted(arrayObject: any, params: any) {
  const dbDataObject: any[] = arrayObject;

  const dateMode = params?.dateMode;
  let getSortedObjectArrays: any[] = [];
  if (dateMode === "date") {
    if (params?.startDate && params?.endDate) {
      const datesRange = getDatesInRange(
        new Date(params.startDate),
        new Date(params.endDate)
      );
      if (datesRange?.length) {
        datesRange.forEach((dateObject: any) => {
          const isPresent = arrayObject.some(
            (filterDate: any) =>
              parseInt(filterDate.date) === parseInt(dateObject.date) &&
              filterDate.month === dateObject.month
          );
          if (!isPresent) {
            dbDataObject.push({
              date: dateObject.date,
              month: dateObject.month,
              count: 0,
            });
          }
        });
      }
    }
    const gropuByMonth = _.groupBy(dbDataObject, "month");
    getSortedObjectArrays = Object.values(gropuByMonth).map((value) => {
      const sortedValues = sortNumbers(value, "date", "date");
      return _.uniqBy(sortedValues, "date");
    });
    return _.flatten(getSortedObjectArrays);
  } else if (dateMode === "year") {
    const filteredYears = getYearsInRange(
      new Date(params.startDate),
      new Date(params.endDate)
    );
    filteredYears.forEach((year: any) => {
      const isYearPresent = dbDataObject.some(
        (fetchedYear: any) => parseInt(fetchedYear.year) === parseInt(year)
      );
      if (!isYearPresent) {
        dbDataObject.push({ year, count: 0 });
      }
    });
    const sortedObjectValue = sortNumbers(dbDataObject, "year", "year");
    return _.uniqBy(sortedObjectValue, "year");
  } else {
    return dbDataObject;
  }
}
