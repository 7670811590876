import * as yup from "yup";
import { errorConst } from "../../text-constants";

export const validateTemplateDetails = () =>
  yup.object({
    templateSubject: yup
      .string()
      .required(errorConst.TEMPLATE_SUBJECT_REQUIRED)
      .min(1, errorConst.TEMPLATE_SUBJECT_MIN_CHAR),
    templateBody: yup
      .string()
      .required(errorConst.BODY_PLAIN_TEXT_REQUIRED)
      .min(1, errorConst.BODY_PLAIN_TEXT_MIN_CHAR)
  });
