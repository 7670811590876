import { getDateFormat } from "./dateTimeFormatFunction";

export const getRequiredClassData = (classData: any) => {
  return classData.map((eachClass: any) => {
    return {
      ["Class ID"]: eachClass.classId,
      ["Guide ID"]: eachClass.guideId,
      ["Guide Email"]: eachClass.guideEmail,
      ["Guide First Name"]: eachClass.guideFirstName,
      ["Guide Last Name"]: eachClass.guideLastName,
      ["Guide Phone Number"]: eachClass.guidePhone ?? "NA",
      ["Is Class Active"]: getBooleanValue(eachClass.isActive),
      ["Is it a repeated class (True / False)"]: getBooleanValue(
        eachClass.repeatedClass?.isRepeted
      ),
      ["Class repeated from (Class ID of parent class - If repeated)"]:
        eachClass.repeatedClass?.repeatedFrom ?? "NA",
      ["Class Title"]: eachClass.classTitle,
      ["Description"]: eachClass.classDesc,
      ["Main Category Name"]: eachClass.categoryName,
      ["Main Category ID"]: eachClass.categoryId,
      ["Taggings / Sub- Categories"]: eachClass.taggings
        ?.map((tag: any) => `#${tag.name}`)
        ?.join(", "),
      ["Guide Experience"]: eachClass.guideExperience,
      ["Class Difficulty Level"]: eachClass.classDifficultyLevel,
      ["Language"]: eachClass.languageKnown,
      ["Class Location - Address"]: eachClass.classLocation?.locationString,
      ["Class Location - City"]: eachClass.classLocation?.cityName,
      ["Class Location - Country"]: eachClass.classLocation?.countryId,
      ["Latitude"]: getLatLong(eachClass, "lat"),
      ["Longitude"]: getLatLong(eachClass, "long"),
      ["Schedule Start (DD/MM/YYY, HH:MM:SS (A/P)M)"]: getDateFormat(
        eachClass.schedule.startTimestamp
      ),
      ["Schedule End (DD/MM/YYY, HH:MM:SS (A/P)M)"]: getDateFormat(
        eachClass.schedule.endTimestamp
      ),
      ["Price - Price Currency"]: eachClass.price?.priceCurrency,
      ["Price - Per Class Per Person (Guide Price)"]: `${eachClass.price?.total}`,
      ["Price - Service Charge"]: `${eachClass.price?.serviceCharge}`,
      ["Price - Tax"]: `${eachClass.price?.tax}`,
      ["Price - Total"]: `${eachClass.price?.total}`,
      ["Minimum explorers"]: eachClass.minExplorers,
      ["Maximum Explorers"]: eachClass.maxExplorers,
      ["Additional Comments"]: eachClass.additionalReqComments,
      ["No of coupons used"]: eachClass.total_coupons,
      ["Total Bookings"]: eachClass.total_bookings,
      ["Total Cancellations"]: eachClass.total_cancellations,
      ["Class created date"]: getDateFormat(eachClass.createdOn),
      ["Class Updated date"]: getDateFormat(eachClass.updatedOn),
    };
  });
};

function getBooleanValue(data: any) {
  return data ? "Yes" : "No";
}

function getLatLong(eachClass: any, field: string) {
  if (eachClass.classLocation?.loc?.coordinates) {
    if (field === "lat") {
      return eachClass.classLocation?.loc?.coordinates[1];
    } else {
      return eachClass.classLocation?.loc?.coordinates[0];
    }
  } else {
    return "NA";
  }
}
