/**
 * Admin settings listing page functions
 */
import { Tooltip } from "@mui/material";
import * as React from "react";
import { NavigateFunction } from "react-router-dom";

import { IMAGES } from "../../assets/images";
import { fetchAdminsListController } from "../../controllers/adminSettingsController";
import { errorConst } from "../text-constants";

const actionContainer = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
};

const iconsContainer = {
  width: "44px",
  height: "44px",
  cursor: "pointer",
  margin: "3px",
};

export const adminsListingColumnHeaders = (
  setOpenDeletePopup: any,
  navigate: NavigateFunction
) => {
  return [
    { field: "id", headerName: "No.", flex: 1 },
    { field: "fullName", headerName: "Full Name", flex: 1 },
    {
      field: "role",
      headerName: "Role Type",
      flex: 1,
      renderCell: (params: any) => {
        return (params.row.role?.includes("Admin" || "admin") && "Admin") || "";
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      sortable: false,
      disableClickEventBubbling: true,
      width: 120,
      renderCell: (params: any) => {
        return (
          <div style={actionContainer}>
            <Tooltip title="Edit Admin">
              <img
                onClick={() => handleAdminEditClick(params.row, navigate)}
                style={iconsContainer}
                src={IMAGES.editFilledGreenIcon}
              />
            </Tooltip>
            {!params?.row?.isFirstAdmin && (
              <Tooltip title="Delete Admin">
                <img
                  onClick={() => handleAdminDeleteClick(params, setOpenDeletePopup)}
                  style={iconsContainer}
                  src={IMAGES.deleteFilledOrangeIcon}
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];
};

function handleAdminEditClick(params: any, navigate: NavigateFunction) {
  navigate(`/admin/edit-admin`, { state: { id: params.user_id } });
}

function handleAdminDeleteClick(params: any, setOpenDeletePopup: any) {
  setOpenDeletePopup({
    id: params.row.user_id,
    adminName: `${params.row.first_name} ${params.row.last_name}`,
    isClicked: true,
    isDeleting: false,
  });
}

/**
 * function to fetch admins list - listing - page
 */

type state = {
  isLoading: boolean;
  data: any[];
  total: number;
  page: number;
  pageSize: number;
};

export const getAdminsListData = (
  setState: {
    (value: React.SetStateAction<state>): void;
    (arg0: (prev: any) => any): void;
  },
  state: state,
  setMsg: any
) => {
  setState((prev: any) => ({
    ...prev,
    isLoading: true,
  }));
  fetchAdminsListController(
    (response: any) => successAdminsFetch(response, state, setState),
    (error: any) => errorFetch(error, setMsg, setState),
    {
      skip: state.page + 1,
      limit: state.pageSize,
    }
  );
};

function successAdminsFetch(
  response: any,
  state: state,
  setState: {
    (value: React.SetStateAction<state>): void;
    (arg0: (prev: any) => any): void;
    (arg0: (prev: any) => any): void;
  }
) {
  const data = response.data.map((item: any, i: number) => {
    const id = i + 1 + state.page * state.pageSize;
    const fullName = `${item.first_name} ${item.last_name}`;
    return { ...item, id, fullName };
  });
  setState((prev: any) => ({
    ...prev,
    isLoading: false,
    data,
    total: response.count,
  }));
}

function errorFetch(
  error: any,
  setMsg: any,
  setState: {
    (value: React.SetStateAction<state>): void;
    (arg0: (prev: any) => any): void;
    (arg0: (prev: any) => any): void;
  }
) {
  setMsg({
    show: true,
    type: "error",
    msg: getErrorMsg(error),
    errorType: "default",
  });

  setState((prev: any) => ({
    ...prev,
    isLoading: false,
  }));
}

function getErrorMsg(error: any) {
  return error?.data?.message ? error?.data?.message : errorConst.UNKNOW_ERROR;
}
