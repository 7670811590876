//This file includes some functionalities related to edit class
import * as _ from "lodash";
import { NavigateFunction } from "react-router-dom";

export function getIsErrorValue(
  errors: any,
  i: any,
  value: any,
  tags?: any,
  fieldType?: any,
  fieldName?: string
) {
  if (fieldName === "aboutClass") {
    if (value && value.toString().trim().length >= 20) {
      return "";
    } else {
      return errors[i]?.value || "";
    }
  } else if (value) {
    return false;
  }

  for (const key in errors) {
    if (fieldType === "tagsForClass" && tags?.length === 0) {
      return true;
    } else if (
      key?.toString() === i?.toString() &&
      errors[key].value &&
      fieldType !== "tagsForClass"
    ) {
      return true;
    }
  }
}

export function handleButtonClick(data: any, navigate: NavigateFunction) {
  if (data.id === "cancel") {
    navigate(-1);
  }
}

export function handleCropperButtonClick(
  data: any,
  setOpenPopup: any,
  setImage: any,
  setMsg: any,
  imageType?: string,
  images?: any
) {
  if (
    data.id === "crop-image" &&
    (imageType === "otherImages" || imageType === "locationImages")
  ) {
    handleMultiImageAdd(data.data, imageType, images, setImage);
  } else if (data.id === "crop-image") {
    handleCoverImageChange(data.data, setImage, setMsg);
  }
  setOpenPopup({
    isOpen: false,
    imageType: "",
  });
}

/**Remove tag on delete click */
export function handleRemoveImage(imgData: any, imgType: string, setImages: any) {
  setImages((prev: any) => ({
    ...prev,
    [imgType]: prev[imgType]
      ?.filter((img: any) => img.url !== imgData.url)
      .map((eachImg: any, imgIndex: number) => ({
        ...eachImg,
        priority: imgIndex + 1,
      })),
  }));
}

/**set class input data */
export function setClassDataFunction(
  setClassData: any,
  values: any,
  i: any,
  inputValue: any,
  valueAttribute: any = "value"
) {
  setClassData((pre: any) => ({
    ...pre,
    data: [
      ...pre.data.map((val: any, index: number) => {
        if (index === i) {
          values[i][valueAttribute] =
            valueAttribute === "tags"
              ? [...values[i].tags, ..._.flatten(inputValue)]
              : inputValue;
          return {
            ...values[i],
          };
        } else {
          return val;
        }
      }),
    ],
  }));
}

/**handle remove tags */
export function removeTags(tag: any, setClassData: any, values: any, i: any) {
  const tagsList = values[i].tags.filter((eachTag: any) => tag.name !== eachTag.name);
  setClassData((prev: any) => ({
    ...prev,
    data: [
      ...prev.data.map((val: any, ind: any) => {
        if (ind === i) {
          values[i]["tags"] = [...tagsList];
          return {
            ...values[i],
          };
        } else {
          return val;
        }
      }),
    ],
  }));
}

/**handle location input change */
export function setLocationDataFunction(
  setLocationData: any,
  classData: any,
  locIndex: any,
  values: any,
  newValue: any
) {
  setLocationData((pre: any) => ({
    ...pre,
    data: [
      ...pre.data.map((val: any, index1: number) => {
        const index = index1 + classData.data.length;
        if (index === locIndex) {
          values[locIndex].value = newValue;
          return {
            ...values[locIndex],
          };
        } else {
          return val;
        }
      }),
    ],
  }));
}

/**verify tags */
export function verifyTagsInput(value: any, existingTags: any[] = []) {
  return value.split(",").map((tag: any) => {
    const tagsArray: any[] = [];
    if (tag.toString().trim()) {
      const isTagExist = existingTags?.some(
        (eachTag: any) => eachTag.name?.toString()?.trim() === tag
      );
      !isTagExist && tagsArray.push({ name: tag, prefix: "#" });
    }
    return tagsArray;
  });
}

export function handleCoverImageChange(imageData: any, setImages: any, setMsg: any) {
  if (!imageData) {
    setImages((previousState: any) => ({
      ...previousState,
      coverImage: {
        url: "",
        binaryFile: "",
      },
    }));
  } else {
    setImages((previousState: any) => ({
      ...previousState,
      coverImage: {
        url: imageData.url,
        binaryFile: imageData.blobFile,
      },
    }));
  }
}

export function handleMultiImageAdd(
  imageData: any,
  imageType: any,
  images: any,
  setImages: any
) {
  images[imageType].push({
    url: imageData.url,
    binaryFile: imageData.blobFile,
    priority: imageType === "" ? images[imageType].length : images[imageType].length + 1,
  });
  setImages((p: any) => ({ ...p, [imageType]: images[imageType] }));
}

export function getErrorText(errors: any, i: number, value: any, fieldName: string) {
  if (fieldName === "aboutClass") {
    return errors[i]?.value;
  } else if (value) {
    return "";
  }
  for (const key in errors) {
    if (key.toString() === i.toString() && (errors[key]?.value || errors[key]?.tags)) {
      return errors[key].value || errors[key].tags;
    }
  }
}

export function getIsError(errors: any, i: number) {
  for (const key in errors) {
    if (key.toString() === i.toString() && (errors[key]?.value || errors[key]?.tags)) {
      return true;
    }
  }
}
