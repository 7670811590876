import { Stack, Typography } from "@mui/material";
import * as React from "react";
import { ImageComponent } from "..";
import style from "./promoBanner.module.scss";

function PromoBannerComponent(props: any) {
  const { handleDrag, handleDrop, data } = props;
  return (
    <div
      style={{ margin: "10px 10px 0 0" }}
      draggable={true}
      onDragOver={(ev) => ev.preventDefault()}
      onDragStart={handleDrag}
      onDrop={handleDrop}
      className={style.promoCardContainer}
    >
      <ImageComponent className={style.promoBannerImage} image={data.imageUrl} />
      <Stack sx={{ marginLeft: "10px" }} spacing={0.5}>
        <Typography className={`${style.fontStyle} ${style.classNameStyle}`}>
          {data.text1}
        </Typography>
        <Typography className={style.fontStyle}>{data.text2}</Typography>
        <Typography className={style.fontStyle}>{data.text3}</Typography>
        <Typography className={style.fontStyle}>{data.text4}</Typography>
      </Stack>
    </div>
  );
}

export { PromoBannerComponent };
