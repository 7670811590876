import { Stack } from "@mui/material";
import * as React from "react";
import { SimpleTag } from "..";

function SimpleTagList(props: any) {
  const { tagsList, tagAttribute, prefixAttribute, isDeleteIconPresent, onDeleteClick } =
    props;
  return (
    <Stack direction="row" gap={2} sx={{ display: "flex", flexWrap: "wrap" }}>
      {Array.isArray(tagsList) &&
        tagsList.map((tag: any, i: number) => {
          return (
            <React.Fragment key={i}>
              <SimpleTag
                text={tag[tagAttribute || "name"]}
                prefixText={tag[prefixAttribute || "prefix"]}
                isDeleteIconPresent={isDeleteIconPresent}
                onDeleteClick={() => onDeleteClick(tag)}
                title={tag.title || ""}
              />
            </React.Fragment>
          );
        })}
    </Stack>
  );
}

export { SimpleTagList };
