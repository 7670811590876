import { PATH_PARAMS } from "../constants";
import { SuccessResponse } from "../types/parameters.type";
import { ApiClient, ErrorHandler, SuccessHandler } from "../services/data.service";

export const changePassword = async (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  postParameter: any
) => {
  ApiClient.makePostRequest(
    PATH_PARAMS.CHANGE_PASSWORD,
    successHandler,
    errorHandler,
    postParameter
  );
};

export const createNewAdminController = async (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  postParameter: any
) => {
  ApiClient.makePostRequest(
    PATH_PARAMS.CREATE_NEW_ADMIN,
    successHandler,
    errorHandler,
    postParameter
  );
};

export const fetchAdminsListController = async (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params: any
) => {
  ApiClient.makeGetRequest(
    PATH_PARAMS.FETCH_ADMINS_LIST,
    successHandler,
    errorHandler,
    params
  );
};

export const editAdminsDetailsController = async (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  postParameters: any,
  userId: any
) => {
  ApiClient.makePostRequest(
    `${PATH_PARAMS.EDIT_ADMIN_DETAILS}${userId}`,
    successHandler,
    errorHandler,
    postParameters
  );
};

export const deleteAdminController = async (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  userId: any
) => {
  ApiClient.makeDeleteRequest(
    `${PATH_PARAMS.DELETE_ADMIN}${userId}`,
    successHandler,
    errorHandler
  );
};

export const fetchSingleAdminController = async (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  userId: any
) => {
  ApiClient.makeGetRequest(
    `${PATH_PARAMS.FETCH_SINGLE_ADMIN}${userId}`,
    successHandler,
    errorHandler
  );
};

export const verifyEmailIdAndGetData = async (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  paramsData: any
) => {
  ApiClient.makePostRequest(
    `${PATH_PARAMS.FETCH_EXISTING_USER_DATA}`,
    successHandler,
    errorHandler,
    paramsData
  );
};
