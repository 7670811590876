import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";

/**
 * checkbox selection options
 * checkboxSelection={true}
 * disable select all options from header part:
 * "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
      display: "none"
    }
 */

const DataGridComponent = (props: any): JSX.Element => {
  const {
    rows = [],
    autoHeight = true,
    rowCount = 0,
    loading = false,
    rowsPerPageOptions = [10, 20, 50, 100],
    pagination = true,
    page,
    pageSize,
    paginationMode = "server",
    onPageChange,
    onPageSizeChange,
    onRowClick,
    columns,
    checkboxSelection = false,
    selectAllCheckbox = false,
    hideFooterSelectedRowCount = true,
    rowHeight = 55,
    disableColumnMenu = true,
    disableSelectionOnClick = true,
    hideFooter = false,
    onCellClick,
    selectionModel,
    isRowSelectable,
  } = props;

  return (
    <DataGrid
      headerHeight={60}
      sx={{
        width: "100%",
        height: "300px",
        minHeight: `${pageSize > 10 && "800px"}`,
        cursor: "pointer",
        borderRadius: "8px 8px 0px 0px",
        ".MuiDataGrid-columnSeparator": {
          display: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "#2D2178",
          color: "rgba(255, 255, 255, 1)",
          fontSize: "16px",
          fontWeight: 500,
          borderRadius: "8px 8px 0px 0px",
        },
        "& .MuiDataGrid-row": {
          color: "rgba(0, 0, 0, 1)",
          fontWeight: 400,
          borderWidth: "0px",
          fontSize: "14px",
          border: "none",
          ":hover": {
            bgcolor: "transparent",
            boxShadow: "0 0 15px 5px #6B5B87",
          },
        },
        ".MuiDataGrid-cell": {
          border: "none",
        },
        "& .MuiDataGrid-row:nth-of-type(even)": {
          backgroundColor: "#F0F7F8",
        },
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
          outline: "none",
        },
        ...(!selectAllCheckbox && {
          "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
            display: "none",
          },
        }),
      }}
      rowHeight={rowHeight}
      checkboxSelection={checkboxSelection}
      autoHeight={pageSize <= 10 && autoHeight}
      rows={rows}
      rowCount={rowCount}
      loading={loading}
      rowsPerPageOptions={rowsPerPageOptions}
      pagination={pagination}
      page={page}
      pageSize={pageSize}
      paginationMode={paginationMode}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      onRowClick={onRowClick}
      columns={columns}
      hideFooterSelectedRowCount={hideFooterSelectedRowCount}
      disableSelectionOnClick={disableSelectionOnClick}
      disableColumnMenu={disableColumnMenu}
      hideFooter={hideFooter}
      onCellClick={onCellClick}
      selectionModel={selectionModel}
      isRowSelectable={isRowSelectable}
    />
  );
};

export { DataGridComponent };
