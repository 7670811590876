import { Dispatch } from "react";
import { fetchSingleExplorerDetailsController } from "../../../../controllers/explorersController";
import { viewGuideOrExplorerData } from "./viewPageData";
import { editGuideOrExplorerData } from "../edit-guideOrExplorer/editGuideOrExplorerPageData";

export function getExplorerData(
  explorerId: string | number,
  setViewData: Dispatch<any>,
  isEditPage?: any
) {
  setViewData((prev: any) => ({
    ...prev,
    isLoading: true,
  }));

  fetchSingleExplorerDetailsController(
    (response: any) => successExplorerFetch(response, setViewData, isEditPage),
    (error: any) => errorExplorerFetch(error, setViewData),
    explorerId
  );
}

function successExplorerFetch(
  response: any,
  setViewData: Dispatch<any>,
  isEditPage?: any
) {
  let modifiedResponse: any = null;
  if (isEditPage) {
    modifiedResponse = response && editGuideOrExplorerData(response, "explorer");
  } else {
    modifiedResponse = response && viewGuideOrExplorerData(response, "explorer");
  }
  setViewData((previousVal: any) => ({
    ...previousVal,
    data: modifiedResponse,
    ...(isEditPage && { copyData: response }),
    ...(isEditPage
      ? { explorerImage: { binaryFile: "", url: response?.explorerProfilePic } }
      : { explorerImage: response?.explorerProfilePic }),
    explorerName: `${response?.firstName} ${response?.lastName}`,
    isLoading: false,
    selectedCountry: response.countryId,
    cityId: response.cityId,
  }));
}

function errorExplorerFetch(error: any, setViewData: Dispatch<any>) {
  setViewData((previousVal: any) => ({
    ...previousVal,
    data: null,
    explorerImage: null,
    explorerName: null,
    isLoading: false,
  }));
}
