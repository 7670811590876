import * as React from "react";
import { useNavigate } from "react-router-dom";

import * as components from "../../../components";
import { IMAGES } from "../../../assets/images";
import { columnHeaders } from "../../../utils/reusable-functions/guidesOrExplorers/listing/listingTableHeaders";
import { getGuidesOrExplorerList } from "../../../utils/reusable-functions/guidesOrExplorers/listing/apiFunctions";
import useMessage from "../../../hooks/customHooks/useMessage";
import { getSelectedFilters } from "../../../utils/reusable-functions/filterFunctions";
import { preCheckDownloadReport } from "../../../utils/reusable-functions/exportDataFunction";
import { ListingPageComponent } from "../../../compoundComponents";
import { guideListConst, guideReview } from "../../../utils/text-constants/guidesConstant";
import { errorConst } from "../../../utils/text-constants";
import { ButtonsListComponent, SimpleDialogue } from "../../../components";
import { twoButtonList } from "src/utils/reusable-functions/buttonFunction";
import { updateGuideController } from "src/controllers/guidesController";
import { showToast } from "src/utils/helper-functions/helperFunction";
import { Tooltip } from "@mui/material";

const GuideListingPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { setMsg } = useMessage();

  const [pageState, setPageState] = React.useState({
    firstUserCreatedOn: "",
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 20,
  });

  const [searchText, setSearchText] = React.useState<string>("");
  const [selectedFilters, setSelectedFilters] = React.useState();
  const [isGuideReportDownloading, setIsGuideReportDownloading] =
    React.useState<boolean>(false);
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [guideReviewStatus, setGuideReviewStatus] = React.useState({
    id: "",
    userId: "",
    userName: "",
    status: 'Pending',
    isClicked: false
  })
  const [reloadData, setReloadData] =  React.useState<boolean>(false);
  const [isSubmit,setIsSubmit] = React.useState<boolean>(false);

  function changeStatusData(status: string) {
    setGuideReviewStatus({...guideReviewStatus,status:status} )
  }

  function clearGuideReviewStatus() {
    setGuideReviewStatus({
      id: "",
      userId: "",
      userName: "",
      status: 'Pending',
      isClicked: false
    })
    setIsSubmit(false)
  }

  function showReviewProfile() {

    return (
      <SimpleDialogue
        openPopup={true}
        dialogInlineStyle={{ minWidth: "40%", minHeight: "max-content", padding: 10 }}
      >
        <div style={{ padding: '10px' }}>
          <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between' }} >
            <h2 style={{ fontSize: 18 }}>{guideReview.reviewProfile}{guideReviewStatus.userName}</h2>
            <Tooltip title="Close">
              <img
                src={IMAGES.closeIcon}
                onClick={clearGuideReviewStatus}
                style={{ width: '30px' }}
              />
            </Tooltip>
          </div>
          <components.SingleSelectInputField
            name={'reviewStatus'}
            label=''
            listData={[{ label: "Approve", value: "Approve" }, { label: "Reject", value: "Reject" }]}
            onChange={(e: any) => {
              changeStatusData(e.target?.value)
            }}
            value={guideReviewStatus.status}
          />
          <ButtonsListComponent
            isLoading={false}
            buttonsIndexForLoader={[0]}
            buttonsArray={twoButtonList(`Submit`, "Cancel")}
            onButtonClick={(data: any) => {
              if (data.label === 'Cancel') {
                clearGuideReviewStatus()
              } else if (guideReviewStatus.status === '') {
                alert(guideReview.selectFromDropdownAlert)
              } else if (data.label === 'Submit' && guideReviewStatus.status !== '') {
                setIsSubmit(true)
              }
            }}
          />
        </div>
        {submitConfirmationModal()}
      </SimpleDialogue>
    )
  }

  function submitConfirmationModal() {
    const status = guideReviewStatus.status === 'Reject' ? 'reject' : 'approve'
    return (
      <SimpleDialogue
        openPopup={isSubmit}
        dialogInlineStyle={{ width: '450px', minHeight: "max-content", padding: 20 }}
      >
        <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between' }} >
          <h2 style={{ fontSize: 18 }}>Are you sure you want to {status}?</h2>
          <Tooltip title="Close">
            <img
              src={IMAGES.closeIcon}
              onClick={() => setIsSubmit(false)}
              style={{ width: '30px' }}
            />
          </Tooltip>
        </div>
        <ButtonsListComponent
          isLoading={isUpdating || false}
          buttonsIndexForLoader={[0]}
          buttonsArray={twoButtonList("Ok", "Cancel")}
          onButtonClick={ (data: any) => {
            if (data.label === 'Cancel') {
              setIsSubmit(false)
            } else if (data.label === 'Ok' && guideReviewStatus.status !== '') {
              // make api call
              const finalPayload = guideReviewStatus.status === 'Approve' ? { becomeGuideStatus: 'Approved' } : { becomeGuideStatus: 'Rejected' }
              const profileStatus = guideReviewStatus.status === 'Approve' ? 'Approved' : 'Rejected';
              setIsUpdating(true);
              updateGuideController(
                () => {
                  showToast(
                    setMsg,
                    `Guide profile ${profileStatus} successfully!`,
                    "success"
                  );
                  setIsUpdating(false);
                },
                (error: any) => {
                  showToast(
                    setMsg,
                    error.message || error.data.message || errorConst.UNKNOW_ERROR,
                    "error"
                  );
                  setIsUpdating(false);
                },
                guideReviewStatus.userId,
                finalPayload
              );
              clearGuideReviewStatus();
              setTimeout(() => {setReloadData(!reloadData)}, 500)
            }
          }}
        />
      </SimpleDialogue>
    )
  }

  function handleDownloadReport() {
    preCheckDownloadReport(
      selectedFilters,
      searchText,
      2,
      setIsGuideReportDownloading,
      {}
    );
  }

  function handleNavigateToDetails(guideId: string | number) {
    navigate("/admin/view-guide", { state: { guideId } });
  }

  React.useEffect(() => {
    getGuidesOrExplorerList(
      setPageState,
      pageState,
      setMsg,
      {
        module: "guide",
        ...(searchText && { search: searchText }),
        ...getSelectedFilters(selectedFilters),
      },
      "guide"
    );
  }, [pageState.page, pageState.pageSize, selectedFilters, searchText, reloadData]);
  return (
    <>
    <ListingPageComponent
      button1={{
        isLoading: isGuideReportDownloading,
        label: guideListConst.DOWNLOAD_REPORT,
        endIcon: IMAGES.downloadReportIcon,
        handleClick: handleDownloadReport,
      }}
      searchField={{
        placeholder: guideListConst.SEARCH_GUIDE,
        onSearchClick: (searchText1: string) => setSearchText(searchText1),
      }}
      filterPopupData={{
        selectedFilters,
        setSelectedFilters,
        initialDates: pageState.firstUserCreatedOn
          ? [
              new Date(pageState.firstUserCreatedOn) ||
                new Date(new Date().getFullYear(), 0, 1),
              new Date(),
            ]
          : "",
      }}
      pageState={pageState}
      setPageState={setPageState}
      columnHeaders={columnHeaders(handleNavigateToDetails,setGuideReviewStatus, true)}
    />
    {guideReviewStatus.isClicked && showReviewProfile()}
    </>
  );
};

export default GuideListingPage;
