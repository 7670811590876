/**class category const */

export const classCategoryList = [
  {
    id: "CTR_1",
    value: "Arts",
  },
  {
    id: "CTR_2",
    value: "Crafts",
  },
  {
    id: "CTR_3",
    value: "Culinary",
  },
  {
    id: "CTR_4",
    value: "Design & Tech",
  },
  {
    id: "CTR_5",
    value: "Wellness",
  },
  {
    id: "CTR_6",
    value: "Wine & Beverages",
  },
];
