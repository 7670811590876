import * as yup from "yup";
import { errorConst } from "../../text-constants";

export const validateCouponsCreate = () =>
  yup.object({
    title: yup
      .string()
      .trim()
      .required(errorConst.TITLE_REQUIRED)
      .min(1, errorConst.TITLE_REQUIRED),
    discount: yup
      .number()
      .typeError(errorConst.FIELD_SHOULD_BE_NUMBER)
      .required(errorConst.DISCOUNT_REQUIRED)
      .min(0.1, errorConst.MIN_1)
      .max(100, errorConst.MAX_100),
    startDateTime: yup
      .date()
      // .min(new Date(), errorConst.MIN_START_DATE_ONLY)
      .typeError(errorConst.ENTER_VALID_DATE_ONLY)
      .required(errorConst.START_DATE_ONLY_REQUIRED),
    endDateTime: yup
      .date()
      .typeError(errorConst.ENTER_VALID_DATE_ONLY)
      .min(yup.ref("startDateTime"), errorConst.END_DATE_GREATERTHAN_START_DATE_ONLY)
      .required(errorConst.END_DATE_ONLY_REQUIRED),
    minBillAmount: yup
      .number()
      .typeError(errorConst.FIELD_SHOULD_BE_NUMBER)
      .required(errorConst.BILL_AMOUNT_FIELD_MANADATORY),
    maxDiscountAmount: yup
      .number()
      .typeError(errorConst.FIELD_SHOULD_BE_NUMBER)
      .nullable(true),
    countryId: yup.string().required(),
    description: yup
      .string()
      .trim()
      .required(errorConst.DESCRIPTION_REQUIRED)
      .min(1, errorConst.DESCRIPTION_REQUIRED),
    termsAndCondtionsList: yup
      .array()
      .required(errorConst.TERMS_CONDITION_REQUIRED)
      .min(1, errorConst.TERMS_CONDITION_REQUIRED)
      .max(10),
  });

export const validateEditReferralCoupon = () =>
  yup.object({
    title: yup
      .string()
      .trim()
      .required(errorConst.TITLE_REQUIRED)
      .min(1, errorConst.TITLE_REQUIRED),
    discount: yup
      .number()
      .typeError(errorConst.FIELD_SHOULD_BE_NUMBER)
      .required(errorConst.DISCOUNT_REQUIRED)
      .min(0.1, errorConst.MIN_1)
      .max(100, errorConst.MAX_100),
    durationInMonths: yup
      .number()
      .integer(errorConst.FIELD_NUMBER)
      .typeError(errorConst.FIELD_SHOULD_BE_NUMBER)
      .required(errorConst.DURATION_FIELD_REQUIRED),
    minBillAmount: yup
      .number()
      .typeError(errorConst.FIELD_SHOULD_BE_NUMBER)
      .required(errorConst.BILL_AMOUNT_FIELD_MANADATORY),
    maxDiscountAmount: yup
      .number()
      .typeError(errorConst.FIELD_SHOULD_BE_NUMBER)
      .nullable(true),
    countryId: yup.string().required(),
    description: yup
      .string()
      .trim()
      .required(errorConst.DESCRIPTION_REQUIRED)
      .min(1, errorConst.DESCRIPTION_REQUIRED),
    termsAndCondtionsList: yup
      .array()
      .required(errorConst.TERMS_CONDITION_REQUIRED)
      .min(1, errorConst.TERMS_CONDITION_REQUIRED),
  });
