import * as React from "react";
import PromotedClassComponent from "../../../modules/promoted-class/PromotedClassComponent";

const PromotedClassPage: React.FC = () => {
  return (
    <>
      <PromotedClassComponent />
    </>
  );
};

export default PromotedClassPage;
