import * as _ from "lodash";

export function iSThereErrorInEditField(errors: any, i: number, value: any) {
  for (const key in errors) {
    if (
      key.toString() === i.toString() &&
      (value?.toString()?.trim()?.length === 0 || !value?.toString()?.trim())
    ) {
      return true;
    }
  }
}

export function getFieldsErrorText(errors: any, i: number, value: any) {
  for (const key in errors) {
    if (
      key.toString() === i.toString() &&
      (value?.toString()?.trim()?.length === 0 || !value?.toString()?.trim())
    ) {
      return errors[key].data;
    }
  }
}

export function getOnlyChangedValues(objectValue: any, editData: any, userType: string) {
  const formData = new FormData();
  const copyData = editData.copyData;
  const cityId = editData.cityId;

  getChangedValueOnUserType(objectValue, editData, userType, formData);

  cityId && cityId !== copyData.cityId && formData.append("cityId", cityId);
  objectValue.countryId !== copyData.countryId &&
    formData.append("countryId", objectValue.countryId);
  objectValue.cityName !== copyData.cityName &&
    formData.append("cityName", objectValue.cityName);

  if (!_.isEqual(copyData.languagesKnown, objectValue.languagesKnown)) {
    objectValue.languagesKnown.includes("Other")
      ? objectValue.otherLanguages !== copyData.languagesKnown[0] &&
        formData.append(`languagesKnown[0]`, objectValue.otherLanguages)
      : _.without(objectValue.languagesKnown, "Other").forEach(
          (language: any, langIndex: number) =>
            formData.append(`languagesKnown[${langIndex}]`, language)
        );
  }

  const pronounFieldOnUserType = userType === "guide" ? "guidePronoun" : "pronoun";
  const pronounDataOnUserType =
    userType === "guide" ? copyData.guidePronoun : copyData.pronoun;

  if (objectValue.pronounOther) {
    if (objectValue.pronounOther !== pronounDataOnUserType) {
      formData.append(pronounFieldOnUserType, objectValue.pronounOther);
    }
  } else if (objectValue.pronoun !== pronounDataOnUserType) {
    formData.append(pronounFieldOnUserType, objectValue.pronoun);
  }
  return formData;
}

function getChangedValueOnUserType(
  objectValue: any,
  editData: any,
  userType: string,
  formData: any
) {
  if (userType === "guide") {
    if (editData?.guideImage?.binaryFile) {
      formData.append("guideProfilePic", editData.guideImage.binaryFile);
    }
    objectValue.guideBio !== editData.copyData?.guideBio &&
      formData.append("guideBio", objectValue.guideBio);
  } else {
    if (editData?.explorerImage?.binaryFile) {
      formData.append("explorerProfilePic", editData.explorerImage.binaryFile);
    }
    objectValue.explorerBio !== editData.copyData?.explorerBio &&
      formData.append("explorerBio", objectValue.explorerBio);
  }
}

/**
 * handle guide edit page input function
 */

export function handleGuideExplorerDataChange(
  e: any,
  i: any,
  values: any,
  setEditData: any,
  name?: string | number
) {
  const getIndexOfOtherLang = getFieldIndex(values, "otherLanguages");
  setEditData((prev: any) => ({
    ...prev,
    cityId: getCityId(e, prev),
    selectedCountry:
      e?.target?.name === "countryId" ? e.target.value : prev.selectedCountry,
    data: [
      ...prev.data.map((val: any, inn: any) => {
        if (e?.target?.name === "countryId") {
          const cityIndex = getFieldIndex(values, "cityName");
          if (cityIndex > -1) {
            values[cityIndex]["data"] = "";
          }
        }
        if (i === inn) {
          return getValIfFoundFieldIndex(values, i, e, name, inn, getIndexOfOtherLang);
        } else {
          return val;
        }
      }),
    ],
  }));
}

function getValIfFoundFieldIndex(
  values: any,
  i: number,
  e: any,
  name: string | number,
  inn: number,
  getIndexOfOtherLang: number
) {
  if (values[i]["name"] === "languagesKnown") {
    checkForLanguageFieldChanges(e, values, i, getIndexOfOtherLang);
  } else if (values[i]["name"] === "otherLanguages") {
    values[i].data = e.target.value;
    values[i].isDataPresent = e.target.value ? true : false;
  } else {
    if (name && name === "city") {
      values[inn]["data"] = e?.Name;
    } else {
      if (values[inn].name === "pronoun") {
        checkForPronounFieldChange(values, e, inn);
      }
      values[inn]["data"] = e?.target?.value;
    }
  }
  return {
    ...values[i],
  };
}

function checkForPronounFieldChange(values: any, e: any, inn: number) {
  const indexOfOtherPronoun = getFieldIndex(values, "pronounOther");
  if (indexOfOtherPronoun > -1) {
    values[indexOfOtherPronoun].data = "";
    values[indexOfOtherPronoun].isFieldVisible = false;
  }
  if (e?.target?.value === "Other") {
    values[inn].gridBreakpoints = { lg: 6, md: 6 };
    values[indexOfOtherPronoun].isFieldVisible = true;
  } else {
    values[inn].gridBreakpoints = { lg: 12, md: 12 };
  }
}

function checkForLanguageFieldChanges(
  e: any,
  values: any,
  i: number,
  getIndexOfOtherLang: number
) {
  const {
    target: { value },
  } = e;
  if (values[i].data.includes("Other") && value.includes("Other")) {
    value.splice(value.indexOf("Other"), 1);
    values[i].data = value;
  } else if (!values[i].data.includes("Other") && value.includes("Other")) {
    values[i].data = ["Other"];
  } else {
    values[i].data = value;
  }

  if (values[i].data.includes("Other")) {
    values[getIndexOfOtherLang].isDataPresent = values[getIndexOfOtherLang].data
      ? true
      : false;
    values[getIndexOfOtherLang].isFieldVisible = true;
  } else {
    values[getIndexOfOtherLang].data = "";
    values[getIndexOfOtherLang].isDataPresent = true;
    values[getIndexOfOtherLang].isFieldVisible = false;
  }
}

export function checkIfOtherLangRequired(values: any, i: number) {
  const isOtherValIncluded = values[i].data.includes("Other");
  if (values[i].data?.length > 2 || values[i].data?.length === 0) {
    return false;
  } else if (isOtherValIncluded && values[i].data?.length === 1) {
    return true;
  } else {
    return false;
  }
}

export function getFieldIndex(values: any, fieldName: string) {
  return values.findIndex((eachObject: any) => eachObject.name === fieldName);
}

export function handleGuideProfileChange(imageData: any, setState: any) {
  setState((previous: any) => ({
    ...previous,
    guideImage: {
      binaryFile: imageData?.blobFile,
      url: imageData?.url,
    },
  }));
}

function getCityId(e: any, prev: any) {
  if (e?.Id) {
    return e.Id;
  } else if (e?.target?.name === "countryId") {
    return "";
  } else {
    return prev.cityId;
  }
}
