import { FormHelperText, MenuItem, Select, Stack, Typography } from "@mui/material";
import * as React from "react";
import { labelType } from "../../utils/helper-functions";

const SingleSelectInputField = (props: any): JSX.Element => {
  const {
    id,
    label,
    name,
    onChange,
    value = 10,
    disabled = false,
    listData = [],
    stackStyle = { mb: "15px" },
    error = false,
    errorText = "",
  } = props;

  const ITEM_HEIGHT = 70;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const menuStyle = {
    fontSize: "15px",
    "&:focus": {
      background: "rgba(178, 223, 100, 0.5)",
    },
  };

  const labelStyle = {
    fontWeight: 400,
    fontSize: "15px",
    color: disabled ? "#A29191" : "#353535",
  };

  return (
    <Stack spacing={1} sx={stackStyle}>
      <Typography sx={labelStyle}>{label}</Typography>
      <Select
        id={id}
        error={error}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        displayEmpty
        MenuProps={MenuProps}
        sx={{ fontSize: "15px", width: "100%", backgroundColor: disabled && "#E7E1E1" }}
      >
        {Array.isArray(listData) &&
          listData.map(
            (
              data: {
                value: string | number | readonly string[];
                label: labelType;
              },
              index: number
            ) => {
              return (
                <MenuItem key={index} sx={menuStyle} value={data.value}>
                  {data.label}
                </MenuItem>
              );
            }
          )}
      </Select>
      {error && (
        <FormHelperText
          style={{
            fontStyle: "italic",
            fontWeight: 400,
            fontSize: "15px",
            color: "#F73030",
            margin: "4px 0 0",
            lineHeight: "22.5px",
          }}
        >
          {errorText}
        </FormHelperText>
      )}
    </Stack>
  );
};

export { SingleSelectInputField };
