import { Grid, Stack } from "@mui/material";
import * as React from "react";

import { IMAGES } from "../assets/images";
import * as components from "../components";

const ListingPageComponent = (props: any): JSX.Element => {
  const {
    button1,
    searchField,
    filterButton,
    columnHeaders = [],
    pageState,
    setPageState,
    filterPopupData,
  } = props;

  const [searchText, setSearchText] = React.useState("");
  const [openFilter, setOpenFilter] = React.useState<boolean>(false);

  const handleSearchChange = (event: any) => {
    setSearchText(event.target.value);
    if (event.target.value === "") {
      searchField?.onSearchClick("");
    }
  };

  const handleClearSearch = () => {
    setSearchText("");
    searchField?.onSearchClick("");
  };

  const onApplyOrCancelButtonClick = (data: any, filterData: any) => {
    if (data.id === "apply") {
      filterPopupData?.setSelectedFilters({ ...filterData });
    }
    setOpenFilter(false);
  };

  return (
    <>
      <Grid container p={1.5} spacing={2}>
        <Grid item md={12} lg={12} justifyContent="space-between" display={"flex"}>
          <components.LoadingButtonComponent
            isLoading={button1?.isLoading}
            buttonLabel={button1?.label}
            handleClick={button1?.handleClick}
            endIcon={
              button1?.endIcon && <components.ImageComponent image={button1?.endIcon} />
            }
          />
          <Stack direction="row" spacing={2}>
            <components.SearchInputField
              value={searchText}
              placeholder={searchField?.placeholder}
              onChange={handleSearchChange}
              onSearchClick={() => searchField?.onSearchClick(searchText)}
              onClearClick={handleClearSearch}
            />
            <components.LoadingButtonComponent
              isLoading={filterButton?.isLoading}
              buttonLabel={filterButton?.label || "Filter"}
              handleClick={() => setOpenFilter((prev: any) => !prev)}
              endIcon={
                <components.ImageComponent
                  image={filterButton?.endIcon || IMAGES.filterIcon}
                />
              }
            />
          </Stack>
        </Grid>
        <Grid item lg={12} md={12}>
          <components.DataGridComponent
            columns={columnHeaders}
            rows={pageState?.data}
            rowCount={pageState?.total}
            loading={pageState?.isLoading}
            page={pageState?.page}
            pageSize={pageState?.pageSize}
            onPageChange={(page: any) => {
              setPageState({ ...pageState, page });
            }}
            onPageSizeChange={(pageSize: any) => {
              setPageState({ ...pageState, pageSize });
            }}
          />
        </Grid>
      </Grid>
      {openFilter && (
        <components.SideDrawer
          openState={openFilter}
          onCloseDrawer={() => setOpenFilter(!openFilter)}
        >
          <components.FilterComponent
            initialDates={filterPopupData?.initialDates || ""}
            filters={filterPopupData?.selectedFilters}
            setFilters={filterPopupData?.setSelectedFilters}
            onButtonClick={(data: any, filterData: any) =>
              onApplyOrCancelButtonClick(data, filterData)
            }
          />
        </components.SideDrawer>
      )}
    </>
  );
};

export { ListingPageComponent };
