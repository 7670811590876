import { PATH_PARAMS } from "../constants";
import { SuccessResponse } from "../types/parameters.type";
import { ApiClient, ErrorHandler, SuccessHandler } from "../services/data.service";

export const fetchCouponsController = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params?: any
) => {
  ApiClient.makeGetRequest(
    `${PATH_PARAMS.GET_COUPONS_LIST}`,
    successHandler,
    errorHandler,
    params
  );
};

export const createCouponController = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  postParams?: any
) => {
  ApiClient.makePostRequest(
    PATH_PARAMS.CREATE_GENERIC_COUPON,
    successHandler,
    errorHandler,
    postParams
  );
};

export const fetchEachCouponDetailsController = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  id?: any
) => {
  ApiClient.makeGetRequest(
    `${PATH_PARAMS.GET_EACH_COUPON_DETAILS}${id}`,
    successHandler,
    errorHandler
  );
};

export const updateCouponController = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  couponId?: string | number,
  params?: any
) => {
  ApiClient.makePutRequest(
    `${PATH_PARAMS.UPDATE_COUPON}${couponId}`,
    successHandler,
    errorHandler,
    params
  );
};
