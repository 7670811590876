import { Divider, Grid, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import {
  changeColor,
  getCurrencyType,
  getTimezones,
  onKeyDown,
} from "../../../utils/helper-functions";
import { twoButtonList } from "../../../utils/reusable-functions/buttonFunction";
import { handleSubmitCreateCoupon } from "../../../utils/reusable-functions/coupons/createCoupons";
import { validateCouponsCreate } from "../../../utils/reusable-functions/data-validation/couponsValidatoin";
import { countriesList, couponsConst } from "../../../utils/text-constants";
import * as components from "../../../components";
import useMessage from "../../../hooks/customHooks/useMessage";
import style from "./coupons.module.scss";
import {
  handleDropFunction2,
  handleOnDragFunction,
} from "../../../utils/reusable-functions/dragDropFunction";
import { IMAGES } from "../../../assets/images";

export default function CreateCouponPage() {
  const navigate = useNavigate();
  const { setMsg } = useMessage();
  const [isCreating, setIsCreating] = React.useState(false);
  const [dragBoxId, setDragBoxId] = React.useState(null);

  const initialValues: any = {
    title: "",
    discount: null,
    countryId: "US",
    startDateTime: "",
    endDateTime: "",
    minBillAmount: null,
    maxDiscountAmount: null,
    description: "",
    termsAndCondition: "",
    termsAndCondtionsList: [],
    enableEmail: false,
    isOneOffCoupon: true,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validateCouponsCreate}
      onSubmit={(values) =>
        handleSubmitCreateCoupon(values, navigate, setMsg, setIsCreating)
      }
      validateOnChange
    >
      {({ errors, touched, handleChange, handleBlur, values, setFieldValue }: any) => {
        return (
          <Form onKeyDown={onKeyDown}>
            <Grid container spacing={2} p={2.5} pl={3}>
              <Grid item sm={12} md={12} lg={12}>
                <components.TextInputField
                  name={"title"}
                  label={couponsConst.TITLE}
                  placeholder={"Enter Coupon Title"}
                  errorText={errors.title}
                  onChange={handleChange}
                  error={errors.title && touched.title}
                  onBlur={handleBlur}
                  maxInputLenth={30}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <components.TextInputField
                  name={"discount"}
                  label={couponsConst.DISCOUNT}
                  placeholder={"Enter Discount"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorText={errors.discount}
                  error={errors.discount && touched.discount}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <components.SingleSelectInputField
                  name="countryId"
                  label={couponsConst.COUNTRY}
                  listData={countriesList}
                  onChange={handleChange}
                  value={values.countryId}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <components.SingleDatePicker
                  name="startDateTime"
                  label={`${couponsConst.START_DATE_TIME} ${getTimezones(
                    values.countryId
                  )}`}
                  value={values.startDateTime}
                  onChange={(value: any) => setFieldValue("startDateTime", value)}
                  error={errors.startDateTime && touched.startDateTime}
                  errorText={errors.startDateTime}
                  minDate={new Date().setDate(new Date().getDate())}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <components.SingleDatePicker
                  name="endDateTime"
                  label={`${couponsConst.END_DATE_TIME} ${getTimezones(
                    values.countryId
                  )}`}
                  value={values.endDateTime}
                  onChange={(value: any) => setFieldValue("endDateTime", value)}
                  error={errors.endDateTime && touched.endDateTime}
                  errorText={errors.endDateTime}
                  minDate={
                    values.startDateTime || new Date().setDate(new Date().getDate() + 1)
                  }
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <components.TextInputField
                  name={"minBillAmount"}
                  label={couponsConst.MIN_BILL_AMT}
                  placeholder={"Enter Amount"}
                  value={values.minBillAmount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxInputLenth={20}
                  errorText={errors.minBillAmount}
                  error={errors.minBillAmount && touched.minBillAmount}
                  prefixText={
                    <p className={style.currencyStyle}>
                      {getCurrencyType(values.countryId)}
                    </p>
                  }
                />
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <components.TextInputField
                  name={"maxDiscountAmount"}
                  label={couponsConst.MAX_DISCOUNT}
                  placeholder={"Enter Amount"}
                  value={values.maxDiscountAmount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxInputLenth={20}
                  errorText={errors.maxDiscountAmount}
                  error={errors.maxDiscountAmount && touched.maxDiscountAmount}
                  prefixText={
                    <p className={style.currencyStyle}>
                      {getCurrencyType(values.countryId)}
                    </p>
                  }
                />
              </Grid>
              <Grid item sm={12} md={12} lg={12}>
                <components.TextInputField
                  name={"description"}
                  placeholder={"Enter Description"}
                  label={couponsConst.DESCRIPTION}
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxInputLenth={50}
                  errorText={errors.description}
                  error={errors.description && touched.description}
                  multiline
                  rows={2}
                />
              </Grid>
              <Grid
                item
                sm={12}
                md={12}
                lg={12}
                display="flex"
                flexDirection={"row"}
                alignItems={"center"}
                gap={2}
                mb={2}
              >
                <Typography sx={{ fontSize: "15px" }}>
                  {couponsConst.SEND_EMAIL_TO_USERS}
                </Typography>
                <components.ToggleSwitchComponent
                  checked={values.enableEmail}
                  onChange={(event: any) => {
                    setFieldValue("enableEmail", event.target.checked);
                  }}
                />
                <Typography sx={{ fontSize: "15px" }}>
                  {couponsConst.IS_ONE_OFF_COUPON}
                </Typography>
                <components.ToggleSwitchComponent
                  checked={values.isOneOffCoupon}
                  onChange={(event: any) => {
                    setFieldValue("isOneOffCoupon", event.target.checked);
                  }}
                />
              </Grid>
              <Grid item sm={12} md={12} lg={12}>
                <components.TextInputField
                  name={"termsAndCondition"}
                  label={couponsConst.TERMS_CONDITIONS}
                  placeholder={"Enter Terms & Conditions"}
                  value={values.termsAndCondition}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorText={errors.termsAndCondtionsList}
                  disabled={values.termsAndCondtionsList.length >= 10 ? true : false}
                  error={errors.termsAndCondtionsList && touched.termsAndCondition}
                  endIcon={values.termsAndCondition?.toString().trim() && IMAGES.addIcon}
                  onIconClick={() => {
                    const val = [
                      ...values.termsAndCondtionsList,
                      values.termsAndCondition,
                    ];
                    setFieldValue("termsAndCondition", "");
                    setFieldValue("termsAndCondtionsList", val);
                  }}
                />
                {Array.isArray(values.termsAndCondtionsList) &&
                  values.termsAndCondtionsList.map((terms: any, index: number) => (
                    <div
                      draggable={true}
                      onDragStart={() => {
                        changeColor(`draggable-${index}`, "#F0F7F8");
                        handleOnDragFunction(index, setDragBoxId);
                      }}
                      onDrop={() => {
                        changeColor(`draggable-${dragBoxId}`, "#FFFFFF");
                        const responseAfterDragDrop = handleDropFunction2(
                          index,
                          values.termsAndCondtionsList,
                          dragBoxId
                        );
                        setFieldValue("termsAndCondtionsList", responseAfterDragDrop);
                      }}
                      onDragOver={(ev) => ev.preventDefault()}
                      onMouseOut={() => changeColor(`draggable-${dragBoxId}`, "#FFFFFF")}
                    >
                      <components.TextDeleteComponent
                        index={index}
                        text={terms}
                        prefixIcon={IMAGES.blueRoundIcon}
                        boxStyle={style.textDeleteBoxStyle}
                        handleDeleteClick={() => {
                          values.termsAndCondtionsList.splice(index, 1);
                          setFieldValue(
                            "termsAndCondtionsList",
                            values.termsAndCondtionsList
                          );
                        }}
                      />
                    </div>
                  ))}
              </Grid>
              <Grid item sm={12} md={12} lg={12}>
                <Divider sx={{ mt: 2 }} />
                <components.ButtonsListComponent
                  isLoading={isCreating}
                  buttonsIndexForLoader={[0]}
                  buttonsArray={twoButtonList("Create")}
                  onButtonClick={(data: any) => {
                    if (data.id === "cancel") {
                      navigate("/admin/coupons-listing", {
                        state: { tabIndex: 1 },
                        replace: true,
                      });
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}
