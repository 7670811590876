import { Box, Stack, Typography } from "@mui/material";
import * as React from "react";
import { DoughnutChartComponent } from "./baseGraphs/DoughnutChartComponent";
import { IMAGES } from "../../assets/images/index";
import theme from "../../themes/theme";

function DoughnutChartAndText(props: any) {
  const { chartData, percentageData } = props;
  return (
    <Stack direction={"row"} sx={{ alignItems: "center", padding: "10px" }} spacing={3}>
      <Box
        sx={{
          width: "110px",
          height: "110px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={IMAGES.upArrowIcon} style={{ position: "relative", left: "65%" }} />
        <DoughnutChartComponent chartData={chartData} />
      </Box>
      <Stack p={1}>
        <Typography
          variant="h4"
          sx={{ fontWeight: 600, fontSize: "24px", color: "#40AFA8" }}
        >
          {percentageData.percent}
        </Typography>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "14px",
            marginTop: "10px",
            color: theme.palette.secondary.contrastText,
          }}
        >
          {percentageData.text}
        </Typography>
      </Stack>
    </Stack>
  );
}

export { DoughnutChartAndText };
