import * as React from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import logo from "../../assets/images/Moonlight_Black.svg";
import { resetPassword, verifyToken } from "../../controllers/authController";
import { LoadingButtonAndTextLink, TextInputField } from "../../components";
import ShowPasswordIcon from "../../assets/images/eye-icon.svg";
import HidePasswordIcon from "../../assets/images/eye-icon-hide.svg";
import useMessage from "../../hooks/customHooks/useMessage";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { setMsg } = useMessage();

  const urlParameters = window.location.search.split("?q=")[1].split("/");

  const token = urlParameters[0];
  const userEmail = urlParameters[1];

  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [showErrorMessage, setShowErrorMessage] = React.useState("");
  const [isButtonLoading, setIsButtonLoading] = React.useState<boolean>(false);

  const validationSchema = Yup.object({
    newPassword: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password must contain atleast 8 characters, one uppercase, one number and one special case character"
      ),
    confirmPassword: Yup.string()
      .required("Password is required")
      .oneOf([Yup.ref("newPassword"), null], "Password do not match"),
  });

  const verifyResetToken = () => {
    const verifyTokenParameters = { email: userEmail, token: token };
    const onVerifySuccess = async (response: any) => {
      console.log(response);
    };
    const onVerifyError = (error: any) => {
      navigate("/link-expired");
    };
    verifyToken(onVerifySuccess, onVerifyError, verifyTokenParameters);
  };

  const onSuccess = async (response: any) => {
    setIsButtonLoading(false);
    setMsg({
      show: true,
      type: "success",
      msg: "Password changed successfully",
      errorType: "default",
    });
    navigate("/login");
  };
  const onError = (error: any) => {
    setIsButtonLoading(false);
    setShowErrorMessage(error.data.errors.userMessage);
  };

  const submitPassword = (values: any) => {
    setIsButtonLoading(true);
    const postParameter = {
      email: userEmail,
      newPassword: window.btoa(values.newPassword),
    };
    resetPassword(onSuccess, onError, postParameter);
  };

  React.useEffect(() => {
    verifyResetToken();
  });

  return (
    <Box
      overflow="auto"
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        width="100%"
        flexDirection={"row"}
      >
        <div>
          <img src={logo} alt="Logo" />
          <Typography variant="h3" color="darkGrey.main" mt={8} mb={4}>
            Reset Password
          </Typography>
          <Formik
            initialValues={{
              newPassword: "",
              confirmPassword: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => submitPassword(values)}
            validateOnChange
          >
            {({ errors, touched, handleChange }) => (
              <Form style={{ width: "400px" }}>
                {showErrorMessage && (
                  <Typography variant="h6" color="error.main" sx={{ my: "12px" }}>
                    {showErrorMessage}
                  </Typography>
                )}
                <TextInputField
                  label="New Password"
                  id="newPassword"
                  type={showNewPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  name="newPassword"
                  error={touched.newPassword && Boolean(errors.newPassword)}
                  errorText={errors.newPassword}
                  onChange={handleChange}
                  endIcon={showNewPassword ? ShowPasswordIcon : HidePasswordIcon}
                  onIconClick={() => setShowNewPassword(!showNewPassword)}
                />
                <Typography
                  variant="body2"
                  color="brown.light"
                  sx={{ fontStyle: "italic", mb: "18px" }}
                >
                  Password must contain at least 8 characters, one uppercase, one number
                  and one special case character
                </Typography>
                <TextInputField
                  label="Confirm Password"
                  id="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  name="confirmPassword"
                  error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                  errorText={errors.confirmPassword}
                  onChange={handleChange}
                  endIcon={showConfirmPassword ? ShowPasswordIcon : HidePasswordIcon}
                  onIconClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
                <LoadingButtonAndTextLink
                  isLoading={isButtonLoading}
                  text={"Back to login"}
                  buttonArray={[
                    { type: "submit", label: "Submit", inlineStyle: { width: "100%" } },
                  ]}
                  onTextClick={() => navigate("/")}
                />
              </Form>
            )}
          </Formik>
        </div>
      </Box>
    </Box>
  );
};

export default ResetPassword;
