export const broadCastConst = {
  NEW_BROADCAST_MESSAGE: "New Broadcast Message",
  SEARCH_MESSAGE: "Search Message",
};

export const createBroadCastConst = {
  MESSAGE_TITLE_48_CHAR: "Message Title (Max 48 Characters)",
  MESSAGE_BODY_90_CHAR: "Message Body (Max 90 Characters)",
  CLASS_CATEGORY: "Class Category",
  TARGETED_LOCATION: "Targeted Location",
  COUNTRY: "Country",
  TARGETED_CITY: "Targeted State/City",
  SCHEDULE_SEND: "Schedule Send",
  ENTER_MESSAGE_TITLE: "Enter Message title",
  ENTER_MESSAGE: "Enter your message",
  SELECT_CLASS_CATEGORY: "Select Class Category (Optional)",
  SELECT_ALL_CLASS_CATEGORIES: "Select All Class Category",
  SEND_SCHEDULE: "Schedule Send",
  CITY_SELECT_DIALOG_SUB_TEXT: "you can select upto 30 cities",
};
