import { NavigateFunction } from "react-router-dom";
import { updateExplorerController } from "../../../../controllers/explorersController";
import { alertConst, errorConst } from "../../../../utils/text-constants";
import { checkFormHasFile, convertArrayToObject } from "../../../helper-functions";
import { showToast } from "../../../helper-functions/helperFunction";
import { getOnlyChangedValues } from "./editGuideOrExplorerFunctions";

//handle edit button click function

export const handleSubmitEditExplorerDetails = (
  values: any,
  editData: any,
  explorerId: number | string,
  setMsg: any,
  navigate: NavigateFunction,
  setIsUpdating: any
) => {
  const objectValue = convertArrayToObject(values);
  const finalPayload: any = getOnlyChangedValues(objectValue, editData, "explorer");
  if (checkFormHasFile(finalPayload)) {
    setIsUpdating(true);
    updateExplorerController(
      () => {
        showToast(
          setMsg,
          `Explorer "${objectValue.firstName} ${
            objectValue.lastName || ""
          }" updated successfully!`,
          "success"
        );
        setTimeout(() => navigate(-1), 1500);
        setIsUpdating(false);
      },
      (error: any) => {
        showToast(
          setMsg,
          error.message || error.data.message || errorConst.UNKNOW_ERROR,
          "error"
        );
        setIsUpdating(false);
      },
      explorerId,
      finalPayload
    );
  } else {
    showToast(setMsg, alertConst.NO_CHANGES_FOUND_EXPLORER, "warning");
    setIsUpdating(false);
  }
};

// handle explorer profile change
export function handleExplorerProfileChange(imageData: any, setState: any) {
  setState((previous: any) => ({
    ...previous,
    explorerImage: {
      binaryFile: imageData?.blobFile,
      url: imageData?.url,
    },
  }));
}
