/**
 * All success message text constants can be added here
 */

export const successConst = {
  NEW_ADMIN_ADD_SCCESS: "New admin added successfully!",
  ADMIN_EDIT_SUCCESS: "Admin details updated successfully!",
  DELETE_SUCCESS: "deleted successfully!",
  UPDATE_SUCCESSFUL: "updated successfully!",
  PREV_EMAIL_SUCCESS: "Test email sent successfully!"
};
