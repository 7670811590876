import * as React from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import style from "./customdate.module.scss";
import "../../assets/scss/variables.scss";

function CustomDateRangePicker(props: any) {
  const {
    onDateSelect,
    selectedDate,
    showDateDisplay = true,
    fixedHeight = true,
  } = props;

  const selectionRange: any = {
    startDate: selectedDate[0] || new Date(new Date().getFullYear(), 0, 1),
    endDate: selectedDate[1] || new Date(new Date().getFullYear(), 11, 31),
    key: "selection",
    color: "#B2DF64",
    autoFocus: true,
  };

  return (
    <DateRangePicker
      fixedHeight={fixedHeight}
      className={style.contanierForDate}
      ranges={[selectionRange]}
      showDateDisplay={showDateDisplay}
      onChange={(newValue: any) => onDateSelect(newValue)}
      editableDateInputs={true}
    />
  );
}

export { CustomDateRangePicker };
