import { Box, Divider, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IMAGES } from "../../../assets/images";
import { twoButtonList } from "../../../utils/reusable-functions/buttonFunction";
import { editGuideValidationSchema } from "../../../utils/reusable-functions/data-validation/editGuideValidationSchema";
import {
  handleExplorerProfileChange,
  handleSubmitEditExplorerDetails,
} from "../../../utils/reusable-functions/guidesOrExplorers/edit-guideOrExplorer/editExplorerFunctions";
import { getExplorerData } from "../../../utils/reusable-functions/guidesOrExplorers/view-guideOrExplorer/viewExplorerApiData";
import { explorerDetailsConst } from "../../../utils/text-constants";
import * as components from "../../../components";
import style from "./explorers.module.scss";
import useMessage from "../../../hooks/customHooks/useMessage";
import { validateImage } from "../../../utils/reusable-functions/data-validation/imageFileValidation";
import {
  getIsFieldVisible,
  getOnlyMentionedLanguage,
  onEndIconClick,
} from "../../../utils/reusable-functions/guidesOrExplorers/edit-guideOrExplorer/editGuideOrExplorerFunctions2";
import {
  getFieldsErrorText,
  iSThereErrorInEditField,
  handleGuideExplorerDataChange,
} from "../../../utils/reusable-functions/guidesOrExplorers/edit-guideOrExplorer/editGuideOrExplorerFunctions";

export default function EditExplorerPage() {
  const navigate = useNavigate();
  const location: any = useLocation();
  const { setMsg } = useMessage();
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [editData, setEditData] = React.useState<any>({
    data: null,
    copyData: null,
    isLoading: false,
    explorerImage: null,
    explorerName: null,
    selectedCountry: null,
    cityId: null,
  });

  const [openPopup, setOpenPopup] = React.useState({
    citySelect: false,
    cityIndex: null,
    values: null,
  });

  const [openCropper, setOpenCropper] = React.useState<boolean>(false);
  const [selectedImage, setSelectedImage] = React.useState(null);

  React.useEffect(() => {
    if (location?.state?.explorerId) {
      getExplorerData(location.state.explorerId, setEditData, true);
    }
  }, []);

  function handleButtonClick(data: any) {
    if (data.id === "cancel") {
      navigate(-1);
    }
  }

  function handleFilePick(e: any) {
    validateImage(e, setMsg) && setOpenCropper(true);
    setSelectedImage(e);
  }

  function handleInputFieldClick(name: string, cityIndex: number | string, values: any) {
    if (name === "cityName") {
      setOpenPopup((prev: any) => ({
        citySelect: true,
        cityIndex,
        values,
      }));
    } else {
      return;
    }
  }

  function onKeyDown(e: any) {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      e.preventDefault();
    }
  }

  return (
    <>
      {!editData.isLoading && editData.data && (
        <Formik
          initialValues={editData.data}
          validationSchema={editGuideValidationSchema}
          onSubmit={(values: any) =>
            handleSubmitEditExplorerDetails(
              values,
              editData,
              location?.state?.explorerId,
              setMsg,
              navigate,
              setIsUpdating
            )
          }
          validateOnChange
        >
          {({ errors, handleBlur, values }: any) => {
            return (
              <Form onKeyDown={onKeyDown}>
                <Grid container spacing={3} p={2.5} pl={3}>
                  <Grid
                    item
                    sm={12}
                    md={12}
                    lg={12}
                    display="flex"
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <components.InputImageFile
                      src={editData.explorerImage?.url || IMAGES.emptyProfileImg}
                      handleImageChange={(e: any) => handleFilePick(e)}
                    />
                    <Typography className={style.explorerName}>
                      {editData.explorerName}
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12}>
                    <Divider />
                  </Grid>
                  {Array.isArray(editData.data) &&
                    editData.data?.map((eachField: any, index: number) => {
                      return (
                        <components.CheckForInputFields
                          eachField={eachField}
                          name={eachField.name}
                          label={eachField.label}
                          inputFieldType={eachField.inputFieldType}
                          isDisabled={eachField.isDisabled}
                          isMultilineInput={eachField.isMultilineInput}
                          listData={eachField.listData}
                          readOnly={eachField.readOnly}
                          isFieldVisible={getIsFieldVisible(values, index)}
                          value={
                            values[index].name === "languagesKnown"
                              ? getOnlyMentionedLanguage(values[index].data)
                              : values[index].data
                          }
                          onChange={(e: any) =>
                            handleGuideExplorerDataChange(e, index, values, setEditData)
                          }
                          handleBlur={handleBlur}
                          error={iSThereErrorInEditField(
                            errors,
                            index,
                            values[index].data
                          )}
                          errorText={getFieldsErrorText(
                            errors,
                            index,
                            values[index].data
                          )}
                          onInputFieldClick={() =>
                            handleInputFieldClick(values[index].name, index, values)
                          }
                          onIconClick={() => {
                            const indexOfLang = onEndIconClick(values, index);
                            handleGuideExplorerDataChange(
                              values[index].data,
                              indexOfLang,
                              values,
                              setEditData,
                              values[index].name
                            );
                          }}
                        />
                      );
                    })}
                  <Grid item sm={12} md={12} lg={12}>
                    <Divider />
                    <components.ButtonsListComponent
                      buttonsIndexForLoader={[0]}
                      isLoading={isUpdating}
                      buttonsArray={twoButtonList()}
                      onButtonClick={(data: any) => handleButtonClick(data)}
                    />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}
      {editData.isLoading && (
        <Box className={style.progressBarContainer}>
          <components.CircularProgressBar loadername={explorerDetailsConst.LOADER_TEXT} />
        </Box>
      )}
      {openPopup.citySelect && (
        <components.CitySelectDialog
          countryId={editData.selectedCountry || ""}
          selectedCityId={editData.cityId || ""}
          openPopup={openPopup.citySelect}
          handleCitySelect={(city: any) => {
            handleGuideExplorerDataChange(
              city,
              openPopup.cityIndex,
              openPopup.values,
              setEditData,
              "city"
            );
            setOpenPopup(() => ({
              citySelect: false,
              cityIndex: null,
              values: null,
            }));
          }}
          handleButtonClick={() =>
            setOpenPopup((previous: any) => ({
              ...previous,
              citySelect: false,
              cityIndex: null,
              values: null,
            }))
          }
        />
      )}
      {openCropper && (
        <components.ImageCropDialog
          openPopup={openCropper}
          aspectRatioValue={1}
          selectedFile={selectedImage}
          handleButtonClick={(data: any) => {
            if (data.id === "crop-image") {
              handleExplorerProfileChange(data.data, setEditData);
            }
            setOpenCropper(false);
          }}
        />
      )}
    </>
  );
}
