import * as React from "react";
import { SimpleDialogue } from "../dialogues/SimpleDialogue";
import { CancelClassPopupComponent } from "./CancelClassPopupComponent";

function CancelClassDialog(props: any) {
  const {
    openPopup,
    handleButtonClick,
    handleOtherReasonInput,
    cancelReason,
    handleSubmitClick,
    cancelClassLoader,
  } = props;
  return (
    <SimpleDialogue
      openPopup={openPopup}
      dialogInlineStyle={{ minWidth: "60%", minHeight: "max-content" }}
    >
      <CancelClassPopupComponent
        cancelClassLoader={cancelClassLoader}
        cancelReason={cancelReason}
        handleOtherReasonInput={handleOtherReasonInput}
        onClose={() => handleButtonClick({ id: "id-Discard" })}
        handleButtonClick={handleButtonClick}
        handleSubmitClick={handleSubmitClick}
      />
    </SimpleDialogue>
  );
}

export { CancelClassDialog };
