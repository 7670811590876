import * as React from "react";
import { Box, Divider, Stack, Tooltip, Typography } from "@mui/material";

import style from "./promoted-class.module.scss";
import { ButtonsListComponent, SimpleDialogue } from "../../components";
import { IMAGES } from "../../assets/images";
import { twoButtonsList } from "../../utils/reusable-functions/buttonFunction";

type IPromotedClassConfirmationComponent = {
  openPopup: boolean;
  promotedClassName: string;
  handleClose: () => void;
  handleConfirmClick: (data: any) => void;
};

const PromotedClassConfirmationComponent: React.FC<
  IPromotedClassConfirmationComponent
> = ({ openPopup, promotedClassName, handleClose, handleConfirmClick }) => {
  return (
    <>
      <SimpleDialogue openPopup={openPopup}>
        <Stack className={style.confirmationModelContainer} spacing={1}>
          <Stack className={style.header}>
            <Typography variant="h5" className={style.title}>
              Remove Confirmation
            </Typography>
            <Tooltip title="Close" placement="left">
              <img
                src={IMAGES.closeIcon}
                className={style.closeIcon}
                onClick={handleClose}
                alt="close"
              />
            </Tooltip>
          </Stack>
          <Divider />
          <p className={style.message}>
            Are you sure you want to remove the class <b>{promotedClassName}</b> from
            promotion?
          </p>
          <Box>
            <ButtonsListComponent
              buttonsArray={twoButtonsList("Confirm", "Cancel")}
              onButtonClick={(data: any) => handleConfirmClick(data)}
            />
          </Box>
        </Stack>
      </SimpleDialogue>
    </>
  );
};

export default PromotedClassConfirmationComponent;
