export const editClassLocationData = (locationData: any) => {
  return [
    {
      name: "country",
      label: "Country*",
      value: locationData.countryId || "",
      isDisabled: false,
      gridBreakpoints: { xs: 12, md: 6, lg: 6 },
      isSelectDropdownField: true,
    },
    {
      name: "city",
      label: "City*",
      value: locationData.cityName || "",
      isDisabled: false,
      gridBreakpoints: { xs: 12, md: 6, lg: 6 },
    },
    {
      name: "ApartmentNo",
      label: "Apartment number / Building name*",
      value: locationData.apartmentNumberOrBuildingName || "",
      isDisabled: false,
    },
    {
      name: "addressLine1",
      label: "Address Line 1*",
      value: locationData.addressLine1 || "",
      isDisabled: false,
      gridBreakpoints: { xs: 12, md: 6, lg: 6 },
    },
    {
      name: "zipCode",
      label: "Zip Code*",
      value: locationData.zipCode || "",
      isDisabled: false,
      gridBreakpoints: { xs: 12, md: 12, lg: 12 },
    },
    {
      name: "additionalLocationDetails",
      label: "Additional Location Details",
      value: locationData.directions || "",
      isDisabled: false,
      gridBreakpoints: { xs: 12, lg: 12, md: 12 },
      isMultilineInput: true,
    },
    {
      name: "makeLocationPublic",
      label: "Make location visible to all explorers",
      value:
        locationData.makeLocationPublic === "true" ||
        locationData.makeLocationPublic === true
          ? true
          : false,
      isDisabled: false,
      gridBreakpoints: { xs: 12, lg: 12, md: 12 },
      isCheckboxField: true,
    },
  ];
};
