import * as React from "react";
import { Doughnut } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
Chart.register(CategoryScale);

function DoughnutChartComponent(props: any) {
  const { chartData, options, totalCount = "" } = props;

  const chartOptions = {
    cutout: 37,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    ...options,
  };

  const plugins = [
    {
      id: "id",
      beforeDraw(chart: any) {
        const { width } = chart;
        const { height } = chart;
        const { ctx } = chart;
        ctx.restore();
        const fontSize = (height / 100).toFixed(2);
        ctx.font = `${fontSize}em Poppins`;
        ctx.textBaseline = "top";
        const text = totalCount;
        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  return <Doughnut data={chartData} options={chartOptions} plugins={plugins} />;
}

export { DoughnutChartComponent };
