import { Grid } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import * as components from "../../../components";
import { alertConst, broadCastConst, errorConst } from "../../../utils/text-constants";
import { IMAGES } from "../../../assets/images";
import {
  deleteBroadcastController,
  fetchBroadCastMessageController,
} from "../../../controllers/broadcastController";
import useMessage from "../../../hooks/customHooks/useMessage";
import { getDateFormat } from "../../../utils/reusable-functions/dateTimeFormatFunction";
import { showToast } from "../../../utils/helper-functions/helperFunction";
import { broadCastColumnNames } from "../../../utils/reusable-functions/broadcast-messages/listingTableHeaders";

export default function ListingBroadCastMessages() {
  const navigate = useNavigate();
  const { setMsg } = useMessage();

  const [pageState, setPageState] = React.useState({
    firstCreatedClassDate: "",
    isLoading: true,
    data: [],
    total: 0,
    page: 0,
    pageSize: 10,
  });

  const [searchText, setSearchText] = React.useState("");
  const [searchTextForApi, setSearchTextForApi] = React.useState("");
  const [openDeletePopup, setOpenDeletePopup] = React.useState({
    id: "",
    broadcastTitle: "",
    isClicked: false,
    isDeleting: false,
  });

  function viewBroadCastFunction(id: number | string) {
    navigate("/admin/view-broadcast-message-details", { state: { broadCastId: id } });
  }

  React.useEffect(() => {
    fetchBroadCast();
  }, [searchTextForApi, pageState.page, pageState.pageSize]);

  function fetchBroadCast() {
    fetchBroadCastMessageController(
      (response: any) => {
        const data = response?.Messages?.map((item: any, i: number) => {
          const id = i + 1 + pageState.page * pageState.pageSize;
          const scheduleDate = getDateFormat(item.schedule?.scheduleDate);
          return { ...item, id, scheduleDate };
        });

        setPageState((previous: any) => ({
          ...previous,
          isLoading: false,
          data,
          total: response.count,
        }));
      },
      (error: any) => {
        setPageState((prev: any) => ({
          ...prev,
          data: [],
          isLoading: false,
        }));
        showToast(
          setMsg,
          error.message || error.data?.message || errorConst.UNKNOW_ERROR,
          "error"
        );
      },
      {
        skip: pageState.page + 1,
        limit: pageState.pageSize,
        ...(searchTextForApi && { search: searchTextForApi }),
      }
    );
  }

  function deleteFunction(broadcast: any) {
    setState(broadcast.id, broadcast.messageTitle, true, false);
  }

  function handleDeletePopupButtonClick(button: any) {
    if (button.id === "id-Delete") {
      setOpenDeletePopup((prev: any) => ({
        ...prev,
        isDeleting: true,
      }));
      deleteBroadcastController(
        () => {
          showToast(
            setMsg,
            `Message "${openDeletePopup.broadcastTitle}" deleted successfully!`,
            "success"
          );
          fetchBroadCast();
          setState("", "", false, false);
        },
        (error: any) => {
          showToast(
            setMsg,
            error.message || error?.data?.message || errorConst.UNKNOW_ERROR,
            "error"
          );
          setState("", "", false, false);
          fetchBroadCast();
        },
        openDeletePopup.id
      );
    } else {
      setState("", "", false, false);
    }
  }

  function setState(
    id: any,
    broadcastTitle: string,
    isClicked: boolean,
    isDeleting: boolean
  ) {
    setOpenDeletePopup({
      id,
      broadcastTitle,
      isClicked,
      isDeleting,
    });
  }

  return (
    <Grid container p={1.5} pl={2.5} spacing={2.5}>
      <Grid item sm={12} md={12} lg={6} justifyContent="flex-start">
        <components.LoadingButtonComponent
          buttonLabel={broadCastConst.NEW_BROADCAST_MESSAGE}
          handleClick={() => navigate("/admin/new-broadcast-message")}
          endIcon={<img src={IMAGES.addIcon} />}
        />
      </Grid>
      <Grid item sm={12} md={12} lg={6} display={"flex"} justifyContent="flex-end">
        <components.SearchInputField
          value={searchText}
          placeholder={broadCastConst.SEARCH_MESSAGE}
          onChange={(e: any) => {
            !e.target.value && setSearchTextForApi("");
            setSearchText(e.target.value);
          }}
          onSearchClick={() => {
            setSearchTextForApi(searchText);
          }}
          onClearClick={() => {
            setSearchText("");
            setSearchTextForApi("");
          }}
        />
      </Grid>
      <Grid item sm={12} md={12} lg={12}>
        <components.DataGridComponent
          columns={broadCastColumnNames(viewBroadCastFunction, deleteFunction)}
          rows={pageState.data}
          rowCount={pageState.total}
          loading={pageState.isLoading}
          page={pageState.page}
          pageSize={pageState.pageSize}
          onPageChange={(page: any) => {
            setPageState({ ...pageState, page });
          }}
          onPageSizeChange={(pageSize: any) => {
            setPageState({ ...pageState, pageSize });
          }}
        />
      </Grid>
      {openDeletePopup.isClicked && (
        <components.DeleteAlertDialogue
          isLoading={openDeletePopup.isDeleting}
          openPopup={openDeletePopup.isClicked}
          onButtonClick={handleDeletePopupButtonClick}
          subHeading={`${alertConst.DELETE_BROADCAST_TITLE} "${openDeletePopup.broadcastTitle}" ? This action cannot be undone.`}
        />
      )}
    </Grid>
  );
}
