import { Box, CssBaseline, Toolbar } from "@mui/material";
import * as React from "react";
import { Outlet } from "react-router-dom";

import { NavBar as AppBarComponent } from "../components";
import { getTopAppbarText } from "../utils/reusable-functions/sideNavbarFunctions";
import Sidebar from "./sidebar";

const AdminLayout: React.FC = () => {
  const [selectedPage, setSelectedPage] = React.useState(getTopAppbarText());
  const drawerWidth = 270;
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Sidebar
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        drawerWidth={drawerWidth}
      />
      <AppBarComponent selectedPage={selectedPage} drawerWidth={drawerWidth} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          mt: 2.5,
          p: 2,
          pb: 5,
          width: `calc(100% - ${drawerWidth}px)`,
          bgcolor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.common.white
              : theme.palette.grey[900],
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default AdminLayout;
