import { PATH_PARAMS } from "../constants";
import { SuccessResponse } from "../types/parameters.type";
import { ApiClient, ErrorHandler, SuccessHandler } from "../services/data.service";

export const getClassList = async (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params: any
) => {
  ApiClient.makeGetRequest(
    PATH_PARAMS.FETCH_CLASSES,
    successHandler,
    errorHandler,
    params
  );
};

export const getPromotedClassList = async (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params: any
) => {
  ApiClient.makeGetRequest(
    PATH_PARAMS.FETCH_PROMOTED_CLASSES,
    successHandler,
    errorHandler,
    params
  );
};

export const addPromotedClass = async (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params: any
) => {
  ApiClient.makePostRequest(
    PATH_PARAMS.ADD_PROMOTED_CLASS,
    successHandler,
    errorHandler,
    params
  );
};

export const promotedClassSwapSequence = async (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params: any
) => {
  ApiClient.makePostRequest(
    PATH_PARAMS.PROMOTED_CLASS_SWAP_SEQUENCE,
    successHandler,
    errorHandler,
    params
  );
};
