import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { useMediaQuery } from "@mui/material";

function SideDrawer(props: any) {
  const { openState, children, drawerPosition = "right", onCloseDrawer } = props;
  const isWidthMatches = useMediaQuery("(min-width:760px)");

  /**
   * openState - boolean value
   * children - data to be displayed on drawer component
   * drawerPosition - default position 'right' else custom position
   */

  return (
    <Drawer
      anchor={drawerPosition}
      open={openState}
      style={{ zIndex: 2000, transitionDelay: "1s" }}
      PaperProps={{
        sx: { width: isWidthMatches ? "48%" : "100%" },
      }}
      onClose={onCloseDrawer}
    >
      {children}
    </Drawer>
  );
}

export { SideDrawer };
