import { Box, Button, Divider, Tooltip, Typography } from "@mui/material";
import * as React from "react";

import { SimpleDialogue } from "../SimpleDialogue";
import * as components from "../../../components";
import { IMAGES } from "../../../assets/images";
import style from "../dialog.module.scss";
import { getCities } from "../../../utils/text-constants/citiesList";

function CitySelectDialog(props: any) {
  const {
    openPopup,
    handleButtonClick,
    countryId = "",
    handleCitySelect,
    selectedCityId,
    isMultiSelect,
    maxCount,
    subHeading,
  } = props;

  return (
    <SimpleDialogue
      dialogInlineStyle={{ minWidth: "35%", minHeight: "95%" }}
      openPopup={openPopup}
    >
      <CitySelectContent
        handleButtonClick={handleButtonClick}
        countryId={countryId}
        handleCitySelect={handleCitySelect}
        selectedCityId={selectedCityId}
        isMultiSelect={isMultiSelect}
        maxCount={maxCount}
        subHeading={subHeading}
      />
    </SimpleDialogue>
  );
}

const CitySelectContent = (props: any): JSX.Element => {
  const {
    handleButtonClick,
    countryId,
    handleCitySelect,
    selectedCityId,
    isMultiSelect,
    maxCount,
    subHeading,
  } = props;

  const postsPerPage = 500;
  const [totalRecords, setTotalRecords] = React.useState(null);
  const [citiesList, setCitiesList] = React.useState([]);
  const [next, setNext] = React.useState(postsPerPage);
  const [searchText, setSearchText] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [selectedCities, setSelectedCities] = React.useState<any[]>(
    JSON.parse(JSON.stringify(selectedCityId)) || []
  );

  function loopWithSlice(start: any, end: any, searchT: any) {
    let arrayForHoldingPosts: any[] = [];
    const filteredNewRecords = filterAllRecords(searchT);
    const slicedPosts = filteredNewRecords.slice(start, end);
    arrayForHoldingPosts = [...slicedPosts];
    setCitiesList((prev: any) => [...prev, ...arrayForHoldingPosts]);
  }

  React.useEffect(() => {
    loopWithSlice(0, postsPerPage, "");
  }, []);

  const handleShowMoreCity = () => {
    loopWithSlice(next, next + postsPerPage, searchText);
    setNext(next + postsPerPage);
  };

  function handleSearchChange(e: any) {
    setSearchText(e.target.value);
    if (e.target.value === "") {
      loopWithSlice(0, postsPerPage, "");
    }
  }

  function handleClearSearch() {
    setCitiesList(() => []);
    setSearchText(() => "");
    loopWithSlice(0, postsPerPage, "");
  }

  function getFilteredVal() {
    return citiesList.filter(
      (city: any) => city.Name.toLowerCase().includes(search.toLowerCase()) && city
    );
  }

  function filterAllRecords(val: string) {
    const filtered = getCities(countryId).filter(
      (city: any) => city.Name.toLowerCase().includes(val.toLowerCase()) && city
    );
    setTotalRecords(filtered.length);
    return filtered;
  }

  function searchInputs() {
    setCitiesList(() => []);
    setSearch(searchText);
    loopWithSlice(0, postsPerPage, searchText);
  }

  function handleCityCheckbox(selectedCity: any) {
    const filteredValue = selectedCities.filter(
      (city: any) => city.Id !== selectedCity.Id
    );
    if (filteredValue?.length !== selectedCities?.length) {
      setSelectedCities(() => [...filteredValue]);
    } else {
      selectedCities.push(selectedCity);
      setSelectedCities(() => [...selectedCities]);
    }
  }

  function highlightSelectedCity(filteredCity: any) {
    if (isMultiSelect) {
      const isPresent = selectedCities.some(
        (city: any) => city.Id?.toString() === filteredCity.Id.toString()
      );
      if (isPresent) {
        return style.selectedCityName;
      } else {
        return style.cityNameStyle;
      }
    } else {
      return filteredCity.Id.toString() === selectedCityId.toString()
        ? style.selectedCityName
        : style.cityNameStyle;
    }
  }

  return (
    <>
      <div className={style.cityHeaderImgContainer}>
        <Box display={"flex"} justifyContent={"space-between"} alignItems="center">
          <div className={style.headingStyle}>
            <Typography className={style.selectCityHeader}>{`Select city`}</Typography>
            <Typography className={style.selectCitySubHeading}>
              {subHeading || ""}
            </Typography>
          </div>
          <Tooltip title="Close">
            <img
              src={IMAGES.closeIcon}
              className={style.closeIcon}
              onClick={() => handleButtonClick({ id: "id-close" })}
            />
          </Tooltip>
        </Box>
        <components.SearchInputField
          style={{ width: "100%", marginTop: "25px" }}
          value={searchText}
          onChange={handleSearchChange}
          onClearClick={handleClearSearch}
          onSearchClick={() => searchInputs()}
        />
      </div>
      <Box p={4} pb={10} pt={2} sx={{ width: "100%" }}>
        {citiesList.length > 0 &&
          citiesList.map((filteredCity: any, index: number) => {
            return (
              <React.Fragment key={filteredCity.Id}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={() => handleCitySelect && handleCitySelect(filteredCity)}
                >
                  <Typography className={highlightSelectedCity(filteredCity)}>
                    {filteredCity.Name}{" "}
                    {filteredCity.stateName && `(${filteredCity.stateName})`}
                  </Typography>
                  {isMultiSelect && (
                    <components.CheckboxComponent
                      title={
                        selectedCities?.length === maxCount
                          ? "You have reached maximum limit"
                          : ""
                      }
                      checked={selectedCities.some(
                        (selectedCity: any) => selectedCity.Id === filteredCity.Id
                      )}
                      onChange={(e: any) => {
                        if (!e.target.checked) {
                          handleCityCheckbox(filteredCity);
                        } else {
                          selectedCities?.length < maxCount &&
                            handleCityCheckbox(filteredCity);
                        }
                      }}
                    />
                  )}
                </Box>
                <Divider className={style.cityDivider} sx={{ height: "0.1px" }} />
              </React.Fragment>
            );
          })}
        {citiesList.length === 0 && searchText === "" ? (
          <div
            style={{
              width: "100%",
              minHeight: "500px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <components.CircularProgressBar loadername="Loading cities" />
          </div>
        ) : (
          ""
        )}
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
          {getFilteredVal()?.length < totalRecords && totalRecords > next && (
            <components.LoadingButtonComponent
              buttonLabel={"Load More"}
              handleClick={handleShowMoreCity}
              buttonStyle={{ display: "flex", justifyContent: "center" }}
            />
          )}
        </Box>
      </Box>
      {isMultiSelect && selectedCities.length > 0 && (
        <div className={style.citySelectBottomContainer}>
          <Typography className={style.citiesCountStyle}>{`${selectedCities.length} ${
            selectedCities.length > 1 ? "cities" : "city"
          } selected.`}</Typography>
          <Button
            className={style.applyCityButton}
            onClick={() => handleButtonClick("id-apply", selectedCities)}
          >
            Apply
          </Button>
        </div>
      )}
    </>
  );
};

export { CitySelectDialog, CitySelectContent };
