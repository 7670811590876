import { Dispatch } from "react";
import { fetchExplorerClassController } from "../../../../controllers/explorersController";

import { fetchGuideClassController } from "../../../../controllers/guidesController";
import { getDateFormat } from "../../dateTimeFormatFunction";

export function getGuideOrExplorerClass(
  id: string | number,
  setPageState: Dispatch<any>,
  state: any,
  userType?: string
) {
  setPageState((prev: any) => ({
    ...prev,
    isLoading: true,
  }));

  if (userType === "guide") {
    fetchGuideClassController(
      (response: any) => successGuideOrExplorerClassFetch(response, setPageState, state),
      (error: any) => errorGuideOrExplorerClassFetch(error, setPageState),
      id,
      {
        skip: state.page + 1,
        limit: state.pageSize,
      }
    );
  } else {
    fetchExplorerClassController(
      (response: any) => successGuideOrExplorerClassFetch(response, setPageState, state),
      (error: any) => errorGuideOrExplorerClassFetch(error, setPageState),
      id,
      {
        skip: state.page + 1,
        limit: state.pageSize,
      }
    );
  }
}

function successGuideOrExplorerClassFetch(
  response: any,
  setState: Dispatch<any>,
  state: any
) {
  const data =
    Array.isArray(response?.data) &&
    response?.data?.map((item: any, i: number) => {
      const id = i + 1 + state.page * state.pageSize;
      const fullName = `${item.first_name} ${item.last_name}`;
      const dateTime = getDateFormat(item.schedule?.startTimestamp);
      return { ...item, id, fullName, dateTime };
    });

  setState((prev: any) => ({
    ...prev,
    isLoading: false,
    data: data || [],
    total: response.count,
  }));
}

function errorGuideOrExplorerClassFetch(error: any, setState: Dispatch<any>) {
  setState((prev: any) => ({
    ...prev,
    isLoading: false,
    data: [],
    total: 0,
  }));
}
