import * as React from "react";
import { Bar } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import theme from "../../../themes/theme";

Chart.register(CategoryScale);

function BarChartComponent(props: any) {
  const { chartData, options } = props;

  const ticks = {
    beginAtZero: true,
    color: theme.palette.brown.main,
    font: {
      size: "12px",
    },
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: { ...ticks },
        grid: {
          display: false,
        },
      },
      y: {
        min: 0,
        ticks: { ...ticks },
        grid: {
          drawBorder: false,
          display: true,
        },
      },
    },
    ...options,
  };

  return (
    <div style={{minHeight: "200px", height: "300px"}}>
      <Bar data={chartData} options={chartOptions} />
    </div>
  )
}

export { BarChartComponent };
