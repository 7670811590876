import * as React from "react";

import { checkStringLength } from "../dataLengthCheck";
import { getDateFormat } from "../dateTimeFormatFunction";

export const bookingsTableColumns = [
  { headerName: "No.", field: "id", width: 50 },
  { headerName: "Booking ID", field: "bookingId", width: 150 },
  { headerName: "Class ID", field: "classId", width: 150 },
  { headerName: "Schedule ID", field: "scheduleId", width: 150 },
  { headerName: "Guide ID", field: "guideId", width: 150 },
  {
    headerName: "Explorer ID",
    field: "explorerId",
    width: 150,
    title: "explorerId",
  },
  {
    headerName: "Created On",
    field: "createdOn",
    width: 250,
    renderCell: (params: any) => (
      <p title={params.row.createdOn}>
        {params.row.createdOn
          ? getDateFormat(checkStringLength(params.row.createdOn, 30))
          : "NA"}
      </p>
    ),
  },
  {
    headerName: "Updated On",
    field: "updatedOn",
    width: 250,
    renderCell: (params: any) => (
      <p title={params.row.updatedOn}>
        {params.row.updatedOn
          ? getDateFormat(checkStringLength(params.row.updatedOn, 30))
          : "NA"}
      </p>
    ),
  },
  {
    headerName: "Booking Status",
    field: "bookingStatus",
    width: 200,
    renderCell: (params: any) => (
      <p title={params.row.bookingStatus}>
        {checkStringLength(params.row.bookingStatus ?? "NA", 15)}
      </p>
    ),
  },
  {
    headerName: "Payment Status",
    field: "paymentStatus",
    width: 250,
    renderCell: (params: any) => (
      <p title={params.row.paymentStatus}>
        {checkStringLength(params.row.paymentStatus ?? "NA", 20)}
      </p>
    ),
  },
  {
    headerName: "Stripe Order Id",
    field: "stripeOrderId",
    width: 250,
    renderCell: (params: any) => (
      <p title={params.row.stripeOrderId}>
        {checkStringLength(params.row.stripeOrderId ?? "NA", 20)}
      </p>
    ),
  },
  {
    headerName: "Stripe Intent",
    field: "stripeIntent",
    width: 250,
    renderCell: (params: any) => (
      <p title={params.row.stripeIntent}>
        {checkStringLength(params.row.stripeIntent ?? "NA", 20)}
      </p>
    ),
  },
  {
    headerName: "Guide Phone",
    field: "guidePhone",
    width: 200,
    renderCell: (params: any) => (
      <p title={params.row.guidePhone}>
        {checkStringLength(params.row.guidePhone ?? "NA", 20)}
      </p>
    ),
  },
  {
    headerName: "Guide Email",
    field: "guideEmail",
    width: 350,
    renderCell: (params: any) => (
      <p title={params.row.guideEmail}>
        {checkStringLength(params.row.guideEmail ?? "NA", 35)}
      </p>
    ),
  },
  {
    headerName: "Guide Firstname",
    field: "guideFirstName",
    width: 250,
    renderCell: (params: any) => (
      <p title={params.row.guideFirstName}>
        {checkStringLength(params.row.guideFirstName ?? "NA", 22)}
      </p>
    ),
  },
  {
    headerName: "Guide Lastname",
    field: "guideLastName",
    width: 250,
    renderCell: (params: any) => (
      <p title={params.row.guideLastName}>
        {checkStringLength(params.row.guideLastName ?? "NA", 22)}
      </p>
    ),
  },
  {
    headerName: "Class Title",
    field: "classTitle",
    width: 350,
    renderCell: (params: any) => (
      <p title={params.row.classTitle}>
        {checkStringLength(params.row.classTitle ?? "NA", 35)}
      </p>
    ),
  },
  {
    headerName: "Class Start Time",
    field: "startTimeStamp",
    width: 250,
    renderCell: (params: any) => (
      <p title={params.row.startTimeStamp}>
        {params.row.startTimeStamp
          ? getDateFormat(checkStringLength(params.row.startTimeStamp, 30))
          : "NA"}
      </p>
    ),
  },
  {
    headerName: "Class End Time",
    field: "endTimeStamp",
    width: 250,
    renderCell: (params: any) => (
      <p title={params.row.endTimeStamp}>
        {params.row.endTimeStamp
          ? getDateFormat(checkStringLength(params.row.endTimeStamp, 30))
          : "NA"}
      </p>
    ),
  },
  { headerName: "Guide price", field: "guidePrice", width: 150 },
  { headerName: "Spot(s) Booked", field: "seatCount", width: 180 },
  { headerName: "Service charge", field: "serviceCharge", width: 180 },
  { headerName: "Tax", field: "tax", width: 150 },
  { headerName: "Total amount", field: "total", width: 150 },
  { headerName: "Currency", field: "priceCurrency", width: 150 },
  {
    headerName: "Discount Applied (%)",
    field: "discount",
    renderCell: (params: any) => (
      <p title={params.row.discount}>
        {params.row.discount !== undefined && params.row.discount !== null
          ? params.row.discount
          : "NA"}
      </p>
    ),
    width: 250,
  },
  {
    headerName: "Coupon Discount",
    field: "deductedAmount",
    renderCell: (params: any) => (
      <p title={params.row.deductedAmount}>
        {params.row.deductedAmount !== undefined &&
        params.row.deductedAmount !== null
          ? params.row.deductedAmount
          : "NA"}
      </p>
    ),
    width: 200,
  },
  {
    headerName: "Subtotal",
    field: "subTotal",
    renderCell: (params: any) => (
      <p title={params.row.subTotal}>
        {params.row.subTotal !== undefined && params.row.subTotal !== null
          ? params.row.subTotal
          : "NA"}
      </p>
    ),
    width: 150,
  },
  {
    headerName: "Coupon code",
    field: "code",
    renderCell: (params: any) => (
      <p title={params.row.code}>
        {params.row.code !== undefined && params.row.code !== null
          ? params.row.code
          : "NA"}
      </p>
    ),
    width: 250,
  },
];
