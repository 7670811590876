import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import { FormHelperText, Stack, Typography } from "@mui/material";
import { CheckboxComponent } from "../checkbox/CheckboxComponent";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  disablePortal: true,
};

function MultiSelectInputField(props: any) {
  const {
    id,
    name,
    error = false,
    errorText,
    value = [],
    handleChange,
    listData = [],
    disabled,
    stackStyle = { mb: "15px" },
    label,
    onBlur,
  } = props;

  const menuStyle = {
    fontSize: "15px",
    "&:focus": {
      background: "rgba(178, 223, 100, 0.5)",
    },
  };

  const labelStyle = {
    fontWeight: 400,
    fontSize: "15px",
    color: disabled ? "#A29191" : "#353535",
  };

  return (
    <Stack spacing={1} sx={stackStyle}>
      <Typography sx={labelStyle}>{label}</Typography>
      <Select
        id={id}
        name={name}
        error={error}
        displayEmpty
        multiple
        value={value}
        onBlur={onBlur}
        onChange={handleChange}
        renderValue={(selected) => selected?.join(", ")}
        MenuProps={MenuProps}
        sx={{ fontSize: "15px", width: "100%", backgroundColor: disabled && "#E7E1E1" }}
      >
        {listData.map((data: any, index: number) => (
          <MenuItem key={index} value={data} sx={menuStyle}>
            <CheckboxComponent
              checked={
                value
                  .map((val: any) => val.toLowerCase())
                  .indexOf(data?.toString()?.toLowerCase()) > -1
              }
            />
            <ListItemText
              sx={menuStyle}
              primary={<Typography sx={menuStyle}>{data}</Typography>}
            />
          </MenuItem>
        ))}
      </Select>
      {error && (
        <FormHelperText
          style={{
            fontStyle: "italic",
            fontWeight: 400,
            fontSize: "15px",
            color: "#F73030",
            margin: "4px 0 0",
            lineHeight: "22.5px",
          }}
        >
          {errorText}
        </FormHelperText>
      )}
    </Stack>
  );
}

export { MultiSelectInputField };
