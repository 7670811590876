/**this page is for handling functionality of listing guides page */

import { errorConst } from "../../../text-constants";
import { fetchGuidesController } from "../../../../controllers/guidesController";
import { fetchExplorersController } from "../../../../controllers/explorersController";

export const getGuidesOrExplorerList = (
  setState: any,
  state: any,
  setMsg: any,
  params?: any,
  userType?: string
) => {
  setState((prev: any) => ({
    ...prev,
    isLoading: true,
  }));

  const queryParams = {
    skip: state.page + 1,
    limit: state.pageSize,
    ...params,
  };

  if (userType === "guide") {
    fetchGuidesController(
      (response: any) => successFetch(response, setState, state, "Guide"),
      (error: any) => errorFetch(error, setState, setMsg),
      queryParams
    );
  } else {
    fetchExplorersController(
      (response: any) => successFetch(response, setState, state, "Explorer"),
      (error: any) => errorFetch(error, setState, setMsg),
      queryParams
    );
  }
};

function successFetch(response: any, setState: any, state: any, dataAttribute: string) {
  const data = response[dataAttribute].map((item: any, i: number) => {
    const id = i + 1 + state.page * state.pageSize;
    return { ...item, id };
  });

  setState((prev: any) => ({
    ...prev,
    isLoading: false,
    data,
    total: response.count,
    firstUserCreatedOn: response.firstUserCreatedOn,
  }));
}

function errorFetch(error: any, setState: any, setMsg: any) {
  setState((prev: any) => ({
    ...prev,
    isLoading: false,
  }));
  setMsg({
    show: true,
    type: "error",
    msg: getErrorMsg(error),
    errorType: "default",
  });
}

function getErrorMsg(error: any) {
  return error?.data?.message ? error?.data?.message : errorConst.UNKNOW_ERROR;
}
