import * as React from "react";
import { CircularProgress, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import style from "./buttons.module.scss";

function ButtonsListComponent(props: any) {
  const {
    stackClassName = style.twoButtonStack,
    stackDirection = { xs: "column", sm: "column", md: "row", lg: "row" },
    stackSpacing = 2,
    inlineStyle,
    buttonsArray,
    isLoading = false,
    buttonsIndexForLoader,
    onButtonClick,
    disableElevation = true,
  } = props;

  const [clickedButtonIndex, setClickedButtonIndex] = React.useState(null);

  /**
   * This component is useful when you have to implement one or more buttons
   *
   * buttonsArray: array of object, indicates each buttons properties
   * example: buttonsArray = [{type: "submit", handleClick: function(), variant: "outlined", inlineStyle: {'styling properties here'} }]
   *
   * isLoading: boolean for button loader
   *
   * buttonsIndexForLoader: array of numbers indicates the index number of buttonsArray to have the
   * loader when user clicks the button
   * example: buttonsIndexForLoader = [0, 1, 4];
   * explanation: for 0, 1, 4 th index object we need to have loader when we click those buttons
   */

  /**
   *buttonInlineStyle:  By default first button will contain background color and remaining buttons will be outlined
   * you can pass your custom inline style as well
   */

  const buttonInlineStyle = (index: number, button: any) => {
    if (index === 0) {
      return {
        backgroundColor: "#2D2178",
        color: "white",
        fontWeight: "600",
        fontSize: "16px",
        padding: "10px 60px",
        "&:hover": { backgroundColor: "#2D2178" },
        ...button.inlineStyle,
      };
    } else {
      return {
        borderColor: "#2D2178",
        color: "#2D2178",
        padding: "10px 60px",
        fontWeight: "600",
        fontSize: "16px",
        "&:hover": { borderColor: "#2D2178", backgroundColor: "#2D2178", color:'#ffffff' },
        ...button.inlineStyle,
      };
    }
  };

  const buttonVariant = (index: number, button: any) => {
    if (button.variant) {
      return button.variant;
    } else {
      if (index === 0) {
        return "contained";
      } else {
        return "outlined";
      }
    }
  };

  const selectLoaderButton = (index: number) => {
    if (
      isLoading &&
      Array.isArray(buttonsIndexForLoader) &&
      buttonsIndexForLoader.includes(index)
    ) {
      if (index === clickedButtonIndex) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const setButtonDisable = (index: number) => {
    if (isLoading && clickedButtonIndex !== null && clickedButtonIndex !== index) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Stack
      spacing={stackSpacing}
      direction={stackDirection}
      className={stackClassName}
      sx={inlineStyle}
    >
      {Array.isArray(buttonsArray) &&
        buttonsArray.map((button: any, index: any) => {
          return (
            <LoadingButton
              key={index}
              sx={buttonInlineStyle(index, button)}
              type={button.type || "button"}
              loading={selectLoaderButton(index)}
              variant={buttonVariant(index, button)}
              onClick={() => {
                setClickedButtonIndex(index);
                button.handleClick && button.handleClick(button);
                onButtonClick && onButtonClick(button);
              }}
              loadingIndicator={<CircularProgress sx={{ color: "#0D3334" }} size={25} />}
              disabled={setButtonDisable(index)}
              disableElevation={disableElevation}
            >
              {button.label}
            </LoadingButton>
          );
        })}
    </Stack>
  );
}

export { ButtonsListComponent };
