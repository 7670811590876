import * as React from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { LoadingButtonAndTextLink, TextInputField } from "../../components";
import logo from "../../assets/images/Moonlight_Black.svg";
import { forgotPassword } from "../../controllers/authController";
import ToastMessage from "../../components/customMessageBar/Toast";

const ForgotPassword = () => {
  const [showErrorMessage, setShowErrorMessage] = React.useState("");
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
  const [isButtonLoading, setIsButtonLoading] = React.useState<boolean>(false);

  const navigate = useNavigate();

  const validationSchema = Yup.object({
    email: Yup.string().email("Please enter a valid email").required("Email is required"),
  });
  const onSuccess = async (response: any) => {
    setShowSuccessMessage(true);
    setIsButtonLoading(false);
  };
  const onError = (error: any) => {
    setIsButtonLoading(false);
    if (error.data.errors.code === 1000) {
      setShowErrorMessage("Email is invalid");
    } else {
      setShowErrorMessage(error.data.errors.userMessage);
    }
  };

  const submitEmail = (values: any) => {
    setIsButtonLoading(true);
    forgotPassword(onSuccess, onError, values);
  };

  return (
    <Box
      overflow="auto"
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        width="100%"
        flexDirection={"row"}
      >
        <div>
          <img src={logo} alt="Logo" />
          <Typography variant="h3" color="darkGrey.main" mt={8} mb={4}>
            Forgot Password
          </Typography>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              submitEmail(values);
              resetForm({
                values: {
                  email: "",
                },
              });
            }}
            validateOnChange
          >
            {({ errors, touched, values, handleChange }) => (
              <Form style={{ width: "400px" }}>
                {showSuccessMessage && (
                  <ToastMessage
                    toastHeader="Email Sent!"
                    toastMessage="Please check your email and click on the provided link to reset your password"
                  />
                )}
                <TextInputField
                  label="Email"
                  id="email"
                  placeholder="Enter Email"
                  name="email"
                  value={values.email}
                  error={(touched.email && Boolean(errors.email)) || showErrorMessage}
                  errorText={showErrorMessage || errors.email}
                  onChange={(e: any) => {
                    handleChange(e);
                    setShowErrorMessage("");
                    setShowSuccessMessage(false);
                  }}
                />
                <Typography
                  variant="body2"
                  color="brown.light"
                  sx={{ fontStyle: "italic" }}
                >
                  Enter the email associated with your account and we'll send an email to
                  reset your password
                </Typography>
                <LoadingButtonAndTextLink
                  isLoading={isButtonLoading}
                  buttonArray={[
                    { type: "submit", label: "Submit", inlineStyle: { width: "100%" } },
                  ]}
                  text={"Back to login"}
                  onTextClick={() => navigate("/")}
                />
              </Form>
            )}
          </Formik>
        </div>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
