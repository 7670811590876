import { Button } from "@mui/material";
import * as React from "react";
import { NavigateFunction } from "react-router-dom";
import { buttonStyle, guideCancelRequestedStyle } from "../../buttonFunction";
import { checkStringLength } from "../../dataLengthCheck";
import { getDateFormat } from "../../dateTimeFormatFunction";

export const columnHeaders = (navigate: NavigateFunction, setIsUnpublish: any, setExperienceReviewStatus: any) => {
  return [
    { headerName: "No.", field: "id", flex: 1, minWidth: 50 },
    {
      headerName: "Approval Status", field: "experienceStatus", sortable: true, width: 200, renderCell: (params: any) => {
        const showButton = () => {  
          if (
            params?.row?.experienceStatus === 'PendingApproval' &&
            params?.row?.class_status !== 'Cancelled' &&
            params?.row?.class_status1 !== 'Unpublished'
          ) {
            return (
              <Button
                sx={[buttonStyle, {
                  color: "white",
                  backgroundColor: "#FE6331",
                  "&:hover": {
                    borderColor: "#FE6331",
                    backgroundColor: "rgba(254, 99, 49, 0.8)"
                  },
                }]}
                variant="outlined"
                onClick={() => {
                  const classDetails = params.row
                  setExperienceReviewStatus({
                    id: classDetails.id,
                    classId: classDetails.class_id,
                    userId: classDetails.userId,
                    className: classDetails.class_title,
                    isClicked: true,
                    status: classDetails.experienceStatus
                  })
                }}
              >
                {"Pending"}
              </Button>)
          } else if (params?.row?.experienceStatus === 'Approved') {
            return (
              'Approved'
            )
          } else if (params?.row?.experienceStatus === 'Rejected') {
            return (
              'Rejected'
            )
          } else {
            return ''
          }
        }
        return (
          showButton()
        )
      }
    },
    {
      field: "moreInfo",
      headerName: "More Info",
      sortable: false,
      disableClickEventBubbling: true,
      width: 160,
      renderCell: (params: any) => {
        return (
          <Button
            sx={buttonStyle}
            variant="outlined"
            onClick={() =>
              navigate("/admin/class-details", {
                state: { class_id: params.row.class_id },
              })
            }
          >
            {"Details"}
          </Button>
        );
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      sortable: false,
      disableClickEventBubbling: true,
      width: 160,
      renderCell: (params: any) => {
        return params?.row?.guideCancelRequested ? (
          params?.row.class_status === "Scheduled" &&
          params?.row.is_active === true &&
          params.row.scheduled_time_stamps &&
          !params.row.scheduled_time_stamps[0]?.isGuideCheckedIn && (
            <Button
              onClick={() =>
                setIsUnpublish({
                  id: params.row.class_id,
                  classTitle: params.row.class_title,
                  isClicked: true,
                  reasonId:params.row.cancellation?.reasonId,
                  reasonDisplay:params.row.cancellation?.reasonDisplay,                 
                  otherReason:params.row.cancellation?.otherReason,
                  guideCancelRequested: params?.row?.guideCancelRequested,
                  scheduleId:
                    params.row.scheduled_time_stamps &&
                    params.row.scheduled_time_stamps[0]?.scheduleId,
                })
              }
              sx={[buttonStyle, params?.row?.guideCancelRequested ? guideCancelRequestedStyle : null]}
              variant="outlined"
            >
               Requested
            </Button>
          )
        ) : (
          params?.row.class_status === "Scheduled" &&
          params?.row.is_active === true &&
          params.row.scheduled_time_stamps &&
          !params.row.scheduled_time_stamps[0]?.isGuideCheckedIn && (
            <Button
              onClick={() =>
                setIsUnpublish({
                  id: params.row.class_id,
                  classTitle: params.row.class_title,
                  isClicked: true,
                  reasonId:params.row.cancellation?.reasonId,
                  reasonDisplay:params.row.cancellation?.reasonDisplay,            
                  otherReason:params.row.cancellation?.otherReason,
                  scheduleId:
                    params.row.scheduled_time_stamps &&
                    params.row.scheduled_time_stamps[0]?.scheduleId,
                })
              }
              sx={[buttonStyle, params?.row?.guideCancelRequested ? guideCancelRequestedStyle : null]}
              variant="outlined"
            >
              Cancel
            </Button>
          )
        );
      },
    },

    { headerName: "Class ID", field: "class_id", flex: 1, minWidth: 100 },
    {
      headerName: "Class Title",
      field: "class_title",
      flex: 1,
      minWidth: 310,
      renderCell: (params: any) => {
        return <p title={params.row.class_title}>{checkStringLength(params.row.class_title)}</p>;
      },
    },
    { headerName: "Class Status", field: "class_status1", flex: 1, minWidth: 150 },
    {
      headerName: "Category Name",
      field: "category_name",
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => {
        return (
          <p title={params.row.category_name}>{checkStringLength(params.row.category_name, 20)}</p>
        );
      },
    },
    {
      headerName: "Guide Email",
      field: "guide_email",
      flex: 1,
      minWidth: 280,
      renderCell: (params: any) => {
        return (
          <p title={params.row.guide_email}>{checkStringLength(params.row.guide_email, 25)}</p>
        );
      },
    },
    {
      headerName: "Guide Name",
      field: "guide_name",
      flex: 1,
      minWidth: 250,
      renderCell: (params: any) => {
        return <p title={params.row.guide_name}>{checkStringLength(params.row.guide_name, 25)}</p>;
      },
    },
    { headerName: "Country", field: "country", flex: 1, minWidth: 180 },
    { headerName: "City", field: "city", flex: 1, minWidth: 160 },
    {
      headerName: "Schedule start date & time",
      field: "schedule_start_datetime",
      flex: 1,
      minWidth: 280,
      renderCell: (params: any) => {
        return (
          <p title={getDateFormat(params.row.schedule_start_datetime)}>
            {getDateFormat(params.row.schedule_start_datetime)}
          </p>
        );
      },
    },
    {
      headerName: "Schedule end date & time",
      field: "schedule_end_datetime",
      flex: 1,
      minWidth: 280,
      renderCell: (params: any) => {
        return (
          <p title={getDateFormat(params.row.schedule_end_datetime)}>
            {getDateFormat(params.row.schedule_end_datetime)}
          </p>
        );
      },
    },
    { headerName: "Total Bookings", field: "total_bookings", flex: 1, minWidth: 180 },
    {
      headerName: "Total Cancellations",
      field: "total_cancellations",
      flex: 1,
      minWidth: 200,
    },
  ];
};
