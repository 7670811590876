import { PATH_PARAMS } from "../constants";
import { SuccessResponse } from "../types/parameters.type";
import { ApiClient, ErrorHandler, SuccessHandler } from "../services/data.service";

export const getGraphClassData = async (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params: any
) => {
  ApiClient.makeGetRequest(
    PATH_PARAMS.FETCH_CLASS_GRAPH_DATA,
    successHandler,
    errorHandler,
    params
  );
};

export const getGraphUserData = async (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params: any
) => {
  ApiClient.makeGetRequest(
    PATH_PARAMS.FETCH_USER_GRAPH_DATA,
    successHandler,
    errorHandler,
    params
  );
};

export const getGraphClassPercentData = async (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params: any
) => {
  ApiClient.makeGetRequest(
    PATH_PARAMS.FETCH_PERCENT_CLASS_DATA,
    successHandler,
    errorHandler,
    params
  );
};

export const getGraphBookingsdata = async (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params: any
) => {
  ApiClient.makeGetRequest(
    PATH_PARAMS.FETCH_BOOKINGS_GRAPH_DATA,
    successHandler,
    errorHandler,
    params
  );
};
