export const couponsConst = {
  TITLE: "Title* (Max 30 Characters)",
  DISCOUNT: "Discount* (%)",
  COUNTRY: "Country*",
  START_DATE_TIME: "Start Date*",
  END_DATE_TIME: "End Date*",
  MIN_BILL_AMT: "Minimum Transaction Amount*",
  MAX_DISCOUNT: "Maximum Discount (Optional)",
  DESCRIPTION: "Description* (Max 50 Characters)",
  SEND_EMAIL_TO_USERS: "Send coupon to all users and notify via email",
  TERMS_CONDITIONS: "Terms & Conditions*",
  DURATION_IN_MONTHS: "Duration* (months)",
  STATUS: "Status*",
  IS_ONE_OFF_COUPON: "Is one-off coupon?"
};
