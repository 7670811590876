export const twoButtonList = (
  label1?: string,
  label2?: string,
  type1?: string,
  type2?: string
) => {
  return [
    {
      id: label1 ? `id-${label1}` : "update",
      label: label1 || "Update",
      type: type1 || "submit",
    },
    {
      id: label2 ? `id-${label2}` : "cancel",
      label: label2 || "Cancel",
      type: type2 || "button",
    },
  ];
};

export const applyCancelButtonsList = () => {
  return [
    {
      id: "apply",
      label: "Apply",
    },
    {
      id: "cancel",
      label: "Cancel",
    },
  ];
};

export const twoButtonsList: any = (label1 = "Delete", label2 = "Cancel") => {
  return [
    {
      id: `id-${label1}`,
      label: label1,
      inlineStyle: { width: "50%" },
    },
    {
      id: `id-${label2}`,
      label: label2,
      inlineStyle: { width: "50%" },
    },
  ];
};

/**3 buttons */

export const emailTemplateEditButtons: any = () => {
  return [
    {
      id: "id-update",
      label: "Update",
      type: "submit",
    },
    {
      id: "id-preview-email",
      label: "Send Test Email",
    },
    {
      id: "id-cancel",
      label: "Cancel",
    },
  ];
};

export const buttonStyle = {
  height: "40px",
  width: "100%",
  fontSize: "18px",
  color: "#FE6331",
  borderColor: "#FE6331",
  borderRadius: "6px",
  backgroundColor: "white",
  "&:hover": {
    borderColor: "#FE6331",
    backgroundColor: "rgba(254, 99, 49, 0.2)"
  },
};

export const guideCancelRequestedStyle = {
  backgroundColor: "#FE6331",
  color: "white",
}
