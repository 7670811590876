import axios, { AxiosInstance } from "axios";
import { getIdToken, clearStorage } from "../utils/auth";
import { ErrorResponse } from "../types/parameters.type";

export type SuccessHandler<T> = (t: T) => void;
export type ErrorHandler = (error: ErrorResponse) => void;

export enum RequestType {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

class AxiosApiClient {
  private readonly BASE_URL = `${process.env.REACT_APP_BASE_API_URL}/${process.env.REACT_APP_API_VERSION}/admin/`;

  private readonly axios: AxiosInstance;

  constructor() {
    this.axios = axios.create({
      baseURL: this.BASE_URL,
    });
  }

  private readonly logoutUser = () => {
    //clear cookie and redirect user to particular page
    clearStorage();
    window.location.assign("/login");
  };
  private reCallApis(callBackRequests: Array<any>) {
    callBackRequests.forEach((eachReq: any) => {
      this.makeRequest(
        eachReq.uri,
        eachReq.requestType,
        eachReq.success,
        eachReq.failure,
        eachReq.body,
        eachReq.keyPrefix,
        false
      );
    });
  }

  public makeRequest<T, V>(
    uri: string,
    requestType: RequestType,
    success: SuccessHandler<T>,
    failure: ErrorHandler,
    body?: V,
    keyPrefix?: string,
    fromRefresh = false
  ) {
    const authToken = getIdToken(); //Value from cookie

    if (authToken !== undefined) {
      // No auth token logout user
    }

    this.axios
      .request<T>({
        data: body,
        url: uri,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: requestType === RequestType.GET ? body : "",
        method: requestType,
      })
      .then((response) => {
        success(response.data);
      })
      .catch((error) => {
        if (error && error.response) {
          if (error.response.status === 403) {
            this.logoutUser();
            return;
          } else {
            if (error?.message && error.message === "Network Error") {
              return failure(error);
            }
            failure(error.response);
          }
        }
      });
  }

  //  ---- Use this for get request , with params and with query parameter --- //

  public makeGetRequest<T, V>(
    uri: string,
    success: SuccessHandler<T>,
    failure: ErrorHandler,
    params?: V,
    key?: string
  ) {
    this.makeRequest(uri, RequestType.GET, success, failure, params, key);
  }

  //  ---- Use this for post request , with body or form data --- //

  public makePostRequest<T, V>(
    uri: string,
    success: SuccessHandler<T>,
    failure: ErrorHandler,
    body?: V
  ) {
    this.makeRequest(uri, RequestType.POST, success, failure, body);
  }
  //  ---- Use this for put request , with body or form data -- //

  public makePutRequest<T, V>(
    uri: string,
    success: SuccessHandler<T>,
    failure: ErrorHandler,
    body?: V
  ) {
    this.makeRequest(uri, RequestType.PUT, success, failure, body);
  }

  //  ----- Use this for delete request , with id --- //
  public makeDeleteRequest<T, V>(
    uri: string,
    success: SuccessHandler<T>,
    failure: ErrorHandler,
    body?: V
  ) {
    this.makeRequest(uri, RequestType.DELETE, success, failure, body);
  }

  //  ----- Use this for patch request ---- //

  public makePatchRequest<T, V>(
    uri: string,
    success: SuccessHandler<T>,
    failure: ErrorHandler,
    body?: V
  ) {
    this.makeRequest(uri, RequestType.PATCH, success, failure, body);
  }
}
export const ApiClient = new AxiosApiClient();
