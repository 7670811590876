import { Box, Dialog, Divider, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import * as React from "react";
import * as Yup from "yup";

import { IMAGES } from "../../assets/images";
import * as components from "../../components/index";
import { addPromotedClass } from "../../controllers/promotedClassController";
import { twoButtonList } from "../../utils/reusable-functions/buttonFunction";
import { addEditAdminsConst, alertConst, errorConst } from "../../utils/text-constants";
import style from "./promoted-class.module.scss";

const validatePromotedForm = (item: any) =>
  Yup.object({
    startTime: Yup.date()
      .min(new Date(), errorConst.MIN_START_DATE)
      .max(new Date(item.scheduleStart), errorConst.DATE_RANGE_ERROR_PROMOTION_START_DATE)
      .required(errorConst.START_DATE_REQUIRED)
      .typeError(errorConst.ENTER_VALID_DATE),
    endTime: Yup.date()
      .min(Yup.ref("startTime"), errorConst.END_DATE_GREATERTHAN_START_DATE)
      .max(new Date(item.scheduleStart), errorConst.DATE_RANGE_ERROR_PROMOTION_END_DATE)
      .required(errorConst.END_DATE_REQUIRED)
      .typeError(errorConst.ENTER_VALID_DATE),
    sequenceNo: Yup.string().trim().required(errorConst.SEQUENCE_NO_REQUIRED),
  });

type IPromotionClassFormComponentProps = {
  dropdownbox: any[];
  item: any;
  openPopup: boolean;
  setOpenPopup: (openPopup: boolean) => void;
  onButtonClick: () => void;
};
const PromotionClassFormComponent: React.FC<IPromotionClassFormComponentProps> = ({
  item,
  openPopup,
  setOpenPopup,
  onButtonClick,
  dropdownbox,
}) => {
  const { class_id, class_title, countryId, scheduleStart } = item;

  const [isUploading, setIsUploading] = React.useState<boolean>(false);

  const initialValues = {
    classTitle: class_title,
    startTime: "",
    endTime: "",
    sequenceNo: "",
    isActive: item.sequence_no ? false : true,
  } as any;

  const handleSubmitForm = (values: any) => {
    setIsUploading(true);

    let params: any;

    if (values.isActive) {
      params = {
        classId: class_id,
        promote: values.isActive,
        sequenceNo: values.sequenceNo,
        startTime: values.startTime,
        endTime: values.endTime,
        countryId: countryId,
      };
    } else {
      params = {
        classId: class_id,
        promote: values.isActive,
      };
    }

    addPromotedClass(
      (res: any) => {
        setOpenPopup(false);
        onButtonClick();
      },
      (err: any) => {
        setIsUploading(false);
      },
      params
    );
  };

  const handleButtonClick = (data: any) => {
    if (data.id === "id-Cancel") {
      setOpenPopup(false);
    }
  };

  return (
    <Dialog
      onClose={() => {
        return;
      }}
      open={openPopup}
      PaperProps={{
        style: { borderRadius: "8px", maxWidth: "1000px" },
      }}
    >
      <Stack className={style.promotionClassContainer} spacing={1}>
        <Stack className={style.confirmContainer}>
          <Typography variant="h5" className={style.confirmHeader}>
            {alertConst.PROMOTE_CLASS_HEADER}
          </Typography>
          <Tooltip title="Close" placement="left">
            <img
              src={IMAGES.closeIcon}
              className={style.closeIcon}
              onClick={() => setOpenPopup(false)}
              alt="close"
            />
          </Tooltip>
        </Stack>
        <Divider />
        <Box p={2} mb={25}>
          <Formik
            initialValues={initialValues}
            validationSchema={() => validatePromotedForm(item)}
            onSubmit={(values) => handleSubmitForm(values)}
            validateOnChange
          >
            {({ errors, touched, handleChange, values, setFieldValue }) => {
              return (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={12} lg={12}>
                      <components.TextInputField
                        name={"className"}
                        label={"Class Name"}
                        maxInputLenth={20}
                        value={class_title}
                        disabled={true}
                      />
                    </Grid>

                    <Grid item sm={12} md={12} lg={12}>
                      <components.SingleSelectInputField
                        name="sequenceNo"
                        label={addEditAdminsConst.POSITION}
                        listData={dropdownbox}
                        onChange={handleChange}
                        value={values.sequenceNo}
                        error={errors.sequenceNo && touched.sequenceNo}
                        errorText={errors.sequenceNo}
                      />
                    </Grid>

                    <Grid item sm={12} md={6} lg={6}>
                      <components.DateTimePickerComponent
                        label={addEditAdminsConst.START_DATE}
                        value={values.startTime}
                        onChange={(value: any) => setFieldValue("startTime", value)}
                        error={errors.startTime}
                        errorText={errors.startTime}
                        minDate={new Date()}
                        maxDate={scheduleStart}
                      />
                    </Grid>

                    <Grid item sm={12} md={6} lg={6}>
                      <components.DateTimePickerComponent
                        label={addEditAdminsConst.END_DATE}
                        value={values.endTime}
                        onChange={(value: any) => setFieldValue("endTime", value)}
                        error={errors.endTime}
                        errorText={errors.endTime}
                        minDate={values.startTime || new Date()}
                        maxDate={scheduleStart}
                      />
                    </Grid>
                  </Grid>
                  <Divider sx={{ mt: 2 }} />
                  <components.ButtonsListComponent
                    isLoading={isUploading}
                    buttonsIndexForLoader={[0]}
                    buttonsArray={twoButtonList("Promote", "Cancel")}
                    onButtonClick={(data: any) => handleButtonClick(data)}
                  />
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Stack>
    </Dialog>
  );
};

export default PromotionClassFormComponent;
