import { Tooltip } from "@mui/material";
import * as React from "react";
import { IMAGES } from "../../assets/images";
import style from "./targetedLocation.module.scss";
import * as components from "..";

function TargetedLocationComponent(props: any) {
  const {
    isDeleteIconVisible,
    text1,
    singleSelectField,
    eachLocation,
    handleAddCitiesClick,
    handleSelectAllCitiesClick,
    onCityDeleteClick,
    handleDeleteLocationClick,
    error,
    errorText,
  } = props;

  return (
    <>
      <div className={style.locationHeader}>
        <h2>{text1}</h2>
        {isDeleteIconVisible && (
          <Tooltip title={"Delete"}>
            <img
              src={IMAGES.deleteFilledOrangeIcon}
              className={style.deleteIconStyle}
              onClick={handleDeleteLocationClick}
            />
          </Tooltip>
        )}
      </div>
      <components.SingleSelectInputField
        name={singleSelectField.name}
        label={singleSelectField.label}
        listData={singleSelectField.listData}
        onChange={singleSelectField.onChange}
        value={singleSelectField.value}
      />
      <components.TargetedCityComponent
        eachLocation={eachLocation}
        handleAddCitiesClick={handleAddCitiesClick}
        handleSelectAllCitiesClick={handleSelectAllCitiesClick}
        onCityDeleteClick={onCityDeleteClick}
        error={error}
        errorText={errorText}
      />
    </>
  );
}

export { TargetedLocationComponent };
