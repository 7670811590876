import { Typography } from "@mui/material";
import * as React from "react";
import { ImageWithContainer, ImageFileInput } from "..";
import style from "./imageComponent.module.scss";

function LabelImagesListingComponent(props: any) {
  const {
    labelClassName = style.mediumFontHeader,
    imageContainerClassName = style.imageContainer,
    imagesList,
    label,
    imageContainerStyle = { width: "170px", height: "140px" },
    imageStyle = { width: 170, height: 140 },
    imgAttribute,
    isDeleteIconVisible,
    showUploadImageContainer = false,
    onDeleteClick,
    handleImageSelect
  } = props;

  return (
    <>
      <Typography className={labelClassName}>{label}</Typography>
      <div className={imageContainerClassName}>
        {imagesList.map((data: any, i: number) => {
          return (
            <React.Fragment key={i}>
              <ImageWithContainer
                imageData={data}
                containerStyle={imageContainerStyle}
                imageStyle={imageStyle}
                imgAttribute={imgAttribute}
                isDeleteIconVisible={isDeleteIconVisible}
                onDeleteClick={()=> onDeleteClick(data, i)}
              />
            </React.Fragment>
          );
        })}
        {showUploadImageContainer && (
          <ImageFileInput handleImageChange={handleImageSelect}/>
        )}
      </div>
    </>
  );
}

export { LabelImagesListingComponent };
