import * as React from "react";
import { Box, Divider, Stack, Tooltip, Typography } from "@mui/material";

import { IMAGES } from "../../assets/images";
import { twoButtonsList } from "../../utils/reusable-functions/buttonFunction";
import { ButtonsListComponent } from "..";
import style from "./delete.module.scss";
import { alertConst } from "../../utils/text-constants";

function DeleteComponent(props: any) {
  const {
    handleButtonClick,
    heading = alertConst.DELETE_CONFIRM_HEADER,
    subHeading = alertConst.DELETE_SUB_TEXT,
    buttonsLabel,
    isLoading,
  } = props;

  return (
    <Stack className={style.deleteContainer} spacing={1}>
      <Stack className={style.confirmContainer}>
        <Typography variant="h5" className={style.confirmHeader}>
          {heading}
        </Typography>
        <Tooltip title="Close" placement="left">
          <img
            src={IMAGES.closeIcon}
            className={style.closeIcon}
            onClick={() => handleButtonClick({ id: "id-Cancel" })}
            alt="close"
          />
        </Tooltip>
      </Stack>
      <Divider />
      <Typography className={style.subText}>{subHeading}</Typography>
      <Box>
        <ButtonsListComponent
          isLoading={isLoading}
          buttonsIndexForLoader={[0]}
          buttonsArray={twoButtonsList(buttonsLabel?.label1, buttonsLabel?.label2)}
          onButtonClick={(data: any) => {
            handleButtonClick(data);
          }}
        />
      </Box>
    </Stack>
  );
}

export { DeleteComponent };
