import { Tooltip } from "@mui/material";
import * as React from "react";
import { IMAGES } from "../../../assets/images";
import { checkStringLength } from "../dataLengthCheck";

const iconsContainer = {
  width: "44px",
  height: "44px",
  cursor: "pointer",
  margin: "3px",
};

export const emailTemplateTableHeaders = (editClickFunction: any) => {
  return [
    { headerName: "No.", field: "id", minWidth: 50 },
    {
      field: "Actions",
      headerName: "Actions",
      sortable: false,
      disableClickEventBubbling: true,
      width: 140,
      renderCell: (params: any) => {
        return (
          <Tooltip title="Edit email template">
            <img
              onClick={() => editClickFunction({ id: params.row?.templateId })}
              style={iconsContainer}
              src={IMAGES.editFilledGreenIcon}
            />
          </Tooltip>
        );
      },
    },
    {
      headerName: "Name",
      field: "templateName",
      minWidth: 400,
      renderCell: (params: any) => (
        <p title={params.row.templateName}>
          {checkStringLength(params?.row?.templateName || "", 50)}
        </p>
      ),
    },
    {
      headerName: "Subject",
      field: "templateSubject",
      minWidth: 650,
      renderCell: (params: any) => (
        <p title={params.row.templateSubject}>
          {checkStringLength(params?.row?.templateSubject || "", 60)}
        </p>
      ),
    },
  ];
};
