import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

import useMessage from "../../hooks/customHooks/useMessage";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomMessageBar() {
  const { msg, setMsg } = useMessage();

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setMsg({
      ...msg,
      show: false,
    });
  };

  return (
    <Snackbar
      open={msg?.show}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <Alert
        onClose={handleClose}
        severity={msg?.type || "info"}
        sx={{ width: "100%", fontWeight: 550, fontSize: "16px" }}
      >
        {msg?.msg || msg?.type}
      </Alert>
    </Snackbar>
  );
}
