import * as _ from "lodash";
import { checkIfOtherLangRequired, getFieldIndex } from "./editGuideOrExplorerFunctions";
const LANGUAGE_OPTIONS = require("../../../text-constants/languagesList.json");

export function onEndIconClick(values: any, index: number) {
  if (values[index].name === "otherLanguages") {
    return getLanguagesIndex(values);
  } else {
    return null;
  }
}

function getLanguagesIndex(values: any) {
  return values.findIndex((eachObject: any) => eachObject.name === "languagesKnown");
}

export function getOnlyMentionedLanguage(languages: any[] = []) {
  return languages.filter((eachLang: any) =>
    LANGUAGE_OPTIONS.some(
      (defaultLanguage: any) =>
        defaultLanguage.lang.toLowerCase() === eachLang.toString().toLowerCase()
    )
  );
}

export function getTagsList(
  value: any,
  currentFieldIndex: number,
  index: number,
  isTagList: boolean
) {
  if (isTagList && value[currentFieldIndex].name === "otherLanguages") {
    const filteredLanguage = getOtherLanguages(value[index].data);
    return filteredLanguage.map((language: any) => ({ name: language }));
  } else {
    return [];
  }
}

export function getOtherLanguages(languagesList: any[]) {
  return languagesList.filter(
    (eachLang: any) =>
      !LANGUAGE_OPTIONS?.some(
        (a: any) =>
          a.lang?.toString()?.toLowerCase() === eachLang?.toString()?.toLowerCase()
      )
  );
}

export function getIsFieldVisible(values: any, index: number) {
  if (values[index].name === "pronounOther") {
    const indexOfPronoun = values.findIndex((val: any) => val.name === "pronoun");
    return values[indexOfPronoun].data === "Other" ? true : false;
  } else if (values[index].name === "otherLanguages") {
    if (values[index].isFieldVisible) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

export function removeOtherLangTag(
  tag: any,
  setEditData: any,
  values: any,
  index: number
) {
  const getLangIndex = getFieldIndex(values, "languagesKnown");
  const getIndexOfOtherLang = getFieldIndex(values, "otherLanguages");
  setEditData((previousState: any) => ({
    ...previousState,
    data: [
      ...previousState.data.map((userData: any, fieldIndex: number) => {
        if (fieldIndex === index) {
          if (getLangIndex > -1) {
            values[getLangIndex].data = _.without(values[getLangIndex].data, tag?.name);
          }

          const langIndex = getFieldIndex(values, "languagesKnown");
          const isRequired = checkIfOtherLangRequired(values, langIndex);
          values[getIndexOfOtherLang].isDataPresent = !isRequired;
        }
        return userData;
      }),
    ],
  }));
}
