import { Grid } from "@mui/material";
import * as React from "react";
import { useLocation } from "react-router-dom";

import CouponsTabsDataComponent from "./CouponsTabsDataComponent";
import * as components from "../../../../components";

export default function CouponsListingPage() {
  const location: any = useLocation();

  const [currentTabIndexValue, setCurrentTabIndexValue] = React.useState(
    location?.state?.tabIndex || 0
  );

  return (
    <Grid container pt={3} pl={3} spacing={2.5}>
      <components.TabComponent
        tabsData={[
          {
            label: "Referral",
            tabComponent: <CouponsTabsDataComponent pageIndex={currentTabIndexValue} />,
          },
          {
            label: "Generic",
            tabComponent: <CouponsTabsDataComponent pageIndex={currentTabIndexValue} />,
          },
        ]}
        currentTabIndexValue={currentTabIndexValue}
        setCurrentTabIndexValue={setCurrentTabIndexValue}
      />
    </Grid>
  );
}
