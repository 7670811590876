import * as React from "react";
import { Typography, Card, Grid } from "@mui/material";
import TickIcon from "../../assets/images/Tick_Square.svg";

const Toast = ({ toastHeader = "", toastMessage = "" }) => {
  return (
    <Card variant="outlined" sx={{ my: "18px", px: "4px" }}>
      <Grid container spacing={2}>
        <Grid
          item
          sm={2}
          sx={{
            backgroundColor: "green.main",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <img
            style={{ height: "24px", margin: "32px 0" }}
            src={TickIcon}
            alt="Success"
          />
        </Grid>
        <Grid item sm={10} sx={{ my: "12px" }}>
          <Typography variant="button" color="green.main" sx={{ fontWeight: 500 }}>
            {toastHeader}
          </Typography>
          <Typography variant="body1" color="brown.main" sx={{ fontWeight: 500 }}>
            {toastMessage}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default Toast;
