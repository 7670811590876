import * as React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";

import loginBgImage from "../../assets/images/link-expired.svg";
import MoonparkLogo from "../../assets/images/Moonlight_Black.svg";
import { Typography } from "@mui/material";

const LinkExpired = () => {
  const navigate = useNavigate();

  const navigateTo = () => {
    navigate('/forgot-password');
  };

  return (
    <Grid container component="main" alignItems="center">
      <Grid item xs={12} sm={8} md={6}>
        <Grid
          container
          spacing={2}
          sx={{
            py: 36,
            pl: 32,
            pr: 16,
            flexDirection: "column",
          }}
        >
          <Grid item sx={{}}>
            <img src={MoonparkLogo} alt="Logo" />
            <Typography variant="h3" color="darkGrey.main" mt={8} mb={4}>
              Oops, This link is expired
            </Typography>
            <Typography variant="h5" color="secondary.contrastText" mb={2}>
              This URL is not valid anymore. Please try again
            </Typography>
            <Button
              type="button"
              fullWidth
              onClick={navigateTo}
              variant="contained"
              sx={{
                my: 3,
                py: '12px',
                // height: "50px",
                backgroundColor: "#FD6E3F",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#FD6E3F",
                },
              }}
            >
              Try Again
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={false}
        sm={4}
        md={6}
        justifyContent="center"
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          px: 16
        }}
      >
        <img src={loginBgImage} alt="expired" />
      </Grid>
    </Grid>
  );
};

export default LinkExpired;
