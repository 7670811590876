import { getSignupDateLabel } from "../../../helper-functions";
import { getSingupDate } from "../../../helper-functions/helperFunction";
import { getDateFormat } from "../../dateTimeFormatFunction";
import { getCountryName } from "../../getCountryName";

/**
 * breaking down the data into functions to remove sonarqube cognitive complexity issue
 * reference page: view guide / view explorer page
 */
export const viewGuideOrExplorerData = (values: any, userType: string) => {
  return [
    ...viewSubData1(values),
    ...viewSubData2(values, userType),
    ...taggingsAndQuestions(values, getQuestionsAttribute(userType)),
  ];
};

function viewSubData1(values: any) {
  return [
    {
      label: "User ID",
      data: values.userId || "-",
    },
    {
      label: "User Name",
      data: values.userHandle || "-",
    },
    {
      label: "First Name",
      data: values.firstName || "-",
    },
    {
      label: "Last Name",
      data: values.lastName || "-",
    },
    {
      label: "Date of Birth",
      data: getDateFormat(values.dateOfBirth) || "-",
    },
    {
      label: "Phone Number",
      data: values.phone || "-",
    },
    {
      label: "Email",
      data: values.email || "-",
    },
    {
      label: "Secondary Email",
      data: values.secondaryEmail || "-",
    },
    {
      label: "Is email verified (Yes / No)",
      data: values.emailVerified?.isVerified ? "Yes" : "No",
    },
    {
      label: "Email Verification date (If Email is verified)",
      data: values.emailVerified?.verifiedOn
        ? getDateFormat(values.emailVerified?.verifiedOn)
        : "-",
    },
    {
      label: "Country",
      data: getCountryName(values.countryId) || "-",
    },
    {
      label: "City",
      data: values.cityName || "-",
    },
    {
      label: "How did the user hear about app",
      data: values.howDidYouHear || "-",
    },
    {
      label: "I Speak",
      data: values.languagesKnown?.join(", "),
    },
  ];
}

function viewSubData2(values: any, userType: string) {
  return [
    {
      label: "I would like to be called",
      data: getPronounBasedOnUserType(values, userType),
    },
    {
      label: "Bio",
      data: getBioBasedOnUserType(values, userType),
    },
    {
      label: getSignupDateLabel(userType),
      data: getSingupDate(values, userType),
    },
    {
      label: "Agreed For Recieving Marketing Campaign",
      data: values.agreedForRecievingMarketingCampaign ? "Yes" : "No",
    },
    {
      label: "Referred By",
      data: values?.referredBy ? values.referredBy : "-",
    },
  ];
}

function taggingsAndQuestions(values: any, attribute: string) {
  return [
    ...(values[attribute]?.questionnaire
      ? values[attribute]?.questionnaire?.map((eachQuestion: any) => ({
          label: eachQuestion.questionDisplay,
          data: eachQuestion.answers?.length
            ? eachQuestion.answers?.join(", ")
            : "-",
        }))
      : []),
    ...(values[attribute]?.taggings
      ? [
          {
            label: "What are you interested in learning?",
            data:
              Array.isArray(values[attribute]?.taggings) &&
              values[attribute]?.taggings?.map((eachTag: any) => ({
                name: eachTag.name,
                prefix: " ",
              })),
            displayType: "taggings",
          },
        ]
      : []),
  ];
}

/**
 * getting pronoun based on userType
 * userType: "guide" || "explorer"
 */
function getPronounBasedOnUserType(values: any, userType: string) {
  if (userType === "guide") {
    return values?.guidePronoun || values?.pronoun || "-";
  } else if (userType === "explorer") {
    return values?.pronoun || "-";
  } else {
    return "-";
  }
}

/**
 * getting bio based on userType
 */

function getBioBasedOnUserType(values: any, userType: string) {
  if (userType === "guide") {
    return values.guideBio || "-";
  } else if (userType === "explorer") {
    return values.explorerBio || "-";
  } else {
    return "-";
  }
}

function getQuestionsAttribute(userType: string) {
  if (userType === "guide") {
    return "guidePreferences";
  } else {
    return "explorerPreferences";
  }
}
