import { PATH_PARAMS } from "../constants";
import { SuccessResponse } from "../types/parameters.type";
import { ApiClient, ErrorHandler, SuccessHandler } from "../services/data.service";

export const getDashboardClassesList = async (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params: any
) => {
  ApiClient.makeGetRequest(
    PATH_PARAMS.GET_DASHBOARD_CLASS_LIST,
    successHandler,
    errorHandler,
    params
  );
};

export const getDashboardClassCount = async (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params: any
) => {
  ApiClient.makeGetRequest(
    PATH_PARAMS.GET_DASHBOARD_CLASS_COUNT,
    successHandler,
    errorHandler,
    params
  );
};

export const getDashboardUsersList = async (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params: any
) => {
  ApiClient.makeGetRequest(
    PATH_PARAMS.GET_DASHBOARD_USER_LIST,
    successHandler,
    errorHandler,
    params
  );
};

export const getDashboardUsersCount = async (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params: any
) => {
  ApiClient.makeGetRequest(
    PATH_PARAMS.GET_DASHBOARD_USER_COUNT,
    successHandler,
    errorHandler,
    params
  );
};

export const getBookingsList = async (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params: any
) => {
  ApiClient.makeGetRequest(
    PATH_PARAMS.GET_BOOKINGS_LIST,
    successHandler,
    errorHandler,
    params
  );
};
