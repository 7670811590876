import * as React from "react";
import { SimpleDialogue, DeleteComponent } from "../index";

function DeleteAlertDialogue(props: any) {
  const { openPopup, onButtonClick, heading, subHeading, buttonsLabel, isLoading } =
    props;
  return (
    <SimpleDialogue openPopup={openPopup}>
      <DeleteComponent
        isLoading={isLoading}
        handleButtonClick={onButtonClick}
        heading={heading}
        subHeading={subHeading}
        buttonsLabel={buttonsLabel}
      />
    </SimpleDialogue>
  );
}

export { DeleteAlertDialogue };
