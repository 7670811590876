import { getDateFormat } from "./dateTimeFormatFunction";

export const getRequiredExplorerData = (data: any) => {
  return data.map((item: any) => {
    let explorerPreferencesColumns: any[] = [];
    if (
      item?.explorerPreferences?.questionnaire &&
      item?.explorerPreferences?.questionnaire.length > 0
    ) {
      explorerPreferencesColumns = getExplorerPreferencesData(
        item?.explorerPreferences?.questionnaire
      );
    }
    return {
      ["User ID"]: item?.userId,
      ["User Handle"]: item?.userHandle ?? "NA",
      ["First Name"]: item?.firstName ?? "NA",
      ["Last Name"]: item?.lastName ?? "NA",
      ["Date of Birth"]: item?.dateOfBirth
        ? getFormatData("date", item?.dateOfBirth)
        : "NA",
      ["Phone Number"]: item?.phone ?? "NA",
      ["Email"]: item?.email ?? "NA",
      ["Is email verified (Yes/No)"]: getFormatData(
        "boolean",
        item?.emailVerified?.isVerified
      ),
      ["Email Verification date (If Email is verified)"]: item?.emailVerified?.isVerified
        ? getFormatData("date", item?.emailVerified?.verifiedOn)
        : "NA",
      ["Language"]: item?.languagesKnown?.length
        ? getArrayToString("language", item?.languagesKnown)
        : "NA",
      ["Country"]: getArrayToString("country", item?.country) ?? "NA",
      ["City"]: item?.cityName ?? "NA",
      ["How did the user hear about app"]: item?.howDidYouHear ?? "NA",
      ["Referred By"]: item?.referredBy ? item?.referredBy : "NA",
      ["Signed Up Date - As Explorer"]:
        getFormatData("date", item?.explorerSignUpDate) ?? "NA",
      ["Explorer Pronoun"]: item.pronoun ?? "NA",
      ["Bio"]: item?.explorerBio ?? "NA",
      ["Agreed For Recieving Marketing Campaign"]: getFormatData(
        "boolean",
        item?.agreedForRecievingMarketingCampaign
      ),
      ...explorerPreferencesColumns,
    };
  });
};

export function getExplorerPreferencesData(data: any[]) {
  return data.reduce((acc: any, item: any) => {
    return {
      ...acc,
      [item?.questionDisplay]: item?.answers?.length ? item?.answers.toString() : "NA",
    };
  }, {});
}

export function getArrayToString(filed: string, data: any[]) {
  if (filed === "country") {
    return data.map((item: any) => item.countryName).toString();
  } else if (filed === "language") {
    return data.toString();
  } else {
    return "";
  }
}

export function getFormatData(type: string, data: any) {
  if (type === "string") {
    return data?.toString();
  } else if (type === "number") {
    return data?.toString();
  } else if (type === "boolean") {
    return data ? "Yes" : "No";
  } else if (type === "date") {
    return getDateFormat(data);
  } else {
    return data;
  }
}
