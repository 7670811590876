import { getDateFormat } from "./dateTimeFormatFunction";
import {
  getArrayToString,
  getExplorerPreferencesData,
  getFormatData,
} from "./exportExplorerFunction";

export const getRequiredGuideData = (guideData: any) => {
  return guideData.map((item: any) => {
    let guidePreferencesColumns: any[] = [];
    if (
      item?.guidePreferences?.questionnaire &&
      item?.guidePreferences?.questionnaire.length > 0
    ) {
      guidePreferencesColumns = getExplorerPreferencesData(
        item?.guidePreferences?.questionnaire
      );
    }

    return {
      "User ID": item?.userId,
      "User Handle": item?.userHandle ?? "NA",
      "First Name": item?.firstName ?? "NA",
      "Last Name": item?.lastName ?? "NA",
      "Date of Birth": item.dateOfBirth ? getDateFormat(item.dateOfBirth) : "NA",
      "Phone Number": item?.phone ?? "NA",
      Email: item?.email ?? "NA",
      "Is email verified (Yes/No)": getBooleanValue(item.emailVerified?.isVerified),
      "Email Verification date (If Email is verified)": item?.emailVerified?.isVerified
        ? getFormatData("date", item?.emailVerified?.verifiedOn)
        : "NA",
      Language: item?.languagesKnown?.length
        ? getArrayToString("language", item?.languagesKnown)
        : "NA",
      ...subDataGuide1(item),
      ...guidePreferencesColumns,
    };
  });
};

function subDataGuide1(item: any) {
  return {
    Country: item?.country ? getArrayToString("country", item?.country) : "NA",
    City: item?.cityName ?? "NA",
    "How did the user hear about app": item?.howDidYouHear ?? "NA",
    "Signed Up Date - As Guide": item?.guideSignUpDate
      ? getFormatData("date", item?.guideSignUpDate)
      : "NA",
    "Pronoun (As Guide)": item.guidePronoun ?? "NA",
    "Guide Bio": item?.guideBio ?? "NA",
    "Agreed For Recieving Marketing Campaign": getBooleanValue(
      item?.agreedForRecievingMarketingCampaign
    ),
    Categories: Array.isArray(item.StartedClasses)
      ? item.StartedClasses?.toString()
      : "NA",
  };
}

function getBooleanValue(data: any) {
  return data ? "Yes" : "No";
}
