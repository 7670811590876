import { Button, Tooltip } from "@mui/material";
import * as React from "react";

import { IMAGES } from "../../../assets/images";
import { buttonStyle } from "../buttonFunction";
import { checkStringLength } from "../dataLengthCheck";

export function broadCastColumnNames(viewBroadCastFunction: any, deleteFunction: any) {
  return [
    { headerName: "No.", field: "id", minWidth: 10, flex: 0.4 },
    {
      field: "Actions",
      headerName: "Actions",
      sortable: false,
      disableClickEventBubbling: true,
      width: 220,
      renderCell: (params: any) => {
        return (
          <>
            <Button
              sx={buttonStyle}
              variant="outlined"
              onClick={() => viewBroadCastFunction(params.row.messageId)}
            >
              {"View"}
            </Button>
            <Tooltip title={"Delete"}>
              <img
                src={IMAGES.deleteFilledOrangeIcon}
                style={{ margin: "0px 10px" }}
                onClick={() =>
                  deleteFunction({
                    id: params.row.messageId,
                    messageTitle: params.row.messageTitle,
                  })
                }
              />
            </Tooltip>
          </>
        );
      },
    },
    { headerName: "Date", field: "scheduleDate", flex: 1.2 },
    {
      headerName: "Message Title",
      field: "messageTitle",
      flex: 2,
      renderCell: (params: any) => (
        <p title={params.row.messageTitle}>
          {checkStringLength(params.row.messageTitle, 40)}
        </p>
      ),
    },
    { headerName: "Status", field: "status", flex: 1 },
  ];
}
