/**
 * All error text constants can be added here
 */

export const errorConst = {
  FIRST_NAME_REQUIRED: "First name is required",
  FULL_NAME_MIN_TWO_CHAR: "Full name must be atleast 2 characters",
  ENTER_VALID_EMAIL: "Please enter a valid email Id",
  EMAIL_ID_REQUIRED: "Email is required",
  UNKNOW_ERROR: "Unknow error occurred!",
  LAST_NAME_REQUIRED: "Last name is required",
  LAST_NAME_MIN_TWO_CHAR: "Last name must be atleast 1 character",
  FIELD_CANNOT_EMPTY: "This field cannot be empty",
  ATLEAST_ONE_TAG: "Tags field must have at least 1 items",
  TAGS_REQUIRED: "Tags required",
  PLEASE_MENTION_REASON: "Please mention the reason",
  MIN_CHAR_FOR_OTHER_REASON: "Other reason should have minimum 1 character",
  TEMPLATE_NAME_REQUIRED: "Template name required",
  TEMPLATE_NAME_MIN_CHAR: "Template name must be atleast 1 character",
  TEMPLATE_SUBJECT_REQUIRED: "Template subject is required",
  TEMPLATE_SUBJECT_MIN_CHAR: "Template subject must have at least 1 character",
  BODY_HTML_REQUIRED: "Template body required(HTML)",
  BODY_HTML_MIN_CHAR: "Template body must have at least 1 character",
  BODY_PLAIN_TEXT_REQUIRED: "Template body plain text required",
  BODY_PLAIN_TEXT_MIN_CHAR: "Template body plain text must have at least 1 character",
  ERROR_IN_CANCEL_CLASS: "Error occurred while cancelling class!",
  OOPS_SOMETHING_WENT_WRONG: "Oops! Something went wrong.",
  SEQUENCE_NO_REQUIRED: "Sequence No. is required",
  START_DATE_REQUIRED: "Start date & time is required",
  END_DATE_REQUIRED: "End date & time is required",
  DATE_RANGE_ERROR_START_DATE: "Start date & time must be less then end date & time",
  DATE_RANGE_ERROR_END_DATE: "End date & time must be greater then start date & time",
  DATE_RANGE_ERROR_PROMOTION_END_DATE:
    "Promotion end date & time should be less than or equal to class schedule start date & time",
  DATE_RANGE_ERROR_PROMOTION_START_DATE:
    "Promotion start date & time should be less than or equal to class schedule start date & time",
  HIDE_EDIT_CLASS:
    "Cannot edit the class, either its expired, cancelled, completed or class schedule time is less than 24 hours",
  MIN_20_CHAR_REQUIRED: "This field should contain minimum 20 characters",
  BROADCAST_MESSAGE_TITLE: "Message title should have minimum 1 character",
  BROADCAST_MESSAGE_TITLE_REQUIRED: "Message title required",
  MESSAGE_BODY_REQUIRED: "Message body required",
  MESSAGE_BODY_MIN_CHAR: "Message body should have minimum 1 character",
  DATE_REQUIRED: "Please select the date",
  ENTER_VALID_DATE: "Please enter a valid date & time",
  ENTER_VALID_TIME: "Please enter a valid time",
  TIME_REQUIRED: "Please select the time",
  MIN_CURRENT_TIME: "Time should be greater than current time",
  ATLEAST_ONE_CLASS_CATEGORY_REQUIRED: "Please select at least one class category",
  MIN_DATE_TIME: "Please select date & time greater than or equal to current date & time",
  MIN_START_DATE:
    "Start date & time should be greater than or equal to current date & time",
  END_DATE_GREATERTHAN_START_DATE:
    "End date & time should be greater than or equal to start date & time",
  END_DATETIME_GREATERTHAN_CURRENT:
    "End date & time should be greater than or equal to current date & time",

  //coupons errors
  TITLE_REQUIRED: "Coupon title is required",
  DISCOUNT_REQUIRED: "Discount value is required",
  MIN_1: "Discount should have minimum 0.1%",
  MAX_100: "Discount can have maximum 100%",
  DESCRIPTION_REQUIRED: "Coupon description is required",
  TERMS_CONDITION_REQUIRED: "Terms and Conditions is required",
  FIELD_SHOULD_BE_NUMBER: "This field can have only numbers",
  DURATION_FIELD_REQUIRED: "Duration field is required",
  FIELD_NUMBER: "This field can have only numbers and maximum two digits",
  BILL_AMOUNT_FIELD_MANADATORY: "Minimum Transaction Amount is required",
  MIN_START_DATE_ONLY: "Start date should be greater than current date",
  ENTER_VALID_DATE_ONLY: "Please enter a valid date",
  START_DATE_ONLY_REQUIRED: "Start date is required",
  END_DATE_GREATERTHAN_START_DATE_ONLY:
  "End date should be greater than or equal to start date",
  END_DATE_ONLY_REQUIRED: "End date is required",
};
