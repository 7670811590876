import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { getGuideData } from "../../../utils/reusable-functions/guidesOrExplorers/view-guideOrExplorer/viewGuideApiData";
import { guidesOrExplorerClassColumnHeader } from "../../../utils/reusable-functions/guidesOrExplorers/view-guideOrExplorer/tableHeader";
import { DetailsViewPageComponent } from "../../../compoundComponents";
import { getGuideOrExplorerClass } from "../../../utils/reusable-functions/guidesOrExplorers/view-guideOrExplorer/guideExplorerClassTableData";

export default function ViewGuidePage() {
  const navigate = useNavigate();
  const location: any = useLocation();

  const [viewData, setViewData] = React.useState<any>({
    data: null,
    isLoading: false,
    guideImage: null,
    guideName: null,
  });

  const [pageState, setPageState] = React.useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 10,
  });

  React.useEffect(() => {
    if (location?.state?.guideId) {
      getGuideData(location.state.guideId, setViewData);
    }
  }, []);

  React.useEffect(() => {
    if (location?.state?.guideId) {
      getGuideOrExplorerClass(location.state.guideId, setPageState, pageState, "guide");
    }
  }, [pageState.page, pageState.pageSize]);

  return (
    <DetailsViewPageComponent
      pageState={pageState}
      setPageState={setPageState}
      viewData={{
        ...viewData,
        profileImage: viewData?.guideImage,
        name: viewData?.guideName,
      }}
      button1={{
        handleClick: () =>
          navigate("/admin/edit-guide", {
            state: { guideId: location?.state?.guideId },
          }),
      }}
      showViews={{ button1: true, button2: true }}
      tableColumns={guidesOrExplorerClassColumnHeader()}
    />
  );
}
