import * as React from "react";
import style from "./imageComponent.module.scss";

function ImageComponent(props: any) {
  const { image, alt = "image", className, styles, onClick, title } = props;
  return (
    <img
      src={image}
      className={`${style.images} ${className}`}
      alt={alt}
      style={styles}
      onClick={onClick}
      title={title}
    />
  );
}

export { ImageComponent };
