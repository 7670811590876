// Update, preview email, cancel button click function
import { NavigateFunction } from "react-router-dom";

import { showToast } from "../../helper-functions/helperFunction";
import {
  previewEmailController,
  updateEmailTemplateController,
} from "../../../controllers/emailTemplateController";
import { errorConst, successConst } from "../../text-constants";

export function handleButtonClick(
  data: any,
  navigate: NavigateFunction,
  setIsupdating: any,
  values: any,
  tempId: number | string,
  setMsg: any,
  fetchedValues: any
) {
  switch (data?.id) {
    case "id-cancel":
      navigate(-1);
      break;
    case "id-preview-email":
      setIsupdating(true);
      handlePreviewEmail(values, tempId, setIsupdating, setMsg, fetchedValues);
      break;
    default:
      return;
  }
}

export const handlePreviewEmail = (
  values: any,
  tempId: string | number,
  setIsupdating: any,
  setMsg: any,
  data: any
) => {
  previewEmailController(
    () => {
      setIsupdating(false);
      showToast(setMsg, successConst.PREV_EMAIL_SUCCESS, "success");
    },
    (error: any) => {
      setIsupdating(false);
      showToast(
        setMsg,
        error.message || error.data.message || errorConst.UNKNOW_ERROR,
        "error"
      );
    },
    {
      templateSubject: values.templateSubject,
      templateBody: values.templateBody,
      templateVariables: data?.templateVariables,
    }
  );
};

export const handleEmailTemplateUpdateClick = (
  value: any,
  templateId: number | string,
  setMsg: any,
  setIsupdating: any,
  navigate: NavigateFunction,
  data: any
) => {
  setIsupdating(true);
  updateEmailTemplateController(
    () => {
      showToast(
        setMsg,
        `Email template ${value.templateName} updated successfully!`,
        "success"
      );
      setIsupdating(false);
      setTimeout(() => navigate(-1), 1500);
    },
    (error: any) => {
      showToast(
        setMsg,
        error.message || error.data.message || errorConst.UNKNOW_ERROR,
        "error"
      );
      setIsupdating(false);
    },
    templateId,
    {
      templateSubject: value.templateSubject,
      templateBody: value.templateBody,
      templateVariables: data?.templateVariables,
    }
  );
};
