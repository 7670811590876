/**
 * backbutton for the required pages in the app bar
 */
export const showBackButton = [
  "class-details",
  "edit-class",
  "add-new-admin",
  "edit-admin",
  "edit-email-template",
  "view-guide",
  "edit-guide",
  "view-broadcast-message-details",
  "new-broadcast-message",
  "edit-explorer",
  "explorer-details",
  "create-coupon",
  "edit-coupon",
  "view-coupon-details",
  "change-password"
];
