import { Typography } from "@mui/material";
import * as React from "react";
import style from "./labelData.module.scss";

/**
 * sample: used in individual class details screen to show the class details
 */

function SimpleLableDataComponent(props: any) {
  const { label, value } = props;
  return (
    <>
      <Typography title={label} className={style.label}>
        {label}
      </Typography>
      <Typography title={value} className={style.value}>
        {value}
      </Typography>
    </>
  );
}

export { SimpleLableDataComponent };
