import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Typography, Stack, TextField, InputAdornment } from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { IMAGES } from "../../assets/images";

function DateTimePickerComponent(props: any) {
  const {
    isDisabled,
    label,
    onChange,
    value,
    error,
    errorText,
    minDate,
    maxDate,
    stackStyle = { mb: "15px" },
    name,
    onBlur,
  } = props;

  const formHelperTextStyle = {
    fontStyle: "italic",
    fontWeight: 400,
    fontSize: "15px",
    color: "#F73030",
    margin: "4px 0 0",
    lineHeight: "22.5px",
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ padding: "50px" }}>
      <Stack spacing={1} sx={stackStyle}>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "16px",
            color: isDisabled ? "#A19191" : "#353535",
          }}
        >
          {label || "Select Date & Time"}
        </Typography>
        <MobileDateTimePicker
          inputFormat="DD/MM/YYYY hh:mm a"
          value={value}
          onChange={onChange}
          minDate={minDate}
          maxDate={maxDate}
          renderInput={(params) => (
            <TextField
              {...params}
              name={name}
              onBlur={onBlur}
              placeholder="dd/mm/yyyy hh:mm am/pm"
              error={error}
              fullWidth
              helperText={error ? errorText : ""}
              FormHelperTextProps={{
                style: formHelperTextStyle,
              }}
            />
          )}
          InputProps={{
            style: {
              backgroundColor: isDisabled && "#E7E1E1",
              fontSize: "15px",
              fontWeight: 400,
              color: "#353535",
            },
            endAdornment: (
              <InputAdornment position="end">
                {
                  <img
                    src={IMAGES.calendarIcon}
                    style={{ cursor: "pointer" }}
                    alt="icon"
                  />
                }
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </LocalizationProvider>
  );
}

export { DateTimePickerComponent };
