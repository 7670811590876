import * as React from "react";
import { ToastMsgType } from "../../utils/helper-functions";

const defaultMsg = {
  show: false,
  type: "success",
  msg: "",
  errorType: "default",
} as ToastMsgType;

export const CustomMessageContext = React.createContext({
  msg: defaultMsg,
  setMsg: (_: ToastMsgType) => {
    return;
  },
});

export const CustomMessageProvider = ({ children }: any) => {
  const [msgState, setMsgState] = React.useState(defaultMsg);

  const contextValue = {
    msg: msgState,
    setMsg: (val: ToastMsgType) => setMsgState(val),
  };

  return (
    <CustomMessageContext.Provider value={contextValue}>
      {children}
    </CustomMessageContext.Provider>
  );
};
