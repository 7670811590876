import { Typography } from "@mui/material";
import * as React from "react";
import { CheckboxComponent } from "..";
import style from "./checkbox.module.scss";

function CheckboxAndText({ text, onChange, isChecked, name }: any) {
  return (
    <div className={style.checkboxCategoryContainer}>
      <CheckboxComponent name={name} checked={isChecked} onChange={onChange} />
      <Typography sx={{ fontWeight: 400, fontSize: "15px" }}>{text}</Typography>
    </div>
  );
}

export { CheckboxAndText };
