export const getDateFormat = (date: string) => {
  if (date) {
    return `${new Date(date)?.getDate()}-${new Date(date)?.getMonth() + 1}-${new Date(
      date
    )?.getFullYear()}; ${new Date(date).toLocaleTimeString("en-US")}`;
  } else {
    return "";
  }
};

export const filterDateFormat = (date: string) => {
  if (date) {
    return `${new Date(date)?.getFullYear()}-${new Date(date)?.getMonth() + 1}-${new Date(
      date
    )?.getDate()}`;
  } else {
    return "";
  }
};

export const dateForDashboard = (date: any[]) => {
  if (date[0].toDateString() === date[1].toDateString()) {
    return `Showing results of ${new Date(date[0]).getDate()}${getDateSuffix(
      new Date(date[0]).getDate()
    )} ${new Date(date[0]).toLocaleString("en-us", { month: "short" })} ${new Date(
      date[0]
    ).getFullYear()}`;
  } else {
    return `Showing results from ${new Date(date[0]).getDate()}${getDateSuffix(
      new Date(date[0]).getDate()
    )} ${new Date(date[0]).toLocaleString("en-us", { month: "short" })} ${new Date(
      date[0]
    ).getFullYear()} to ${new Date(date[1]).getDate()}${getDateSuffix(
      new Date(date[1]).getDate()
    )}  ${new Date(date[1]).toLocaleString("en-us", { month: "short" })} ${new Date(
      date[1]
    ).getFullYear()}`;
  }
};

export function getDateSuffix(dayNow: number) {
  let daySuffix = "";
  switch (dayNow) {
    case 1:
    case 21:
    case 31:
      daySuffix = "st";
      break;
    case 2:
    case 22:
      daySuffix = "nd";
      break;
    case 3:
    case 23:
      daySuffix = "rd";
      break;
    default:
      daySuffix = "th";
      break;
  }
  return daySuffix;
}

export const checkForWholeYear = (date: any[]) => {
  return (
    date[0]?.getMonth() === 0 &&
    date[0]?.getDate() === 1 &&
    date[1]?.getDate() === 31 &&
    date[1]?.getMonth() === 11
  );
};

export function getDatesInRange(startDate: any, endDate: any) {
  const date = new Date(startDate.getTime());

  const dates = [];

  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates.map((dt) => ({ date: dt.getDate().toString(), month: dt.getMonth() + 1 }));
}

export function getYearsInRange(startDate: any, endDate: any) {
  let date: any = startDate.getFullYear();

  const dates: any = [];

  for (let i = date; i <= endDate.getFullYear(); i++) {
    dates.push(date);
    date = date + 1;
  }
  return dates;
}
