import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import { AppBar, Typography, Tooltip } from "@mui/material";
import theme from "../../themes/theme";
import { IMAGES } from "../../assets/images";
import { showBackButton } from "../../utils/text-constants/appBarContent";
import { useNavigate } from "react-router-dom";

const NavBar = (props: any): JSX.Element => {
  const { selectedPage, drawerWidth } = props;
  const navigate = useNavigate();

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: theme.palette.yellow.light,
        width: `calc(100% - ${drawerWidth}px)`,
        ml: `${drawerWidth}px`,
        p: 1,
      }}
      elevation={0}
    >
      <Toolbar sx={{ gap: 3 }}>
        {showBackButton.includes(window.location.pathname?.split("/").pop()) && (
          <Tooltip title={"Go Back"}>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
              src={IMAGES.backArrowIcon}
            />
          </Tooltip>
        )}
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{
            flexGrow: 1,
            color: "#353535",
            fontWeight: 600,
            fontSize: "30px",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          {selectedPage}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export { NavBar };
