import * as Yup from "yup";
import { errorConst } from "../../text-constants";

export const editGuideValidationSchema = () => {
  return Yup.array().of(
    Yup.object({
      data: Yup.mixed().when("inputFieldType", {
        is: "MULTI_SELECT",
        then: Yup.array()
          .min(1, errorConst.FIELD_CANNOT_EMPTY)
          .required(errorConst.FIELD_CANNOT_EMPTY),
        otherwise: Yup.string().trim().when(["id", "isFieldVisible", "isDataPresent"], {
          is: (id: any, isFieldVisible: boolean, isDataPresent: boolean) => {
            if (id === 16 && !isDataPresent) {
              return true;
            }
            if (id === 19 && isFieldVisible) {
              return true;
            }
            if (id === 19 && !isFieldVisible) {
              return false;
            }
            if (id === 13 || id === 14 || id === 15 || id === 18) {
              return true;
            } else {
              return false;
            }
          },
          then: Yup.string().trim()
            .min(1, errorConst.FIELD_CANNOT_EMPTY)
            .required(errorConst.FIELD_CANNOT_EMPTY),
          otherwise: Yup.string().min(0),
        }),
      }),
    })
  );
};
