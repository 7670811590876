import { Box } from "@mui/material";
import * as React from "react";

import DataSummaryViewComponent from "./DataSummaryViewComponent";
import GraphViewComponent from "./GraphViewComponent";

const DashboardComponent: React.FC = () => {
  const [dashboardView, setDashboardView] = React.useState("graphView");

  const handleViewChange = (viewType: string) => {
    setDashboardView(viewType);
  };

  return (
    <>
      <Box>
        {dashboardView === "graphView" ? (
          <GraphViewComponent onChangeView={handleViewChange} />
        ) : (
          <DataSummaryViewComponent onChangeView={handleViewChange} />
        )}
      </Box>
    </>
  );
};

export default DashboardComponent;
