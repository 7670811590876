/**api call functions related to view details class */

import { fetchSingleClassDetailsController } from "../../../../controllers/classesController";
import { getEditInputFields } from "../edit-class/getEditClassData";
import { editClassLocationData } from "../edit-class/getEditLocationData";

export const getSingleClassData = (
  classId: number | string,
  setClassData: any,
  pageType?: string,
  setImages?: any,
  setLocationData?: any,
  setMsg?: any
) => {
  setClassData({
    data: null,
    isLoading: true,
  });
  fetchSingleClassDetailsController(
    (data: any) =>
      successClassFetch(
        Array.isArray(data?.data) && data?.data[0],
        setClassData,
        pageType,
        setImages,
        setLocationData
      ),
    (error: any) => errorClassFetch(error, setMsg, setClassData),
    classId
  );
};

function successClassFetch(
  data: any,
  setClassData: any,
  pageType?: string,
  setImages?: any,
  setLocationData?: any
) {
  if (data) {
    const guideFullName = `${data.guideFirstName} ${data.guideLastName}`;
    const explorers = data.explorers
      ?.filter(
        (filterUser: any) =>
          filterUser.scheduleStatus !== "Scheduled" &&
          filterUser.scheduleStatus !== "ScheduleCancelled" &&
          filterUser.scheduleStatus !== "ScheduleCompleted"
      )
      ?.map((explorer: any, i: number) => ({
        id: i + 1,
        explorerName: `${explorer.firstName || ""} ${explorer.lastName || ""}`,
        status: explorer.scheduleStatus || "NA",
        ...explorer,
      }));
    if (pageType === "EDIT_CLASS") {
      setLocationData({
        data: editClassLocationData(data.classLocation),
      });
      setImages({
        coverImage: {
          url: data.coverImageUrl,
        },
        otherImages: data.otherImages,
        locationImages: data.classLocation.locationImages || [],
      });

      setClassData({
        data: getEditInputFields(data),
        copyData: data,
        isLoading: false,
      });
    } else {
      setClassData({
        data: { ...data, guideFullName, explorers },
        isLoading: false,
      });
    }
  } else {
    setClassData({
      data: null,
      isLoading: false,
    });
  }
}

function errorClassFetch(error: any, setMsg: any, setClassData: any) {
  setClassData({
    data: null,
    isLoading: false,
  });

  setMsg &&
    setMsg({
      show: true,
      type: "error",
      msg: error.message || error.data.message || "Something went wrong!",
      errorType: "default",
    });
}
