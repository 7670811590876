import * as React from "react";
import Cropper from "react-cropper";
import { Box, Tooltip, Typography } from "@mui/material";

import { IMAGES } from "../../assets/images";
import { LoadingButtonComponent } from "../buttonsList/LoadingButtonComponent";
import { CustomFileInput } from "../imageFIleSelect/CustomFileInput";
import style from "./imageCropper.module.scss";
import { ButtonsListComponent, CircularProgressBar } from "..";
import { dashboardConst } from "../../utils/text-constants";
require("cropperjs/dist/cropper.css");

export const ImageCropperComponent = (props: any) => {
  const { selectedFile, handleButtonClick, aspectRatioValue } = props;

  const cropperStyle = {
    height: 400,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "0px",
    minWidth: "100%",
    minHeight: "400px",
    backgroundColor: "#E7E1E1",
  };

  const buttonsList = [
    {
      id: "img-cropping",
      label: "Update Image",
      inlineStyle: { padding: "0 25px", marginRight: "-15px" },
    },
  ];

  const [image, setImage] = React.useState();
  const [cropper, setCropper] = React.useState<any>();
  const [aspectRatio, setAspectRatio] = React.useState<number>(aspectRatioValue || 1.25);
  const [isImageCropping, setIsImageCropping] = React.useState<boolean>(false);

  const onImageChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);
    e.target.value = null;
  };

  const getCroppedImageBlobFile: any = async () => {
    if (typeof cropper !== "undefined") {
      const blob = await (
        await fetch(
          cropper
            .getCroppedCanvas({
              width: 700,
              height: 590,
              minWidth: 700,
              minHeight: 590,
              maxWidth: 4096,
              maxHeight: 4096,
              fillColor: "#fff",
              imageSmoothingEnabled: true,
              imageSmoothingQuality: "low",
            })
            .toDataURL()
        )
      ).blob();
      if (blob) {
        return {
          blobFile: blob,
          url: cropper.getCroppedCanvas().toDataURL(),
        };
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  React.useEffect(() => {
    aspectRatioValue && setAspectRatio(aspectRatioValue);
    onImageChange(selectedFile);
  }, []);

  return (
    <Box p={4} key={"id"}>
      <div className={style.cropperHeaderContainer}>
        <Typography className={style.cropperHeader}>Edit Photo</Typography>
        <Tooltip title="Close">
          <img
            src={IMAGES.closeIcon}
            className={style.closeIconStyle}
            onClick={() => handleButtonClick({ id: "cancel" })}
          />
        </Tooltip>
      </div>
      {image && (
        <Cropper
          style={cropperStyle}
          zoomTo={0.5}
          initialAspectRatio={aspectRatio}
          aspectRatio={aspectRatio}
          src={image}
          viewMode={1}
          zoomable
          dragMode={"move"}
          imageSmoothingEnabled={true}
          imageSmoothingQuality={"low"}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          rotatable={true}
          checkOrientation={false}
          onInitialized={(instance: any) => {
            setCropper(instance);
          }}
        />
      )}
      {!image && (
        <div className={style.progressbarContainer}>
          <CircularProgressBar loadername={dashboardConst.LOADING_IMAGE} />
        </div>
      )}
      <div className={style.buttonsContainer}>
        <ButtonsListComponent
          isLoading={isImageCropping}
          buttonsIndexForLoader={[0]}
          buttonsArray={buttonsList}
          inlineStyle={{ margin: "0px", padding: "0 15px" }}
          onButtonClick={async () => {
            setIsImageCropping(true);
            const imageBlobAndUrl = await getCroppedImageBlobFile();
            if (imageBlobAndUrl) {
              handleButtonClick({ id: "crop-image", data: imageBlobAndUrl });
              setIsImageCropping(false);
            } else {
              setIsImageCropping(false);
            }
          }}
        />
        <CustomFileInput handleImageChange={onImageChange} />
        <LoadingButtonComponent
          buttonLabel="Cancel"
          handleClick={() => handleButtonClick({ id: "cancel" })}
        />
      </div>
    </Box>
  );
};
