/**create admin api function call */
import { Dispatch, SetStateAction } from "react";
import { NavigateFunction } from "react-router-dom";
import {
  createNewAdminController,
  deleteAdminController,
  editAdminsDetailsController,
  fetchSingleAdminController,
} from "../../controllers/adminSettingsController";
import { successConst, errorConst } from "../text-constants";

export const handleCreateAdmin = (
  params: {
    firstName: string;
    lastName: string;
    role: string;
    countryId: string;
    email: string;
  },
  setIsUploading: Dispatch<SetStateAction<boolean>>,
  setMsg: any,
  navigate: NavigateFunction
) => {
  setIsUploading(true);
  createNewAdminController(
    () => handleCreateEditAdminSuccess(setIsUploading, setMsg, navigate),
    (data: any) => handleCreateEditAdminFailure(data, setIsUploading, setMsg),
    params
  );
};

function handleCreateEditAdminSuccess(
  setIsUploading: Dispatch<SetStateAction<boolean>>,
  setMsg: any,
  navigate: NavigateFunction,
  msg?: string
) {
  setIsUploading(false);
  setMsg({
    show: true,
    type: "success",
    msg: msg || successConst.NEW_ADMIN_ADD_SCCESS,
    errorType: "default",
  });
  setTimeout(() => navigate(-1), 1000);
}

function handleCreateEditAdminFailure(
  data: any,
  setIsUploading: Dispatch<SetStateAction<boolean>>,
  setMsg: any
) {
  setIsUploading(false);
  setMsg({
    show: true,
    type: "error",
    msg: getErrorMessage(data),
    errorType: "default",
  });
}

function getErrorMessage(data: any) {
  if (data?.data?.errors?.userMessage) {
    return data.data.errors.userMessage;
  } else if (data?.data?.message) {
    return data.data.message;
  } else {
    return errorConst.UNKNOW_ERROR;
  }
}

/**handle edit admin */

export const handleEditAdmin = (
  params: any,
  userId: any,
  setIsUploading: Dispatch<SetStateAction<boolean>>,
  setMsg: any,
  navigate: NavigateFunction
) => {
  editAdminsDetailsController(
    () =>
      handleCreateEditAdminSuccess(
        setIsUploading,
        setMsg,
        navigate,
        successConst.ADMIN_EDIT_SUCCESS
      ),
    (error: any) => handleCreateEditAdminFailure(error, setIsUploading, setMsg),
    params,
    userId
  );
};

/**delete admin */

export const handleDeleteAdmin = (
  state: any,
  setState: any,
  setMsg: any,
  setToggleToFetchList: Dispatch<SetStateAction<boolean>>,
  navigate: NavigateFunction
) => {
  setState((prev: any) => ({
    ...prev,
    isDeleting: true,
  }));
  deleteAdminController(
    () => deleteSuccess(setState, setMsg, state, setToggleToFetchList, navigate),
    (error: any) => errorDelete(error, setState, setMsg),
    state.id
  );
};

function deleteSuccess(
  setIsDeleting: any,
  setMsg: any,
  state: any,
  setToggleToFetchList: Dispatch<SetStateAction<boolean>>,
  navigate: NavigateFunction
) {
  resetDeleteState(setIsDeleting);
  setMsg({
    show: true,
    type: "success",
    msg: `Admin ${state.adminName} ${successConst.DELETE_SUCCESS}`,
    errorType: "default",
  });

  if (state.id === localStorage.getItem("userId")) {
    setTimeout(() => navigate("/"), 1000);
  } else {
    setToggleToFetchList((prev: any) => !prev);
  }
}

function errorDelete(error: any, setIsDeleting: any, setMsg: any) {
  resetDeleteState(setIsDeleting);
  setMsg({
    show: true,
    type: "error",
    msg: getErrorMessage(error),
    errorType: "default",
  });
}

function resetDeleteState(setIsDeleting: any) {
  setIsDeleting({
    id: "",
    adminName: "",
    isClicked: false,
    isDeleting: false,
  });
}

/**fetch single admin function */

export const fetchSingleAdmin = (
  userId: string,
  setIsLoading: any,
  setData: any,
  setMsg: any
) => {
  fetchSingleAdminController(
    (data: any) => successAdminFetch(data.data, setIsLoading, setData),
    (error: any) => errorAdminFetch(error, setMsg, setIsLoading),
    userId
  );
};

function successAdminFetch(data: any, setIsLoading: any, setData: any) {
  setIsLoading(false);
  setData({
    firstName: data.firstName,
    lastName: data.lastName || "",
    role: (data.role?.includes("Admin" || "admin") && "admin") || "",
    countryId: data.countryId,
    email: data.email,
  });
}

function errorAdminFetch(error: any, setMsg: any, setIsLoading: any) {
  setMsg({
    show: true,
    type: "error",
    msg: getErrorMessage(error),
    errorType: "default",
  });
  setIsLoading(false);
}
